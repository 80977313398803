/* ==========================================================================
	ICON STYLES
========================================================================== */

.icon {
	display: inline-block;
	fill: currentColor;
	position:relative;
	vertical-align:middle;
	@include remit(top, -2);
}

.icon-close {
	transition:$g-trans;
	position: relative;
	display: inline-block;
	width: 16px;
	height: 16px;
	overflow: hidden;
	top:2px;

	&::before, &::after {
		transition:$g-trans;
		content: '';
		position: absolute;
		height: 2px;
		width: 100%;
		top: 50%;
		left: 0;
		margin-top: -1px;
		background: $color-error;
	}
	&::before {
		transform:rotate(45deg);
	}
	&::after {
		transform:rotate(-45deg);
	}
	&.black {
		&::before, &::after {
			height: 8px;
			margin-top: -4px;
		}
	}
}

.icon-date {
	width:16px;
	height:16px;
	@include remit(margin-right, 3);

	&--small {
		width:14px;
		height:14px;
	}
}

.icon-people {
	// width:48px;
	// height:30px;
	width:58px;
	height:36px;
}

.icon-money {
	// width:25px;
	// height:30px;
	width:30px;
	height:36px;
}

.icon-location {
	width:34px;
	height:36px;
}

.icon-error {
	width:18px;
	height:18px;
	@include remit(margin-right, 2);
}