/* ==========================================================================
   TABS
========================================================================== */

.tabs {
	@extend %cf;
	clear:both;
}

.tab {
	@extend %cf;
}


/* ==========================================================================
   RESPONSIVE TABS
========================================================================== */

.r-tabs .r-tabs-nav {
	margin: 0;
	padding: 0;
	display: none;

	@include mq($tabs-bp) {
		display: block;
	}
}

.r-tabs .r-tabs-tab {
	display: inline-block;
	margin: 0;
	list-style: none;
}

.r-tabs .r-tabs-panel {
	display: none;
}

.r-tabs .r-tabs-accordion-title {
	display: block;

	@include mq($tabs-bp) {
		display:none;
	}
}

.r-tabs .r-tabs-panel.r-tabs-state-active {
	display: block;
}


/* ==========================================================================
   BAR-UNDER-SELECTED-TAB VARIANT OF RESPONSIVE TABS
========================================================================== */

.tabs--bar {

	&.r-tabs {

		border-top: 1px solid $lightgrey;

		@include mq($regular) {
			border-style: none;
		}

	}

	.r-tabs-nav {
		border-bottom: 1px solid $lightgrey;
	}

	.r-tabs-anchor {

		border-color: $lightgrey;
		border-style: none none solid;
		border-width: 1px;
		display: block;
		@include font-rem($h4-font-size);
		font-weight: bold;
		@include remit(padding, $bsu);
		position: relative;

		@include mq($regular) {
			border-style: none;
		}

		@include mq($large) {
			@include remit(padding, $bsu ($bsu * 2));
		}

		&::before {

			color: $color-base;
			content: "\25bc";
			display: block;
			position: absolute;
			@include remit(right, $hsu);
			@include font-rem(14);
			top: 50%;
			transform: translateY(-50%);

			@include mq($regular) {
				display: none;
			}

		}

	}

	.r-tabs-state-active > .r-tabs-anchor {

		color: $color-text;
		position: relative;

		&::before {
			content: "\25b2";
		}

		&::after {
			background-color: $color-base;
			content: "";
			display: block;
			height: 5px;
			left: 0;
			position: absolute;
			top: 100%;
			width: 100%;
			transform: translateY(-2px);
		}

	}

	.r-tabs-panel {
		@include remit(padding, $bsu);
	}

}

.tabs--border-bar {

	border-bottom: 1px solid $lightgrey;

	@include mq($regular) {
		border-bottom-style: none;
	}

}

.tabs--cpr {

	.island-top {
		margin-top: 0 !important;
	}

	.figure--fullwidth {
		margin-left: 0;
	}

	.figcaption {
		display: none;
	}

	@include mq($regular) {

		.media__img {
			max-width: 300px;
		}

	}

}

.tabs--container {

	> .r-tabs-nav {
		margin-left: auto;
		margin-right: auto;
	}

	.r-tabs-panel {
		padding-left: 0;
		padding-right: 0;
	}

	.figure--fullwidth {
		margin-bottom: 0;
		margin-top: 0;
	}

}
