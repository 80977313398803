/* ==========================================================================
   TABS CLIENTS
========================================================================== */

$tabs-bg:	$white;
$tab:		$lightgrey;
$tab-text:	#888888;

.clientTabs {
	//Tabs container
	.r-tabs {
		position: relative;
		@include mq($tabs-bp) {
			@include remit(top, -64);
			@include remit(margin-bottom, -64);
		}
	}

	.r-tabs-nav {
		font-size:0;
		position:relative;
		border-bottom: 1px solid #d9d9d9;
	}


	//Tab element
	.r-tabs .r-tabs-nav .r-tabs-tab {
		position: relative;
		// border:1px solid blue;

		&:not(:last-of-type) {
			@include remit(margin-right, 1);
		}
	}

	//Tab anchor
	.r-tabs .r-tabs-nav .r-tabs-anchor {
		display:block;

		font-family:$font-header;
		@include font-rem(19);
		@include remit(padding, 20);
		background-color: $white;
		color:$color-base;
		font-weight: 600;
		min-width:180px;
		text-align:center;
		border-bottom: 4px solid $white;

		@include hover {
			// background-color: darken($lightgrey, 10%);
			color: darken(#888, 10%);
			border-bottom: 4px solid $color-base;
		}
	}

	//Active state tab anchor
	.r-tabs .r-tabs-nav .r-tabs-state-active .r-tabs-anchor {
		background-color:$tabs-bg;
		color:$tab-text;
		border-bottom: 4px solid $color-base;
	}

	//Tab panel
	.r-tabs .r-tabs-panel {
		z-index:100;
		// background-color:$color-base;
	}

	//Accordion anchor
	.r-tabs .r-tabs-accordion-title .r-tabs-anchor {
		display: block;
		background-color: $white;
		// color:$tab-text;
		font-family:$font-header;
		@include remit(padding, $bsu);
		@extend %no-gutter;
		@include font-rem(18);
		font-weight: 600;
		border-top:1px solid #d9d9d9;

		&:after {
			content: '\25bc';
			position:absolute;
			@include remit(right, $hsu);
			@include font-rem(14);
		}

		&:hover {
			color: $color-base;
		}
	}

	//Active accordion anchor
	.r-tabs .r-tabs-accordion-title.r-tabs-state-active .r-tabs-anchor {
		// background-color: darken($color-base, 20%);
		// color:$white;
		// border-bottom:1px solid darken($color-base, 20%);
		color:$tab-text;
		border-bottom: 4px solid $color-base;

		&:after {
			content: '\25b2';
		}
	}
}