/* ==========================================================================
News STYLES
========================================================================== */




/* ==========================================================================
 	DATE STYLES
========================================================================== */

.date {
	@include font-size(14);

	// .blogSideBar & {
	// 	color:$color-tri;
	// 	margin-bottom:0;
	// }
}