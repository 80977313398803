/* ==========================================================================
	SEARCH FORM STLYES
========================================================================== */
.search-header {
	padding:3%;

	@include mq($regular, max) {
		text-align:center;
		height:100%;
		width:100%;
		display:table;


		@include mq($small) {
			padding:20%;
		}
	}

	.search__title {
		@include mq($small, max) {
			@include font-rem(18);
		}
		color:$white;
		@include remit(margin-bottom, $hsu);
	}

	.label {
		@include font-rem(18);
		color:$color-error;
		position:relative;
	}

	.js_search_is_open & {
		@include fadeInUp();
	}

	&__content {
		color:$white;


		@include mq($regular, max) {
			vertical-align:middle;
			display:table-cell;
		}
	}

	p {
		@include font-size(14, 6, 21);
	}

	&__close {
		@include remit(margin-bottom, $hsu);
	}
}


/* ==========================================================================
	MAIN SEARCH STYLES
========================================================================== */

// .search {
// 	position:relative;
// }

.search-results {
	// @extend %cf;
	clear: both;
	display: flex;
	flex-flow: row wrap;

	@include mq($small) {
		margin-left: -10px;
		margin-right: -10px;
	}

	&__item {
		position:relative;
		list-style:none;
		@include remit(margin-bottom, $bsu);
		@include remit(padding-bottom, $hsu);
		// border-left:5px solid lighten($lightgrey, 5%);
		border-bottom:1px solid lighten($lightgrey, 15%);
		width: 100%;


		@include mq($small) {
			// @include gallery(12, of-type);
			border:0;

			width: 50%;
			// float: left;
			padding: 0 10px 12px;
		}

		@include mq($regular) {
			// @include gallery(8, of-type);
			width: 33%;
			// float: left;
		}
	}
}

.search-filters {
	@extend %cf;
	width: 100%;
	float: left;
	margin-bottom: 30px;

	@include mq($regular) {
		width: 20%;
		margin-right: 5%;
	}

	&__section {
    	padding: 30px 0;

    	h2 {
    		color: black;
    		margin: 0;
    	}

    	h4 {
    		color: black;
    		font-size: 14px;
    		font-weight: bold;
    		position: relative;
		    margin-bottom: 30px;
		    cursor: pointer;

		    &:after {
		    	content: "v";
			    display: block;
			    position: absolute;
			    right: 0;
			    top: 0;
			    color: #f36d21;
			    transform: rotate(180deg);

		        border: 2px solid currentColor;
			    border-width: 2px 2px 0 0;
			    width: 8px;
			    height: 8px;
			    content: "";
			    display: inline-block;
			    top: 4px;
			    right: 4px;
			    transform: rotate(135deg);
		    }
    	}

    	input[type="text"]{
    		width: 100%;
    		height: 40px;
    		padding: 10px;
    		margin-bottom: 15px;
    		border: 1px solid #d9d9d9;

    		&::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
			    opacity: 0.6; /* Firefox */
			}

			&:-ms-input-placeholder { /* Internet Explorer 10-11 */
				opacity: 0.6;
			}

			&::-ms-input-placeholder { /* Microsoft Edge */
				opacity: 0.6;
			}
    	}

		form {
	    	max-height: 300px;
	    	overflow: auto;


	    	label {
	    		display: inline-block;
	    		width: 100%;
	    		margin-bottom: 15px;
	    		font-size: 14px;
	    		position: relative;
   				padding-left: 20px;

			    &.disabled {
			    	opacity: 0.6;
			    }

			    input {
			    	position: absolute;
    				left: 0;
			    }

			    &.one-of-three {
			    	@extend .one-of-three;
   					min-height: unset;
			    }
	    	}

	    	.instafilta-match {
	    		color: #f36d21;
	    		font-weight: bold;
	    	}
	    }

	    &.closed {
	    	// max-height: 79px;
    		// overflow: hidden;
    		min-height: 0;

    		h4 {
    			&:after {
    				transform: rotate(-45deg);
    			}

    			& ~ * {
    				display: none!important;
    			}
    		}
	    }

	    .filters-cta {
	    	color: #f36d21;
	    }

	    &.open-close {
	    	h2 {
	    		position: relative;
				cursor: pointer;

    			&:after {
			    	content: "v";
				    display: block;
				    position: absolute;
				    right: 0;
				    top: 0;
				    color: #f36d21;
				    transform: rotate(180deg);

				    border: 2px solid currentColor;
				    border-width: 2px 2px 0 0;
				    width: 8px;
				    height: 8px;
				    content: "";
				    display: inline-block;
				    top: 4px;
				    right: 4px;
				    transform: rotate(135deg);
			    }
	    	}

	    	&.closed {
	    		max-height: unset;

	    		h2 {
	    			&:after {
	    				transform: rotate(-45deg);
	    			}
	    		}
	    	}
	    }

	    &.hideondesktop {
			@include mq($regular) {
				display: none;
			}
	    }

	    &.search-filters__section--item {
	    	display: none;

	    	@include mq($regular) {
				display: block;
	    	}
	    }

	}

	.clearFilters {
		width: 100%;
	    background-color: #d9d9d9;
	    color: #797979;
	    display: inline-block;
	    text-align: center;
	    padding: 10px;
	    font-weight: bold;
	    border: 0;
	    outline: none;
	}

	.appliedFilters {
		padding-bottom: 10px;

		.filter-item {
			position: relative;
			cursor: pointer;

			&:after {
				content: '\00D7';
				position: absolute;
				right: 0;
				top: 0;
			}
		}
	}

	#products & {
		width: 100%;

		@include mq($regular) {
			border-bottom: 1px solid #d9d9d9;
		}

		.hideondesktop {
			display: block;
		}

		.search-filters__section--item {
			@include mq($regular) {
				border: 0 none;
			}
		}

		.applied-filters__client {
			@include mq($regular) {
				border-top: 1px solid #d9d9d9;
				border-bottom: 1px solid #d9d9d9;

				.appliedFilters {
				    display: inline-block;
				    width: calc( 100% - 150px );
				    clear: both;

				    .filter-item {
					    display: inline-block;
					    float: left;
					    padding: 10px 20px 10px 0;
					    margin-right: 20px;

					    &:after {
					    	top: 10px;
					    }
				    }
				}

				.clearFilters {
				    float: right;
					padding: 10px 20px;
				    width: 150px;
				}
			}
		}
	}
}

.search-container {
	@extend %cf;
	clear: both;
	width: 100%;
	margin-top: 83px;

	@include mq($regular) {
		width: 75%;
		float: left;
		clear: unset;
	}

	.entryTeaserCertificate__logo {
		width: 100%;
	}
}

.keyword {
	color:$color-sub;
	font-style:italic;
}

.search-header__form--company {
	h3.search__title {
    	font-size: 2rem;
    	font-weight: bold;
    	margin: 2rem 0 1rem;
    }

    input[type="search"], select {
	    display: inline-block;
	    vertical-align: top;
	    height: 40px;
	    height: 2.5rem;
	    padding: 10px;
	    padding: .625rem;
	    font-size: 16px;
	    font-size: 1rem;
	    float: left;
    }

    input[type="search"] {
    	display: none;
    }

    select {
	    width: 100%;
	    height: 62px;
    }

    label {
	    display: block;
	    margin-bottom: 0;
	    font-weight: normal;
	    font-size: 18px;
		text-align: left;

	    @include mq($small) {
			text-align: center;
	    }
    }

    .form__select {
    	width: 100%;
    	position: relative;

    	@include mq($small) {
			width: 30%;
	    	float: left;
	    	margin-right: 30px;
		}

    	&:after {
    		content: '';
    		position: absolute;
    		bottom: -62px;
    		right: 0;
    		height: 62px;
    		width: 62px;
    		background-color: #f36d21;
    		color: white;
    		text-align: center;
    		font-size: 20px;
    		padding: 20px 15px;
    		cursor: pointer;
    		pointer-events: none;
			transition: all .4s ease;

			@include mq($small) {
				bottom: 0;
			}
    	}

    	label {
    		&:after {
			    position: absolute;
			    border: 2px solid currentColor;
			    border-width: 2px 2px 0 0;
			    width: 15px;
			    height: 15px;
			    content: "";
			    display: inline-block;
			    top: 53px;
			    right: 24px;
			    transform: rotate(135deg);
			    cursor: pointer;
    			pointer-events: none;
			    z-index: 99;
    		}
    	}

    	&:hover {
    		&:after {
    			background-color: #0e9ee4;
    		}
    	}
    }

    .form__search {
    	width: 100%;
    	float: left;
    	overflow: hidden;

    	label {
    		margin-top: 0.7rem;
    	}

    	input[type="search"] {
    		width: 100%;
    		margin-bottom: 0.7rem;
    	}

		button {
			width: 100%;
			font-size: 18px;
			margin-bottom: 2rem;
		}

    	@include mq($small) {
			width: 60%;
	    	float: left;

	    	label {
	    		margin-top: 0;
	    	}

	    	input[type="search"] {
	    		width: calc( 100% - 140px );
	    		margin-bottom: 1.5rem;
	    	}

	    	button {
	    		width: unset;
	    		margin-bottom: unset;
	    	}
		}

    }

    #type {
  //   	background: #ffffff; /* Old browsers */
		// background: -moz-linear-gradient(left, #ffffff 0%, #ffffff 85%, #f36d21 85%, #f36d21 100%); /* FF3.6-15 */
		// background: -webkit-linear-gradient(left, #ffffff 0%, #ffffff 85%, #f36d21 85%, #f36d21 100%); /* Chrome10-25,Safari5.1-6 */
		// background: linear-gradient(to right, #ffffff 0%, #ffffff 85%, #f36d21 85%, #f36d21 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		// filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f36d21',GradientType=1 ); /* IE6-9 */
		// border: 0;
    }
}

form.page-links {

	border-top: 1px solid #d9d9d9;
	padding: 30px 0 0;

	label {
		display: inline-block;
		width: 32px;
		height: 28px;
		font-size: 16px;
		color: #f36d21;
		cursor: pointer;

		&.active {
			color: black;
		}

		&.disabled {
			color: #e3e3e3;
			cursor: inherit;
		}
	}

	input[type="checkbox"] {
		position: absolute;
		visibility: hidden;
	}
}

button.back-to-filters {
	background: #f36d21;
    border: 0 none;
    padding: 15px 30px;
    color: white;

    &.pagination__link--prev {
    	&:before {
    		margin-right: 5px;
    	}
    }
}

.pageTitle.underline {
	padding-bottom: 30px;
}
