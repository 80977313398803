/* ==========================================================================
   FOOTER SEARCH STYLES
========================================================================== */
.footer__search {
	@include remit(margin-top, $g-spacing);

	@include mq($large) {
		@include span(8 last);
		@include remit(margin-top, $hsu);
	}
}

.footer__search {
	color:$white;
	position:relative;

	input {
		width:80%;
		@include remit(padding-bottom, $hsu);
		@include remit(padding-left, $bsu);
		background-color:transparent;
		color: inherit;
		border:0;
		border-bottom:1px solid rgba($white, 0.5);
		border-radius:0;
		outline:none;
		-webkit-appearance: none;

		@include font-rem(13);
		@include placeholder($white);
	}

	.icon {
		width:16px;
		height:16px;
	}

	&__icon {
		position:absolute;
		@include remit(top, 2);
	}
}
