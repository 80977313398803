/* ==========================================================================
	FLEX NAV STYLES
========================================================================== */

// .submenu-icon { display: none; }

// .item-with-ul {
// 	.submenu-icon { display: block; }


// 	@include hover() {
// 		.touch-button {
// 			transition:$g-trans;
// 		}
// 	}
// }

.flexnav {
	ul ul {
		opacity:0;
	}

	li {
		position: relative;
	}

	li a {
		position: relative;
	}

	li ul {
		width: 100%;
		z-index: 900;

		@include mq($nav-bp) {
			position: absolute;
			top: auto;
			width: 350px;
		}

		ul {

			@include mq($nav-bp) {
				margin-left:100%;
				top:0;
			}
		}

		li {
			position: relative;
			overflow: hidden;

			@include mq($nav-bp) {
				display: block;
			}

			a {
				transition:$g-trans;
				display:block;
			}
		}
	}

	li ul.flexnav-show {
		opacity:1;

		@include fadeInDown(
			$duration: 1s,
			$delay: 0s
		);

		li {
			overflow: visible;
		}
	}
}

// // ========================
// //	Rotate the icon
// //=========================
.flexnav-show + .touch-button .arrow {
	transition:$g-trans;
	transform: rotate(-45deg);


	@include mq($nav-bp) {
		transition:$g-trans;
		transform: rotate(135deg);
		color:$color-base;
	}
}