.avcp-title {
	border-bottom: 1px solid currentColor;
	@include font-rem($h5-font-size);
	@include remit(padding-bottom, $hsu);
}

.avcp-detail {

	display: flex;
	flex-direction: column;

	@include mq($regular) {
		flex-direction: row;
		justify-content: space-between;
	}

}

.avcp-detail__content {

	ul {
		@include remit(margin-bottom, $bsu);
		padding-left: 40px;
	}

	@include mq($regular) {
		width: 35%;
	}

}

.avcp-detail__info {

	overflow: auto;

	@include mq($regular) {
		overflow: visible;
		width: 60%;
	}

}

.avcp-table {

	border-collapse: collapse;
	caption-side: bottom;
	width: 100%;

	> caption {
		@include remit(margin-top, $bsu);
		text-align: left;
	}

	th,
	td {
		border-color: $lightgrey;
		border-style: solid;
		border-width: 1px;
		@include remit(padding, $hsu);
		text-align: center;
		vertical-align: middle;
	}

	thead th {
		background-color: $color-base;
		border-color: currentColor;
		color: $white;
		@include font-rem($p-font-size);
		font-weight: normal;
		min-width: 112px;
	}

	tbody th {
		@include font-rem($h3-font-size);
	}

}

.avcp-icon {
	fill: #4394d1;
	@include remit(height, $bsu);
	@include remit(width, $bsu);
}
