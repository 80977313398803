/* ==========================================================================
	BASE FORM STYLES
========================================================================== */

//Give form elements some cursor interactions...
button,
select,
option {
	cursor:pointer;
}
	%text-input:active,
	%text-input:focus,
	textarea:active,
	textarea:focus{
		cursor:text;
		outline:none;
	}

%text-input {
	color: $color-text;
	border:1px solid #dedede;
	border-radius:0;
	outline:none;
	-webkit-appearance: none;

	&:focus {
		@include mq($regular, false, max) {
			@include font-rem(16);
		}
	}
}

select {
	background-image:url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2219%22%20height%3D%229%22%20viewBox%3D%220%200%2019%209%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ctitle%3Eselect%3C%2Ftitle%3E%3Cg%20id%3D%22Icons-export%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Cg%20id%3D%22select%22%20fill%3D%22%23B4B4B4%22%3E%3Cpath%20d%3D%22M19%200L9.5%209%200%200h19z%22%20id%3D%22Rectangle-74%22%2F%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E');
	background-repeat:no-repeat;
	background-position:right 10px center;
	background-color:$white;
	-webkit-appearance: none;
     -moz-appearance: none;
     appearance: none;

    .contact_widget_form & {
    	background-image:url('../img/contact/arrow-down.svg');
    }
}
select::-ms-expand {
    display: none!important;
}
#fax {
	@extend .hidden;
}


/* ==========================================================================
	CHECKBOX STYLES
========================================================================== */

.checkbox {
	position:relative;
	border:1px solid $color-base;
	float:left;
	@include remit(margin-right, 6);
	@include remit(margin-bottom, $hsu);
	width:90px;
	text-align:center;

	span {
		display:block;
		@include remit(padding, 6 10);
		transition:$g-trans;

		@include hover {
			color:$white;
		}
	}

	input {
		position:absolute;
		opacity:0;
		z-index:-1;
		width:100%;
	}

	input:checked + span {
		background-color:$color-base;
		color:$white;
	}

	span:hover, input:checked + span {
		background-color: $color-sub;
	}
}

/* ==========================================================================
	ALL FORM STYLES
========================================================================== */

.required {
	color:$color-sub;

	&:before {
		content: "*";
		margin-right: 3px;
		color:$color-error;
	}
}

.submit {
	@extend %btn;
	background-color:$color-base;
	color:$white;
	position:relative;

	@include hover() {
		background-color:$color-sub;
	}

	&--arrow {
		&:after {
			@include arrow(8px, 45deg, 2px);
			content: '';
			position:relative;
			display:inline-block;
			@include remit(top, -1);
			@include remit(margin-left, 4);
		}
	}
}

.instructions {
	@include font-size(11, $hsu, 16);
	font-style:italic;
}


.form {
	label {
		@include font-size(13);
		font-weight:600;

	}

	input[type="text"],
	input[type="email"],
	input[type="tel"],
	input[type="number"],
	input[type="password"],
	input[type="search"],
	input[type="tel"],
	select,
	textarea {
		@extend %text-input;
		@include placeholder(lighten($color-text, 30%), 0.8, 14);
		transition: box-shadow 0.15s;

		&:focus,
		&.required:focus {
			border: 1px solid rgba($orange, .5);
			box-shadow: 0 0 0.2rem 0.1rem rgba($orange, .25);
			outline: none;
		}

		&.required {
			font-weight:400;
		}

		&.has-error,
		&.error {
			border: 1px solid $color-error;
			background-color: $bg-error;
			color: $color-error;
			@include placeholder($color-error)
		}
	}

	input[type="search"] {
		@include remit(margin-bottom, $bsu);
	}

	&__row {
		@extend %cf;
	}

	&__item {
		@include remit(margin-bottom, $bsu);

		$cols: 6;
		//@for $i from 1 through $cols {
			//&--#{24/$i} {
				//@include mq($large) {
					//@include gallery(24/$i);
				//}
			//}
		//}
	}
}

/* ==========================================================================
	USER INPUT FORM STYLES
========================================================================== */

.form--userInput {
	input[type="text"],
	input[type="email"],
	input[type="tel"],
	input[type="password"],
	input[type="search"],
	select,
	textarea {
		@include remit(height, 45);
		@include remit(padding, 8 6);
		width:100%;
	}

	textarea {
		height:auto;
	}

	input[type="text"]:disabled {
		background: transparent;
	}
}


/* ==========================================================================
	CPR PAGE FORM STYLES
========================================================================== */

.form__rows,
.form--cpr .form__row {
	@extend %cf;
}

@include mq($large) {

	.form--cpr {

		.form__row {

			float: left;
			margin-right: -100%;
			width: 49.3%; // magic number

			&:nth-child(odd) {
				clear: both;
			}

			&:nth-child(even) {
				clear: none;
				margin-left: 50.7%; // magic number
			}

			// Match final 2 rows.
			&:nth-last-child(-n + 2) {
				clear: both;
				margin-left: 0;
				margin-right: 0;
				width: 100%;
			}

		}

		label[for="form-input-fileUpload"] {
			display: inline-block;
			margin-top: 2rem;
		}

	}

}


/* ==========================================================================
   HEADER SEARCH FORM STYLES
========================================================================== */

.form--searchHeader {
	font-size:0;

	p {
		display:inline-block;
	}

	input[type="search"] {

		width:70%;
		display:inline-block;
		vertical-align: top;
		@include remit(height, 40);
		@include remit(padding, 10);
		@include font-rem(16);

		&:focus {
			@include placeholder($white, 0.6, 14);
			border:1px solid rgba($white, .5);
			background-color: $color-sub;
			color:$white;
			outline: none;
			box-shadow: none;
		}

	}

	button {
		display:inline-block;
		vertical-align: top;
		@include remit(height, 62);
		min-width:auto;

		@include hover() {
			background-color:$color-tri;
		}
	}
}

/* ==========================================================================
	ERROR STYLES
========================================================================== */

.has-error, .error {
	margin-bottom:0;
	color: $color-error;
	font-weight:bold;
	// @include shake($delay:0.6);
}

span.has-error,
span.error {
	@include font-size(13);
}

input.has-error,
input.error {
	@include remit(margin-bottom, 4);
}


/* ==========================================================================
	REQUIRED STYLES
========================================================================== */

// .is-required {
// 	color:$color-sub !important;
// }
//
//
//
.widget_icon{
	color: #F66C21;
	margin-right: 10px;
}
.toggle_widget{
	margin-left: 10px;
	-ms-transform: rotate(267deg); /* IE 9 */
	-webkit-transform: rotate(267deg); /* Safari 3-8 */
	transform: rotate(267deg);
	transition: all 0.3s ease-in-out;
	.active &{
		-ms-transform: rotate(360deg); /* IE 9 */
		-webkit-transform: rotate(360deg); /* Safari 3-8 */
		transform: rotate(360deg);
	}
}
.contact-us-widget-list{
	.contact-wiget-item{
		margin: 0 0 1.5rem 0;
		padding: 0 0 1.5rem 0;
		border-bottom: 1px solid #d9d9d9;
		a{
			color: #000;
			@include font-size(15);
			font-weight: bold;
		}
		.contact_widget_form{
			margin-top: 1.5rem;
		}
	}

}
.widget_back_button{
	a{
	@include font-size(15);
		font-weight: bold;
		color: #000;
	}
	margin: 0 0 1.5rem 0;
	padding: 0 0 1.5rem 0;
	border-bottom: 1px solid #d9d9d9;
}

.contact_widget_form{
	h4{
		font-family: "Roboto",sans-serif;
		font-weight: bold;
	}
	a{
		color: #f36d21;
		font-weight: normal;
	}
	h3{
		margin: 0 0 1.5rem 0;
		font-weight: bold;
		color: #000;
	}
	.errors {
		margin: 10px 0 0 0!important;
		padding: 0;
		@include font-size(13);
		list-style: none;
		li{
			list-style: none;
		}

	}
	.has-error,
	.error{
		input, select, textarea{
			border: 1px solid #b94a48;
		}
	}
	button{
		background: #f36d21;
		@include font-size(15);
		color: #ffffff;
		border: 0;
		display: inline-block;
		line-height: 100%;
		height: 45px;
		padding: 10px 20px;
		margin:0;
	}
	.row{
		margin: 0 0 1.5rem 0;
		&:last-child{
			margin-bottom: 0;
		}
	}
	.form-group {
		.custom_upload{
			display: block;
			margin: 0;
			width: 100%;
			font-family: "Roboto", sans-serif;
			border: 1px solid #dedede;
			font-weight: normal;
			position: relative;
			overflow: hidden;
			.upload_button{
				position: absolute;
				top: -1px;
				right: 0;
				display: block;
				height: 45px;
				line-height: 45px;
				font-weight: bold!important;
				background: #D9D9D9;
				color: #6A6A6A!important;
				padding: 0 20px;
			}
		}

		input[type="file"]{
			// display: none;
		}
		// label, input, select,textarea{
		// 	display: block;
		// 	margin: 0;
		// 	width: 100%;
		// 	font-family: "Roboto", sans-serif;
		// 	font-weight: normal;
		// 	&:focus{
		// 		background: #fff;
		// 		color: #000000;
		// 		border: 1px solid #000000;
		// 	}
		// }
		// label{
		// 	@include font-size(13);
		// 	color: #000000;
		// 	margin-bottom: 5px;
		// }
		// input, select,.custom_upload{
		// 	@include font-size(14);
		// 	color: #666666;
		// 	margin: 0!important;
		// 	padding: 0 10px;
		// 	height: 45px;
		// 	line-height: 45px;
		// }

		#form-input-topic,.holding_text{
			color: #d1d1d1;
		}

		.populated{
		color: #6A6A6A!important;
		}

		textarea{
			@include font-size(14);
			color: #666666;
			margin: 0!important;
			padding: 10px 10px;
			min-height: 120px;
			line-height: 100%;
		}
		&.col-lg-6{
			width: 49%;
			float: left;
			&:first-child{
				margin-right: 2%;
			}
		}
		&.col-lg-12{
			width: 100%;
			float: none;
		}
	}

	&#technical_support {

		.form-group .custom_upload,
		.form-group input,
		.form-group select {
		    font-size: 14px;
		    font-size: .875rem;
		    line-height: 1.71429;
		    margin-bottom: 24px;
		    margin-bottom: 1.5rem;
		    color: #666;
		    margin: 0!important;
		    padding: 0 10px;
		    height: 45px;
		    line-height: 45px;
		}

		.form-group input,
		.form-group label,
		.form-group select,
		.form-group textarea {
		    display: block;
		    margin: 0;
		    width: 100%;
		    font-family: Roboto,sans-serif;
		    font-weight: 400;
		}

		input[type="file"]{
			padding: 0;
		    line-height: 0;
		    height: auto;
		}
	}
}


/* MailChimp Form Embed Code - Classic - 12/17/2015 v10.7 */
#mc_embed_signup form {display:block; position:relative; text-align:left; padding:10px 0 10px 3%}
#mc_embed_signup h2 {font-weight:bold; padding:0; margin:15px 0; font-size:1.6em;}
#mc_embed_signup input {border: 1px solid #ABB0B2; -webkit-border-radius: 3px; -moz-border-radius: 3px; border-radius: 3px;}
#mc_embed_signup input[type=checkbox]{-webkit-appearance:checkbox;}
#mc_embed_signup input[type=radio]{-webkit-appearance:radio;}
#mc_embed_signup input:focus {border-color:#333;}
#mc_embed_signup .button {clear:both; background-color: #aaa; border: 0 none; border-radius:4px; transition: all 0.23s ease-in-out 0s; color: #FFFFFF; cursor: pointer; display: inline-block; font-size:15px; font-weight: normal; height: 32px; line-height: 32px; margin: 0 5px 10px 0; padding: 0 22px; text-align: center; text-decoration: none; vertical-align: top; white-space: nowrap; width: auto;}
#mc_embed_signup .button:hover {background-color:#777;}
#mc_embed_signup .small-meta {font-size: 11px;}
#mc_embed_signup .nowrap {white-space:nowrap;}

#mc_embed_signup .mc-field-group {clear:left; position:relative; width:96%; padding-bottom:5%; min-height:50px;}
#mc_embed_signup .size1of2 {clear:none; float:left; display:inline-block; width:46%; margin-right:4%;}
* html #mc_embed_signup .size1of2 {margin-right:2%; /* Fix for IE6 double margins. */}
#mc_embed_signup .mc-field-group label {display:block; margin-bottom:3px;}
#mc_embed_signup .mc-field-group input {display:block; width:100%; padding:8px 0; text-indent:2%;}
#mc_embed_signup .mc-field-group select {display:inline-block; width:99%; padding:5px 0; margin-bottom:2px;}

#mc_embed_signup .datefield, #mc_embed_signup .phonefield-us{padding:5px 0;}
#mc_embed_signup .datefield input, #mc_embed_signup .phonefield-us input{display:inline; width:60px; margin:0 2px; letter-spacing:1px; text-align:center; padding:5px 0 2px 0;}
#mc_embed_signup .phonefield-us .phonearea input, #mc_embed_signup .phonefield-us .phonedetail1 input{width:40px;}
#mc_embed_signup .datefield .monthfield input, #mc_embed_signup .datefield .dayfield input{width:30px;}
#mc_embed_signup .datefield label, #mc_embed_signup .phonefield-us label{display:none;}

#mc_embed_signup .indicates-required {text-align:right; font-size:11px; margin-right:4%;}
#mc_embed_signup .asterisk {color:#e85c41; font-size:150%; font-weight:normal; position:relative; top:5px;}
#mc_embed_signup .clear {clear:both;}

#mc_embed_signup .mc-field-group.input-group ul {margin:0; padding:5px 0; list-style:none;}
#mc_embed_signup .mc-field-group.input-group ul li {display:block; padding:3px 0; margin:0;}
#mc_embed_signup .mc-field-group.input-group label {display:inline;}
#mc_embed_signup .mc-field-group.input-group input {display:inline; width:auto; border:none;}

#mc_embed_signup div#mce-responses {float:left; top:-1.4em; padding:0em .5em 0em .5em; overflow:hidden; width:90%; margin: 0 5%; clear: both;}
#mc_embed_signup div.response {margin:1em 0; padding:1em .5em .5em 0; font-weight:bold; float:left; top:-1.5em; z-index:1; width:80%;}
#mc_embed_signup #mce-error-response {display:none;}
#mc_embed_signup #mce-success-response {color:#529214; display:none;}
#mc_embed_signup label.error {display:block; float:none; width:auto; margin-left:1.05em; text-align:left; padding:.5em 0;}

#mc-embedded-subscribe {clear:both; width:auto; display:block; margin:1em 0 1em 5%;}
#mc_embed_signup #num-subscribers {font-size:1.1em;}
#mc_embed_signup #num-subscribers span {padding:.5em; border:1px solid #ccc; margin-right:.5em; font-weight:bold;}

#mc_embed_signup #mc-embedded-subscribe-form div.mce_inline_error {display:inline-block; margin:2px 0 0; padding:5px 10px; background-color:rgba(255,255,255,0.85); -webkit-border-radius: 3px; -moz-border-radius: 3px; border-radius: 3px; font-size:14px; font-weight:normal; z-index:1; color:#e85c41;}
#mc_embed_signup #mc-embedded-subscribe-form input.mce_inline_error {border:2px solid #e85c41;}

#mc_embed_signup.custom-mailchimp-popup {
    background: rgba(0,0,0,0.8);
    clear:left;
    font:16px Helvetica,Arial,sans-serif;
    position: fixed;
    top: 0;
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    max-height: 100%;
    overflow-y: auto;
    display: none;

    .popup__header {

    	max-width: 501px;
	    margin: 0 auto;
	    position: relative;
	    min-height: 20px;
	    background: black;

	    @media only screen and (max-width: 499px) {
			// max-height: 50px;
	    }

	    .close {
	    	cursor: pointer;
		    position: fixed;
		    top: 10px;
		    right: 10px;
		    width: 25px;
		    height: 25px;
		    border-radius: 50%;
		    background: white;
		    text-align: center;
		    line-height: 25px;
	    }
    }

    h3 {
    	color: #666;
    }

    form {
    	height: 100%;
    	max-width: 501px;
    	margin: 0 auto;
    	background: white;
    	padding: 20px;

    	.mc-field-group {
    		margin: 0;
    		width: 100%;

    		label {
		    	font-size: 14px;
		    	color: black;
		    }
    	}
    }

    input, button {
    	margin: 0;
    }

    .email {
        font: 16px Helvetica,Arial,sans-serif;
        margin-bottom: 0;
    }

    #mce-responses {
        float: left;
        top: -1.4em;
        padding: 0 .5em;
        overflow: hidden;
        width: 100%;
        margin: 0;
        clear: both;
    }

    .response {
        margin: 1em 0;
        padding: 0;
        font-weight: 700;
        float: left;
        top: -1.5em;
        z-index: 1;
        width: 100%;
    }

    @media only screen and (min-width: 500px) {
        padding: 5% 0;
        position: fixed;

        .popup__header {

            .close {
                position: absolute;
                top: -10px;
                right: -10px;
            }
        }

        form {
            height: auto;
        }
    }
}
