/* ==========================================================================
	BASE LINK STYLES
========================================================================== */

a {
	// Property, Duration, Animation Type
	transition: $g-trans;
	color:$color-link;
	text-decoration:none;

	@include hover() {
		color:$color-hover;
		text-decoration:none;
	}
}

.footer a {
	color:$white;
	text-decoration:none;

	@include hover() {
		color:$color-sub;
	}
}

.textFooterAmasci.textFooterAmasci, .courseDetails__text a {
	color:rgba($color-sub, 0.5);

	@include hover() {
		color:$color-sub;
	}
}


a[href^="tel:"] {
	color:$color-sub;

	.header &,
	.footer & {
		color:$white;
	}
}

/* ==========================================================================
	BUTTON STYLES
========================================================================== */

%btn, .btn {
	@extend %btn-reset;
	transition: $g-trans;
	@include remit(padding, $hsu 16);
	text-decoration:none;
	position:relative;
	text-align:center;
	display:inline-block;
	min-width:160px;
	color:$white;
	font-family:$font-header;
	cursor:pointer;

	&:last-of-type {
		margin-bottom:0;
	}

  &--base {
    background-color:$color-base;
    color:$white;

    @include hover() {
      background-color:$color-hover;
     color:$white;

    }
  }
  &--white {
	background-color:$white;
    color:$orange;

    @include hover() {
	  background-color:$white;
	  color:$orange;
    }
  }
}

// /* ==========================================================================
//   MISC LINK STYLES
// ========================================================================== */

// .top {
// 	@extend .icon-arrow-up;
// 	@include font-size(24);
// 	@include remit(padding, $hsu);
// 	@include remit(right, $bsu);
// 	@include remit(bottom, $bsu);
// 	cursor:pointer;
// 	position:fixed;
// 	background-color:$color-sub;
// 	color:$white;
// 	display: none;

// 	&:hover,&:focus,&:active {
// 		background-color:$color-hover;
// 	}
// }