/* ==========================================================================
	LOGO LSITINGS LAYOUT STYLES
========================================================================== */

.logoListing {
	list-style:none;
	@include remit(margin-bottom, $bsu*2);
	@extend %cf;

	&:not(:last-of-type) {
		border-bottom:1px solid $lightgrey;
		@include remit(padding-bottom, $bsu*2);
	}

	&__img {
		border:1px solid $lightgrey;
		@include remit(padding, $bsu);
		height:224px;
		transform-style: preserve-3d;
		vertical-align:middle;
		text-align:center;

		@include mq($small) {
			width:span(16);
		}

		@include mq($medium) {
			@include span(8);
		}


		img {
			position: relative;
			top: 50%;
			transform: translateY(-50%);
		}

		&--no-imge {
			@extend %no-image;
		}
	}

	&__content {
		@include remit(padding-top, $bsu);

		@include mq($medium) {
			@include span(16 last);
		}
	}
}

/* ==========================================================================
	LOGO LISTING STYLING STYLES
========================================================================== */

.logoListing {

	&__btn {
		transition:$g-trans;
		color:$color-sub;
		position:relative;
		font-family:$font-body;
		@include font-rem(13);
		@include remit(margin-left, $bsu);

		&:before {
			content:'';
			// border-left:2px solid $lightgrey;
			// @include remit(margin-right, $hsu);
			background-color:$lightgrey;
			width:2px;
			bottom:0;
			// @include remit(top, 2);
			top:15%;
			height:80%;
			position:absolute;
			@include remit(left, -14);
		}

		&:after {
			@include arrow(8px, 45deg, 2px);
			content: '';
			position:relative;
			display:inline-block;
			@include remit(top, -1);
			@include remit(margin-left, 4);
		}
	}
}