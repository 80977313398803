/* ==========================================================================
  Olark
========================================================================== */

// #olark-wrapper .olark-launch-button {
//     width: 100% !important;
// }

#olark-wrapper .olark-launch-button .olark-button-text {
    font-size: 12px !important;
}

/* IE9, IE10, IE11 */
@media screen and (min-width:0\0) {
  #olark-wrapper .olark-notification-badge .olark-notification-badge-text { line-height: 15px !important;}
}