/* ==========================================================================
  BANNEWR CAROUSEL STYLES
========================================================================== */

.banner--carousel {
  min-height:400px;

  .slick-dots--grey {
      @media screen and (max-width: 759px){
        position: absolute!important;
        width: 100%!important;
        z-index: 100!important;
        top: -40px!important;
      }
  }

  .btn {
     font-family: "Roboto",sans-serif;
     @include font-size(16);
  }
  background-color: #666666;

  @media screen and (max-width: 759px){
    min-height: 600px;
    background-position: 100% 0;
    background-size: 200%;
    padding-top: 63%;
  }
}

.banner__content {
  max-width:30rem;

  position:relative;
  z-index:30;
  color:$white;
  @include remit(padding-top, 30);
  @include remit(padding-bottom, 40);

  p {
    @include font-size(15, yes, 15*1.5);
  }

  @media screen and (max-width: 759px){
    max-width:100%;
    @include remit(padding-top, 10);
    @include remit(padding-bottom, 10);
  }
}

.banner__inline__title{
  margin-top: 24px;
  margin-bottom: 30px;

  .banner__title {
    color: #fff;
    display: inline;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
    line-height: 1.4;
    font-family: "Roboto",sans-serif;
    @include font-size(36, yes, 36*1.2);

    @include mq($small) {
      @include font-size(40, yes, 40*1.2);
    }
    display: inline;
    font-weight: bold;
    margin-bottom: 0;
    span{
      line-height: 1.4;
      padding: 0.45rem 20px;
      border-left: 4px solid #f36c21;
      background-color: #000000;
      @media screen and (max-width: 759px){
        display: inline-block;
        padding: 0 20px;
      }
    }
  }
}

.banner__copy {
  position: relative;
  p{
    font-size: 18px;
    margin-bottom: 30px;
  }
  img{
    position: absolute;
    bottom: -97px;
    right: 22px;
  }
}

.slick-current {
  .banner__content {
      @include fadeIn($delay:0.5s);
  }

  .banner__title {
    @include fadeIn($delay:0.5s);
  }

  .banner__copy {
    @include fadeIn($delay:0.5s);
  }

  .btn {
     @include fadeIn($delay:0.5s);
  }
}

.fullscreen-videoPopup {
  position: fixed;
  top: 0; right: 0; bottom: 0; left: 0;
  z-index: 999;
  display: none;
}
.fullscreen-videoPopup.active {
  display: block;
}
.fullscreen-videoPopup__close {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 9;
  margin: 0 auto;
  padding: 10px;
  max-width: 800px;
  border-radius: 5px;
  background: black;
  color: white;
  text-align: center;
  cursor: pointer;
}
.embed-container {
  width: 100%;
  height: 100%;
  position: relative;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  width: 100%;
}