/* ==========================================================================
	EVENTS LAYOUT STYLES
========================================================================== */

.event {
	list-style:none;
	@include remit(margin-bottom, $bsu*2);
	@extend %cf;

	&:not(:last-of-type) {
		border-bottom:1px solid $lightgrey;
		@include remit(padding-bottom, $bsu*2);
	}

	&__img {
		border:1px solid $lightgrey;
		@include remit(padding, $bsu);
		height:224px;
		transform-style: preserve-3d;
		vertical-align:middle;
		text-align:center;

		@include mq($small) {
			width:span(16);
		}

		@include mq($medium) {
			@include span(8);
		}


		img {
			position: relative;
			top: 50%;
			transform: translateY(-50%);
		}

		&--no-imge {
			@extend %no-image;
		}
	}

	&__content {
		@include remit(padding-top, $hsu);

		@include mq($medium) {
			@include span(16 last);
		}

		p {
			@include font-rem(14);
		}
	}
}

/* ==========================================================================
	LOGO LISTING STYLING STYLES
========================================================================== */

.event {

	.date {
		@include remit(margin-bottom, $hsu);
	}

	&__btn {
		transition:$g-trans;
		position:relative;
		@include font-rem(14);

		&:after {
			@include arrow(8px, 45deg, 2px);
			content: '';
			position:relative;
			display:inline-block;
		}
	}

	&__description {

		&:last-child() {
			margin-bottom:0 !important;
			border: 1px solid red;
		}
	}
}