/* ==========================================================================
	TESTING LAYOUT STYLES
========================================================================== */

.testing {
	@include remit(margin-bottom, $g-spacing);
	div {
		// border:1px solid red;
	}

	&__content {
		@extend %cf;
	}

	&__description {
		@include mq($medium) {
			@include span(10);
		}
	}

	&__cables {
		@include remit(margin-bottom, $bsu);
		clear:both;

		@include mq($medium) {
			@include span(7 last);
			margin-bottom:0;
			clear:none;
		}
	}

	&__cableImg {
		@extend %img-circle;
		@include remit(margin-bottom, $bsu);
		@include remit(margin-top, -36);
		@include remit(margin-left, $bsu);
		float:right;
	}

	&__equipment {
		@include mq($medium) {
			@include span(10);
			@include remit(padding-top, $bsu);
		}
	}

	&__img {
		@include remit(margin-bottom, $bsu);

		@include mq($small) {
			@include span(8 last);
			@include remit(margin-left, $bsu);
		}

		@include mq($medium) {
			@include span(6);
			margin-bottom:0;
			margin-left:0;
		}
	}
}


/* ==========================================================================
	TESTING STYLING STYLES
========================================================================== */

.testing {

	&__subTitle {
		@include remit(margin-bottom, $hsu);
		@include font-rem(16);
		font-weight:500;
	}
}