/* ==========================================================================
	PANEL STYLES
========================================================================== */
.relatedContent {
	background-color:$lightgrey;
	@include remit(padding-top, $g-spacing);
	@include remit(padding-bottom, $g-spacing);

	&__title {
		color:$color-sub;
		font-family:$font-body;
		@include remit(margin-bottom, 36);
	}
}

.relatedContent--light {
	background-color: #efefef;
}

.relatedContent--white {
	background-color: $white;
}
