/* ==========================================================================
	LAYOUT MODULES
========================================================================== */


.home--primary {
	@include mq($regular) {
		@include span(isolate 14 at 2);
	}

	@include mq($large) {
		@include span(isolate 13 at 2);
	}
}

.article--primary {
	@include mq($regular) {
		@include span(isolate 14 at 2);
	}

	@include mq($large) {
		@include span(isolate 13 at 2);
	}
}

.article--center {
	@include mq($regular) {
		@include span(8 of 12);
		float: none;
		margin: 0 auto;
	}
}

.results--primary {
	@include mq($regular) {
		@include span(isolate 22 at 2);
	}
}

.article--sidebar {
	@include remit(padding-top, $g-spacing);

	@include mq($regular) {
		@include span(8 last);
	}
}

.article--share {

	@include mq($regular) {
		@include span(8 last);
		float: none;
		position: absolute;
		@include remit(right, $bsu);
		top: 0;
	}

}

.home--sidebar {
	@include remit(padding-top, $g-spacing);

	@include mq($regular) {
		@include span(8 last);
		padding-top:0;
	}
}

.main {
	@extend %cf;
	position:relative;
	@include remit(padding-top, $g-spacing);

	&--padding-bottom {
		@include remit(padding-bottom, $g-spacing);
	}
}
