/* ==========================================================================
	NAVIGATION STYLES
========================================================================== */

// .nav {
// 	li {
// 		list-style:none;
// 		text-align:center;

// 		@include mq($nav-bp) {
// 			display:inline-block;
// 		}
// 	}

// 	a {
// 		display:block;
// 	}
// }


/* ==========================================================================
	 MAIN NAV
========================================================================== */

.nav--main {
	font-size:0;

	li {
		@extend %caps;
		position:relative;
		@include font-size(14, no);
		color:$white;
		list-style:none;


		@include mq($nav-bp) {
			display:inline-block;
			margin-bottom:0;

			&:not(:last-child) {
				@include remit(margin-right, (20-$bsu));

				@include mq($large) {
					@include remit(margin-right, (40-$bsu));
				}
			}
		}

		&.item-with-ul a {
			@include remit(padding-right, 27);
		}
	}

	a {
		position:relative;
		display:block;
		color:currentColor;
		border-bottom:1px solid rgba($white, 0.2);
		@include remit(padding, $hsu);


		@include mq($nav-bp) {
			border-bottom:0;
			@include remit(padding, $hsu);
		}

		@include hover() {
			color:$color-base;
			text-decoration:none;
		}
	}

	.nav-item--is-top-parent,.nav-item--is-current {
		color:$color-base;
	}

	.touch-button {
		display: inline-block;
		position: absolute;
		z-index: 999;
		@include remit(top, 6);
		@include remit(right, 10);
		@include remit(width, 35);
		@include remit(height, 35);
		@include remit(line-height, 32);
		// border-radius:50%;

		background-color:darken($color-base, 5%);
		text-align: center;
		// @include remit(padding, $hsu);

		@include mq($nav-bp) {
			// @include remit(top, -2);
			@include remit(right, -2);
			background-color:transparent;
		}

		@include hover() {
			cursor: pointer;
		}
	}

	//===============================//
	//	DROPDOWN STYLES
	//===============================//

	$second_level: $color-sub;
	// $third_level: $color-base;
	// $fourth_level: lighten($color-base, 20%);

	// .item-with-ul a {
	// 	@include remit(padding, 3 0);
	// }

	li ul li {
		@include font-rem(13);
		margin-right:0 !important;
	}

	li ul li a {
		display: block;
		@include remit(padding, $hsu $bsu);
		background: $second_level;
		color:currentColor;

		@include hover() {
			@include remit(padding-left, $bsu*1.5);
			color:$white;
			background-color:$color-tri;
		}
	}

	.hovered > a {
		background-color:$second_level;
		color:$color-base;
	}

	.arrow {
		@include arrow(7px, 135deg);
		display:inline-block;
		position:relative;
		color:$white;
	}
}


/* ==========================================================================
	 TOP NAV
========================================================================== */

.nav--top {

	li {
		display:inline-block;
		list-style:none;
		@include font-rem(12);

		&:not(:last-of-type) {
			@include remit(margin-right, 60);
		}
	}

	a {
		display:block;
		color:$white;
	}

	.icon {
		height:14px;
		width:14px;
		@include remit(margin-right, 2);
	}
}


/* ==========================================================================
	 TOP NAV
========================================================================== */

.nav--footer {

	li {
		list-style:none;
		@include font-rem($footer-font-size);
		text-align:right;
		display:inline-block;
		position:relative;
		vertical-align:middle;
		@include remit(margin-top, 10);

		@include mq($small) {
			@include remit(margin-top, 0);
		}

		&:not(:last-of-type) {
			@include remit(margin-right, 5);
			@include remit(padding-right, 5);

			&:after {
				content: '';
				position:absolute;
				@include remit(top, 3);
				bottom:0;
				right:0;
				@include remit(height, 10);
				display: block;
				width: 1px;
				background: rgba($white, 0.5);
			}

			@include mq($small) {
				@include remit(margin-right, 15);
				@include remit(padding-right, 15);
			}
		}
	}

	a {
		display:block;
		color:$white;
	}
}


/* ==========================================================================
	 SIDEBAR SCROLLING  NAV
========================================================================== */
.nav--scroll {

	li {
		list-style:none;
		@include remit(margin-bottom, $bsu);
	}

	a {
		&:before {
			@include arrow(8px, 135deg, 2px);
			content: '';
			position:relative;
			display:inline-block;
			@include remit(top, -3);
			@include remit(margin-right, 4);
		}
	}
}



/* ==========================================================================
	 SIDEBAR  NAV
========================================================================== */

.nav--sidebar {

	li {
		list-style:none;
		@include font-size(15, yes, 15*1.5);

		&:first-child {
			@include remit(margin-top, $bsu);
		}
	}

	.date {
		@include font-size(11, 6, 11);
	}
}
