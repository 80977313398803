/* ==========================================================================
  AUTOCOMPLETE STYLES
========================================================================== */

.autocomplete-suggestions {
	// border: 1px solid $grey;
	background: $white;
	overflow: auto;
	z-index: unset!important;
}

.autocomplete-suggestion {
	@include remit(padding, $hsu);
	white-space: nowrap;
	overflow: hidden;
}

.autocomplete-selected {
	background-color:$grey;
}

.autocomplete-suggestions strong {
	font-weight: 600;
	color: $color-base;
	text-decoration:underline;
}

.autocomplete-group {
	background-color:$color-base;
	@include remit(padding, $hsu);

	strong {
		color:$white !important;
		text-decoration:none !important;
	}
}