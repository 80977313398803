.cookie-notice {
    background-color: #666;
    color: #fff;
    padding: 30px;
    position: fixed;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 9000001; // 1 higher than `.olark-launch-button-wrapper`

    @media (min-width: 768px) {
        width: 340px;
        right: 20px;
        bottom: 20px;
    }
}

.cookie-notice__heading {
    font-size: 16px;
    font-weight: bold;
    color: inherit;
}

.cookie-notice__body {
    font-size: 15px;

    a {
        color: inherit;
        font-weight: bold;

        &:hover,
        &:focus {
            color: inherit;
            text-decoration: underline;
        }
    }
}

.cookie-notice__btn {
    min-width: 1px;
    font-size: 15px;
}


#olark-wrapper .olark-launch-button-wrapper {
    bottom: 65px !important;
}