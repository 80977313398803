/* ==========================================================================
	CARD POPUP MODAL STYLES
========================================================================== */
.cardPopUp {

	&__modal {
		padding:5%;
		position: relative;
		height:100%;

		@include mq($regular) {
			padding:3%;
			@include remit(max-width, 1600);
			margin:auto;
		}
	}

	&__img {
		width:100%;
		position: relative;
		@include remit(margin-bottom, $bsu);

		@include mq($regular) {
			@include span(23 last);
		}
	}

	&__content {
		@include slideInLeft($delay:0.2s);

		@include mq($regular) {
			width:span(16);
			@include remit(padding, $bsu);
			background-color:$white;
			position:absolute;
			// @include remit(top, );
			@include remit(top, -$bsu);
			z-index:300;
		}

		p {
			@include font-size($p-font-size);
			// @include remit(margin-left, 20);
		}
	}
}