.certificate-table-wrapper {
    --padding: 0.75rem;
    --width: min(87.5em, calc(100vw - (var(--padding) * 2) - var(--scrollbar, 0px)));
    width: var(--width);
    height: calc(100vh - var(--scrollbar, 0px));
    overflow: auto;

	@include mq($small) {
        --padding: 1.5rem;
    }

	@include mq($regular) {
        overflow: visible;
        height: auto;
    }
}

.certificate-table {
    border-collapse: collapse;
    min-width: 100%;
    font-size: 14px;
    color: #000;
}

.certificate-table__head,
.certificate-table__body {
    display: block;
}

.certificate-table__row {
    display: grid;
    grid-template-columns:
        minmax(10em, 3fr) // Account Name
        minmax(10em, 5fr) // Location
        minmax(4em, 1fr)  // Year
        minmax(8em, 2fr)  // Certificate No.
        minmax(8em, 2fr)  // Type
        minmax(17em, 9fr) // Description
        minmax(5em, 2fr) // Date Cancelled
        ;

    @include mq($regular) {
        grid-template-columns:
            minmax(120px, 1fr) // Account Logo
            minmax(10em, 3fr)  // Account Name
            minmax(10em, 3fr)  // Location
            minmax(3em, 1fr)   // Year
            minmax(3em, 2fr)   // Certificate No.
            minmax(5em, 2fr)   // Type
            minmax(10em, 3fr)  // Description
            minmax(5em, 2fr)  // Date Cancelled
            ;
    }

    &[hidden] {
        display: none;
    }
}

.certificate-table__head {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 1;
}

.certificate-table__cell {
    border-bottom-style: solid;
    border-color: #a0a0a0;
    border-width: 1px;
    height: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include mq($regular) {
        padding: 20px;
    }

    > p {
        margin: 0;
    }
}

.certificate-table__cell--hide-mobile {
    display: none;

    @include mq($regular) {
        display: flex;
    }
}

.certificate-table__cell--heading {
    text-align: left;
    color: #f36d21;
}

.certificate-table__cell--fixed {
    position: sticky;
    left: 0;
    background-color: #fff;
    align-self: start;

    @include mq($regular) {
        position: static;
    }
}

.certificate-table__head .certificate-table__cell {
    border-width: 2px;
}

.certificate-table__head .certificate-table__cell--fixed {
    z-index: 2;
}

.certificate-table__list {
    margin-top: 1em;
    display: table;

    > div {
        display: table-row;

        > dt {
            color: #f36d21;
            font-weight: bold;
            display: table-cell;
            padding: 0.1em 0.2em 0.1em 0;

            &::after {
                content: ":";
            }
        }

        > dd {
            display: table-cell;
            padding: 0.1em 0 0.1em 0.2em;
            margin: 0;
        }

    }
}

.entryTeaserClient__logo--certificate-table {
    @include mq($medium) {
        margin: 0;
        width: auto;
        float: none;
    }
}

.pagination--certificate-table {
    margin-top: 4rem;
    text-align: center;
}

.lead--certificate-table {
    font-size: 16px;
}
