.case-study__section {
	@include remit(padding-bottom, $bsu * 2);
	@include remit(padding-top, $bsu * 2);
}

.case-study__section--orange {

	background-color: $color-base; /* ; */

	h3,
	p,
	ul {
		color: $white;
	}

}

.case-study__section--grey {

	background-color: $color-text; /* ; */

	h3,
	p,
	ul {
		color: $white;
	}

}
