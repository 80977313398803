/* ==========================================================================
   LISTS WITH SIGNIFICANT NUMBERS
========================================================================== */

ol.significant-numbers { // stronger selector overrides `.content ol`

	counter-reset: significant-numbers;
	list-style-type: none;

	> li {

		counter-increment: significant-numbers;
		position: relative;

		&::before {
			content: counter(significant-numbers);
			color: $color-base;
			@include font-rem($h2-font-size);
			@include remit(left, -$bsu);
			line-height: 1;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}

	}

}


/* ==========================================================================
   STEPS WITH TRIANGLES BETWEEN THEM
========================================================================== */

ol.arrow-steps { // stronger selector overrides `.content ol`

	color: $color-text;
	display: flex;
	flex-direction: column;
	list-style-type: none;
	@include remit(margin-bottom, $bsu);

	@include mq($regular) {
		flex-direction: row;
		@include remit(margin-bottom, ($bsu * 2));
	}

	> li {

		background-color: currentColor;
		border-radius: $g-radius / 2;
		color: inherit;
		display: flex;
		flex-basis: 20%; // works magic ^_^
		flex-direction: column;
		justify-content: center;
		@include remit(margin-bottom, $bsu);
		@include remit(padding, $hsu);
		position: relative;

		@include mq($regular) {
			margin-bottom: 0;
			@include remit(margin-right, $bsu);
		}

		&.arrow-steps__v-narrow {
			flex-basis: 10%;
		}

		&.arrow-steps__narrow {
			flex-basis: 15%;
		}

		&.arrow-steps__wide {
			flex-basis: 25%;
		}

		&.arrow-steps__v-wide {
			flex-basis: 30%;
		}

		&::after {

			border-color: currentColor transparent transparent;
			border-style: solid;
			@include remit(border-width, $hsu);
			content: "";
			color: inherit;
			left: 50%;
			position: absolute;
			top: 100%;
			transform: translateX(-50%);

			@include mq($regular) {
				border-color: transparent transparent transparent currentColor;
				left: 100%;
				top: 50%;
				transform: translateY(-50%);
			}

		}

		&:last-child {

			margin-bottom: 0;
			margin-right: 0;

			&::after {
				display: none;
			}

		}

		> p {

			color: $white;
			@include font-rem($footer-font-size);
			@include remit(margin-bottom, $hsu / 2);

			&:last-child {
				margin-bottom: 0;
			}

		}

	}

}

ol.arrow-steps--orange { // stronger selector overrides `.content ol`
	color: $color-base;
}

ol.arrow-steps--light-grey { // stronger selector overrides `.content ol`
	color: $grey;
}
