/* ==========================================================================
	 VIDEOS
========================================================================== */
.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	height: 0;
	@extend %cf;
	@include remit(margin-bottom, 20);
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}


.galleryVideos {
	@extend %cf;

	&__item {
		list-style:none;
		@include remit(margin-bottom, 36);

		@include mq($regular) {
			@include gallery(8, of-type);
		}
	}

	&__title {
		// font-family:$font-body;
		color:$color-sub;
		// font-weight:500;
		@include font-size(16, $hsu, 16*1.2);
		border-bottom:1px solid $lightgrey;
		@include remit(padding-bottom, 6);
	}

	p {
		@include font-size(14, no, 21);
	}

}