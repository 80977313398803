@if $use-fadeInRight == true {
  @include keyframes(fadeInRight) {
    0% {
      opacity: 0;
      @include transform(translateX(20px));
    }
    100% {
      opacity: 1;
      @include transform(translateX(0));
    }
  }
}

@mixin fadeInRight($count: $countDefault, $duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
  @include animation-name(fadeInRight);
  @include count($count);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}
