@mixin placeholder($color: $color-text, $alpha: .8, $font-size:$p-font-size) {
	&::-webkit-input-placeholder {
		color: rgba($color, $alpha);
		@include font-rem($font-size);
	}  /* WebKit browsers */

	&:-moz-placeholder {
		color: rgba($color, 1);
		@include font-rem($font-size);
	}  /* Mozilla Firefox 4 to 18 */

	&::-moz-placeholder {
		color: rgba($color, 1);
		@include font-rem($font-size);
	}  /* Mozilla Firefox 19+ */

	&:-ms-input-placeholder {
		color: rgba($color, $alpha);
		@include font-rem($font-size);
	} /* Internet Explorer 10+ */
}