/* ==========================================================================
   CERTIFICATE STYLES
========================================================================== */

.certificate {

	&__item {
		list-style:none;
		color:currentColor;
		@include remit(padding-top, 36);


		&--half {
			@include mq($regular) {
				@include gallery(12);
			}

			// &:nth-of-type(even) {
				// @include remit(padding-left, $bsu);
			// }

			// &:nth-of-type(odd) {
				// @include remit(padding-right, $bsu);
			// }
		}

		&--cpr {
			@include mq($small) {
				@include gallery(12);
			}

			@include mq($regular) {
				@include gallery(8);
			}
		}

		&--standby {
			position: relative;
		}

	}

	&__download {
		color:$color-sub;
		@include font-rem(12);
		text-align:center;
		@include remit(width, 100);
		@include remit(margin-left, $hsu);
		@include remit(margin-bottom, $hsu);
		float:right;
		// background-color:$color-base;

		@include mq($regular) {
			// @include span(6 last);
			// @include remit(width, 125);
			@include remit(margin-left, $bsu);
			@include remit(margin-bottom, $bsu);
		}

		.icon {
			@include remit(margin-bottom, 6);
			// height: 100px;
			// width: 74px;
			height: 50px;
			width: 59px;
			fill: $color-base;
			float: right;
		}
	}

	.filesize {
		color:rgba($color-sub, 0.5);
		// font-style:italic;
		margin-left:0;
	}

	&__title {
		@include font-size(21);
		color:$color-sub;
		@include remit(padding-bottom, $hsu);
		@include remit(margin-bottom, $hsu);
		border-bottom:1px solid #d9d9d9;
	}

	&__title__subtitle {
		font-size: 15px;
		color: $color-base;
		font-style:italic;
	    font-weight: 600;
	    margin-top: 15px;
	    margin-bottom: 10px;
	    display: inline-block;
	}

	&__label {
		font-weight:600;
		font-style:italic;
		line-height:1.2;
	}

	.inline {
		display:inline-block;

		&:not(:last-of-type) {
			@include remit(margin-right, 36);
		}
	}

	.cpr-item {
		margin-bottom:0;
	}

	a {
		color:currentColor;
	}

	p {
		@include font-rem(14);
		@include remit(padding-right, $bsu);
	}

	&__standby {

		align-items: center;
		background-color: rgba($white, .75);
		color: $color-base;
		display: flex;
		flex-direction: column;
		height: 100%;
		left: 0;
		justify-content: center;
		position: absolute;
		text-align: center;
		top: 0;
		width: 100%;
		z-index: 1;

		&__title {
			@include font-size(21);
			font-weight:300;
			text-transform: uppercase;
		}

		> p {
			padding-right: 0;
			&:last-child {
				margin-bottom: 0;
			}
		}

	}

	//*
	&__tooltip {

		display: inline;
		text-transform: none;

		> summary {

			color: $color-text;
			display: inline-block;
			height: 1em;
			width: 1em;

			> svg {
				width: 100%;
			}

		}

		> article {

			@include font-rem(14);
			bottom: 100%;
			left: 50%;
			margin-bottom: 0.5em;
			padding: 1em;
			top: auto;
			transform: translateX(-50%);
			width: 22em;

			&::after {
				background-color: $color-text;
				bottom: 0;
				content: "";
				display: block;
				height: 1em;
				left: calc(50% - 0.5em);
				position: absolute;
				transform: rotate(45deg) translate(10%, 10%);
				transform-origin: center;
				width: 1em;
			}

			> p {
				padding-right: 0;
				&:last-child {
					margin-bottom: 0;
				}
			}

		}

	}
	//*/

}
