/* ==========================================================================
  HEADER STYLES
========================================================================== */
.banner {
  background-color:$black;
  background-position:center;
  background-repeat:no-repeat;
  background-size:cover;
  position:relative;

  &--active {
    @include fadeIn();
  }


  &--spacing {
    padding-bottom: 35%;
    @include mq($medium) {
      padding-bottom: 25%;
    }

  }

    &--spacing--smaller {
        padding-bottom: 35%;
        @include mq($medium) {
            padding-bottom: 25%;
        }
        @include mq($large) {
            padding-bottom: 21%;
        }
    }

    &--spacing--smaller {
        padding-bottom: 40%;
        @include mq($medium) {
            padding-bottom: 25%;
        }
        @include mq($large) {
            padding-bottom: 15%;
        }
    }

  &--spacing--no-padding {
    padding: 0;
    @include mq(950) {
      padding-bottom: 25%;
    }
  }
}

// .banner--overlay {
//   &:before {
//     background-image: linear-gradient(to bottom,#000, transparent);
//     bottom: 0;
//     content: '';
//     height:100%;
//     left: 0;
//     opacity:0.8;
//     position: absolute;
//     right: 0;
//     top: 0;
//   }
// }