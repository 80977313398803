//////////////////////////////////////////////////////////////////////////////////////////////////
//Vertical Rhythm Mixin
//
//This mixin is to help create a typograhpical baseline grid but also to allow for specifying different line heights or bottom margins if you need to.
//
//In this mixin you can specifiy the font size in PX and it will calculate the REM based on your $doc-font-size & $doc-line-height variables.
//
//	@include font-size(24);
//
//It will also create a bottom margin based on the $doc-font-size & $doc-line-height variables unless you specify that it shouldn't have one -
//
//	@include font-size(24, no);
//
//Or if you want to specify a different bottom margin to be generated -
//
//	@include font-size(24,32);
//
//This mixin also generates a pixel-less line height by default unless you specify that you either don't want one where I'd suggest declaring 1 //within the mixin -
//
//	@include font-size(24, yes, 1);
//
//There's also the option to specify a different line-height for it to generate to, where you would specify the line-height in (effectively) it's pixel value -
//
//	@include font-size(24, yes, 40);

	//////////////////////////////////////////////////////////////////////////////////////////////////

// the magic mixin
//////////////////

	@mixin font-size($size, $margin: yes, $line-height: $doc-line-height) {

	// generates the font-size in REMs with a PX fallback

		font-size: 0px + $size;
		font-size: 0rem + $size / $doc-font-size;

	// line-height functions
	////////////////////////

	// if you a line-height is specified in the mixin
		@if $line-height != $doc-line-height and $line-height != 1 {
			line-height: ceil($size / $line-height) * ($line-height / $size);
		}

	// if $line-height == 1 because, typing 1 is quicker than 16
		@else if $line-height == 1 {
			line-height: 1;
		}

	// normal $line-height if the line-height is left.
		@else {
			line-height: ceil($size / $doc-line-height) * ($doc-line-height / $size);
		}

		// margin-bottom functions
		//////////////////////////

			// if no is bottom margin is required
			@if $margin == no {
				margin-bottom: 0;
			}

		// if a specific bottom margin is required
			@else if $margin != yes and $margin != no {
				margin-bottom: 0px + $margin;
				margin-bottom: 0rem + ($margin / $doc-font-size);
			}

		// if you're keeping the vertical rhythm with the margin
			@else {
				margin-bottom: 0px + $doc-line-height;
				margin-bottom: 0rem + ($doc-line-height / $doc-font-size);
			}
	}

//////////////////////////////////////////////////////////////////////////////////////////////////
	//REMs with PX fallback mixin

	//Sometimes you would only want to define the fonts' size on an element.
	//Rather than make the vertical rhythm mixin more convoluted here is a separate mixin to be used to give REMs for modern browsers and PX for OldIE and Opera mini
//////////////////////////////////////////////////////////////////////////////////////////////////

@mixin font-rem($size) {
	font-size: 0px + $size;
	font-size: $size / $doc-font-size +rem;
}
