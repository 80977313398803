/* ==========================================================================
	TEXT WIth IMAGE LAYOUT STYLES
========================================================================== */

.media {
  @extend %cf;

   @include mq($small) {
    display:flex;
    align-items: center;

    *:last-child {
      margin-bottom:0;
    }
  }

  @include remit(margin-bottom, $bsu);

  &__content {
    @include mq($small) {
      flex:1 1 50%;
      @include remit(padding-left, $bsu);
    }

    @include mq($regular) {
      flex:1 1 66%;
    }
  }

	&__img {
		@include mq($small) {
      flex:1 1 50%;
    }

		@include mq($regular) {
      flex:1 1 33%;
		}
	}

  &--start {
    align-items: flex-start;
  }

  &--end {
    align-items: flex-end;
  }
}