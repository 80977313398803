/* ==========================================================================
	HEADING STYLES
========================================================================== */

h1, h2, h3, h4, h5, h6 {
	color:$color-text-header;
	font-family:$font-header;
	text-rendering: optimizeLegibility;
	font-weight:300;
}

h1, .alpha 		{
	@include font-size($h1-font-size);
}


h2, .beta {
	@include font-size($h2-font-size);
}

h3, .gamma	{
	@include font-size($h3-font-size);
}


h4, .delta {
	@include font-size($h4-font-size);
}

h5, .epsilon {
	@include font-size($h5-font-size);
}

h6, .zeta {
	@include font-size($h6-font-size);
}

.pageTitle {
	@include mq($regular, max) {
		@include remit(margin-right, 90);
	}
}

/* ==========================================================================
	PARAGRAPH AND LIST STYLES
========================================================================== */


/* List Style */
ul.square	{list-style: square inside;}
ul.disc 	{list-style: circle inside;}
ul.bullet 	{list-style: disc inside;}

// Remove vertical spacing from nested lists.
li {
	> ul,
	> ol{
		 margin-bottom:0;
	 }
}

.lead {
	font-family:$font-header;
	color:$color-sub;
	@include font-size(20, yes, 34);

	&--events {
		font-size: 18px;
	}
}

.article--sidebar p {
  @include font-size(14);
}

// This styles the main content of the page
// For other content sections and to change styling on paragraphs and headings and chunks create new blocks like side-bar etc
.content {
	p {
		color:$color-text;
		@include font-size($p-font-size);
	}

	ul, ol {
		@include remit(margin-bottom, $bsu);
		color:$color-text;
	}

	ul 	{list-style: disc outside;}
	ol 	{list-style: decimal outside;}

	li	{
		@include font-rem($p-font-size);
		@include remit(margin, $hsu $bsu);
	}

	li li {
		font-size:90%;
	}

	h2,h3,h4,h5,h6 {
		color:$color-sub;
		font-family:$font-body;
	}
}

// .article {
// 	h2,h3,h4,h5,h6 {
// 		@include remit(padding-top, $g-spacing);
// 	}
// }


.footer {

	p {
		@include font-size($footer-font-size);
		color:$white;
	}
}


// .bullet-cols {
// 	// number of cols, gap between in rems
// 	// TODO: improve this mixin to include PXs and base unit as PXs and modularise it
// 	@include cols(2, 1);
// }


/* ==========================================================================
   FONT WEIGHTS AND SIZES
========================================================================== */

%caps 				{text-transform:uppercase;}

// .extra-light 		{font-weight:100;}
// .light 				{font-weight:300;}
// .normal 			{font-weight:400;}
// .bold			 	{font-weight:700;}
// .extra-bold 		{font-weight:900;}


// .xsmall 			{@include font-size(11);}
.small 				{font-size:80% !important;}
// .medium 			{font-size:110% !important;}
.large 				{font-size:130% !important;}
// .xlarge 			{@include font-size(30);}