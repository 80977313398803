.form-module {
    input,
    select,
    textarea {
        height: 45px;
        height: 2.8125rem;
        padding: 8px 6px;
        padding: .5rem .375rem;
        width: 100%;
    }

    textarea {
        font-size: 14px;
        font-size: .875rem;
        line-height: 1.71429;
        margin-bottom: 24px;
        margin-bottom: 1.5rem;
        color: #666;
        margin: 0!important;
        padding: 10px;
        min-height: 120px;
        line-height: 100%;
    }
}

.form-module__collapse {
    position: relative;
    @include remit(padding-bottom, $bsu);
    @include remit(margin-bottom, $bsu);
}

.form-module__summary {
    list-style: none;
    text-align: center;
    @include remit(padding-top, $bsu);
    @include remit(padding-bottom, $bsu);

    // Hide arrow that appears in IE
    &::before {
        display: none;
    }

    &::-webkit-details-marker {
        display: none;
    }

    &::after {
        content: "";
        display: block;
        cursor: pointer;
        background-image: url("../img/toggle_widget.svg");
        background-repeat: no-repeat;
        background-size: cover;
        width: 32px;
        height: 18px;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        transform-origin: center;

        [open] > & {
            transform: translateX(-50%) rotate(180deg);
        }
    }

    > h3 {
        padding: 0;
        margin: 0;
    }
}

.form-module__content {
    @include fadeInUp();
}

.form-module__collapsed-text {
    text-align: center;

    [open] + & {
        display: none;
    }

    > p {
        margin: 0;
    }
}

.form-module__button {
    @extend %btn;
    background-color: $color-base;
	color: $white;
	position: fixed;
    right: 0;
    top: 100px;
    transform: rotate(-90deg);
    transform-origin: bottom right;
    z-index: 9999;

	@include hover() {
		background-color: $color-sub;
        color: $white;
	}
}
