@charset "UTF-8";
/*---------------------------------------------------
  Initial setup to import all our SASS based files.

  by Terry Upton (Amasci Creative Limited)
-----------------------------------------------------*/
*, *:before, *:after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary, time {
  display: block;
}

audio, canvas, progress, video {
  display: inline-block;
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden],
template {
  display: none;
}

a {
  background: transparent;
}
a:active, a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b, strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

mark {
  background: #ff0;
  color: #000;
}

small {
  font-size: 70%;
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
}

pre {
  overflow: auto;
}

code, kbd, pre, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled], html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
  height: auto;
}

input[type=search] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, th {
  padding: 0;
}

@keyframes rubberBand {
  0% {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-10px);
  }
  20%, 40%, 60%, 80% {
    transform: translateX(10px);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes flipInX {
  0% {
    opacity: 0;
    transform: perspective(400px) rotateX(90deg);
  }
  40% {
    transform: perspective(400px) rotateX(-10deg);
  }
  70% {
    transform: perspective(400px) rotateX(10deg);
  }
  100% {
    opacity: 1;
    transform: perspective(400px) rotateX(0deg);
  }
}
@keyframes slideInLeft {
  0% {
    opacity: 0;
    transform: translateX(-2000px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideInUp {
  0% {
    opacity: 0;
    transform: translateY(2000px);
  }
  100% {
    transform: translateY(0);
  }
}
/* ==========================================================================
	 ANIMATION STYLES
========================================================================== */
.cableSearch__icon {
  animation-name: fadeInLeft;
  animation-iteration-count: 1s;
  animation-duration: 1s;
  animation-delay: 0.5s;
  animation-timing-function: ease;
  animation-fill-mode: both;
  backface-visibility: hidden;
}
.cableSearch__content {
  animation-name: fadeInRight;
  animation-iteration-count: 1s;
  animation-duration: 1s;
  animation-delay: 1s;
  animation-timing-function: ease;
  animation-fill-mode: both;
  backface-visibility: hidden;
}
.cableSearch__content--full {
  animation-name: fadeInRight;
  animation-iteration-count: 1s;
  animation-duration: 1s;
  animation-delay: 0s;
  animation-timing-function: ease;
  animation-fill-mode: both;
  backface-visibility: hidden;
}
.cableSearch .pagination {
  animation-name: fadeInUp;
  animation-iteration-count: 1s;
  animation-duration: 1s;
  animation-delay: 1.2s;
  animation-timing-function: ease;
  animation-fill-mode: both;
  backface-visibility: hidden;
}

.card__btn, .entryTeaser:hover .entryTeaser__link, .entryTeaser:focus .entryTeaser__link, .entryTeaser:active .entryTeaser__link {
  animation-name: sideToSide;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
}

@keyframes sideToSide {
  0%, 20%, 50%, 80%, 100% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(20px);
  }
  60% {
    transform: translateX(10px);
  }
}
/* ==========================================================================
 INIAL RESETS
========================================================================== */
/* See Normalize for addition resets and set-ups */
*, *:before, *:after {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
figure,
dl, dd, ol, ul,
form, fieldset, legend,
table, th, td, caption,
hr {
  margin: 0;
  padding: 0;
}

/* ==========================================================================
ONE WAY MARGINS
========================================================================== */
p, h1, h2, h3, h4, h5, h6, hgroup, dd, figure, pre, table, fieldset, legend, hr, time {
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}

/* ==========================================================================
MAIN FRAMEWORK DOCUMENT SETUP
========================================================================== */
html {
  font-size: 100%;
  overflow-y: scroll;
  min-height: 100%;
}

body {
  margin: 0 auto;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  color: #666;
}

.container {
  max-width: 87.5em;
  min-width: 18.75em;
  margin: 0 auto;
  position: relative;
}

/* ==========================================================================
  HR RULES
========================================================================== */
hr {
  border: solid #a0a0a0 0px;
  border-top: solid #a0a0a0 1px;
  height: 0px;
  clear: both;
}

/* ==========================================================================
   SELECTION STYLING
========================================================================== */
/* This is used to change the highlight colour when selecting text.
 * Nice bespoke touch to websites.
 * Full article here: http://davidwalsh.name/css-selection
 */
::-moz-selection {
  background: #f36d21;
  color: #FFF;
  text-shadow: none;
}

::selection {
  background: #f36d21;
  color: #FFF;
  text-shadow: none;
}

/* ==========================================================================
   HELPER STYLES
========================================================================== */
.island-top {
  margin-top: 60px !important;
  margin-top: 3.75rem !important;
}

.island-bottom {
  margin-bottom: 60px !important;
  margin-bottom: 3.75rem !important;
}

.margin-top {
  margin-top: 24px !important;
  margin-top: 1.5rem !important;
}

.margin-topX2 {
  margin-top: 48px !important;
  margin-top: 3rem !important;
}

.margin-bottomX2 {
  margin-bottom: 48px !important;
  margin-bottom: 3rem !important;
}

.flush-top {
  padding-top: 0 !important;
}

.flush-bottom {
  margin-bottom: 0 !important;
}

.float-right {
  float: right !important;
}

.float-left {
  float: left !important;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.d-ib {
  display: inline-block !important;
}

.container {
  padding: 0px 12px;
  padding: 0rem 0.75rem;
}
@media (min-width: 37.5em) {
  .container {
    padding: 0px 24px;
    padding: 0rem 1.5rem;
  }
}

@media (max-width: 37.5em) {
  .clientTabs .r-tabs .r-tabs-accordion-title .r-tabs-anchor, .cableSearch .r-tabs .r-tabs-accordion-title .r-tabs-anchor, .header__nav .container, .no-gutter {
    margin-left: -12px;
    margin-left: -0.75rem;
    margin-right: -12px;
    margin-right: -0.75rem;
  }
}
@media (min-width: 37.5em) and (max-width: 59.375em) {
  .clientTabs .r-tabs .r-tabs-accordion-title .r-tabs-anchor, .cableSearch .r-tabs .r-tabs-accordion-title .r-tabs-anchor, .header__nav .container, .no-gutter {
    margin-left: -24px;
    margin-left: -1.5rem;
    margin-right: -24px;
    margin-right: -1.5rem;
  }
}

.underline {
  border-bottom: 1px solid #d9d9d9;
}

.background--grey {
  background-color: #eee;
}

.background--dark-grey {
  background-color: #a1a1a1;
  color: white;
}
.background--dark-grey h1, .background--dark-grey h2, .background--dark-grey h3, .background--dark-grey h4, .background--dark-grey p, .background--dark-grey a {
  color: white !important;
}

.background--orange {
  background-color: #f36d21;
  color: white;
}
.background--orange a:not(.btn) {
  color: white;
  text-decoration: underline;
}

.padded {
  padding-top: 60px;
  padding-bottom: 60px;
}
.padded--executive {
  padding-top: 0;
  display: flex;
  flex-direction: column;
}
@media (min-width: 47.5em) {
  .padded--executive {
    justify-content: center;
    flex-direction: row;
  }
}

.padding-bottom {
  padding-bottom: 60px;
}

.padding-top {
  padding-top: 60px;
}

.one-of-three, .search-filters__section form label.one-of-three {
  font-size: 15px;
  min-height: 150px;
}
@media (min-width: 59.375em) {
  .one-of-three, .search-filters__section form label.one-of-three {
    width: calc(33.333% - 20px);
    float: left;
    margin-left: 10px;
    margin-right: 10px;
  }
  .one-of-three.article, .search-filters__section form label.article.one-of-three, .article .one-of-three, .article .search-filters__section form label.one-of-three, .search-filters__section form .article label.one-of-three {
    margin: 0;
    width: 33.333%;
    float: left;
  }
}

.inline-block {
  display: inline-block;
}

@media (min-width: 59.375em) {
  .certificate__item.one-of-two {
    width: calc(50% - 20px);
    float: left;
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 0;
    padding-right: 0;
  }
}

.custom-container {
  clear: both;
}
@media (min-width: 59.375em) {
  .custom-container {
    margin-left: -10px;
    margin-right: -10px;
  }
}

.one-of-four {
  margin: 10px 0;
  text-align: center;
}
@media (min-width: 47.5em) {
  .one-of-four {
    width: calc(50% - 20px);
    float: left;
    margin: 10px;
  }
}
@media (min-width: 68.75em) {
  .one-of-four {
    width: calc(25% - 20px);
    float: left;
    margin: 0 10px;
    text-align: left;
  }
}

.side-padding {
  padding-left: 30px;
  padding-right: 30px;
}

/* ==========================================================================
   CLEARFIX  STYLES
========================================================================== */
/*
 * Micro clearfix, as per: css-101.org/articles/clearfix/latest-new-clearfix-so-far.php
 * Extend the clearfix class with Sass to avoid the `%cf` class appearing over
 * and over in your markup.
 */
.media:after, .system:after, .entryTeaser:after, .event:after, .courseDetails:after, .pagination:after, .newsTeaser:after, .certificateNotice:after, .team__item:after, .logoListing:after, .testing__content:after, .cableGroup:after, .search-container:after, .search-filters:after, .tab:after, .tabs:after, .term:after, .footer:after, .header__btns:after, .header__search:after, .header:after, .form__rows:after,
.form--cpr .form__row:after, .form__row:after, .main:after, .galleryVideos:after, .videoWrapper:after, .container:after, .row:after {
  content: "";
  display: table;
  clear: both;
}

.clear {
  clear: both;
}

.hamburger, .card__btn, .header__btn .header__btn-icon, .form-module__button, .submit, .btn {
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  overflow: visible;
  outline: 0;
  -webkit-appearance: none;
}

/* ==========================================================================
	HIDE STYLES
========================================================================== */
.hidden, #fax {
  display: none;
}

.alt {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.alt .focusable:active, .alt .focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

/* ==========================================================================
	ARROW MODULES
========================================================================== */
/* ==========================================================================
	HEADING STYLES
========================================================================== */
h1, h2, h3, h4, h5, h6 {
  color: #f36d21;
  font-family: "Roboto", sans-serif;
  text-rendering: optimizeLegibility;
  font-weight: 300;
}

h1, .alpha, .two-columns__inner h2 {
  font-size: 36px;
  font-size: 2.25rem;
  line-height: 1.3333333333;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}

h2, .beta, .two-columns__inner h3 {
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}

h3, .gamma {
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1.1428571429;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}

h4, .delta {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.3333333333;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}

h5, .epsilon {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}

h6, .zeta {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.7142857143;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}

@media (max-width: 59.375em) {
  .pageTitle {
    margin-right: 90px;
    margin-right: 5.625rem;
  }
}

/* ==========================================================================
	PARAGRAPH AND LIST STYLES
========================================================================== */
/* List Style */
ul.square {
  list-style: square inside;
}

ul.disc {
  list-style: circle inside;
}

ul.bullet {
  list-style: disc inside;
}

li > ul,
li > ol {
  margin-bottom: 0;
}

.lead {
  font-family: "Roboto", sans-serif;
  color: #000;
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 1.7;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}
.lead--events {
  font-size: 18px;
}

.article--sidebar p {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.7142857143;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}

.content p {
  color: #666;
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 1.6;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}
.content ul, .content ol {
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  color: #666;
}
.content ul {
  list-style: disc outside;
}
.content ol {
  list-style: decimal outside;
}
.content li {
  font-size: 15px;
  font-size: 0.9375rem;
  margin: 12px 24px;
  margin: 0.75rem 1.5rem;
}
.content li li {
  font-size: 90%;
}
.content h2, .content h3, .content h4, .content h5, .content h6 {
  color: #000;
  font-family: "Roboto", sans-serif;
}

.footer p {
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 2;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  color: #FFF;
}

/* ==========================================================================
   FONT WEIGHTS AND SIZES
========================================================================== */
.nav--breadcrumb__item, .nav--main li {
  text-transform: uppercase;
}

.small {
  font-size: 80% !important;
}

.large {
  font-size: 130% !important;
}

/* ==========================================================================
	BASE LINK STYLES
========================================================================== */
a {
  transition: all 0.4s ease;
  color: #f36d21;
  text-decoration: none;
}
a:hover, a:focus, a:active {
  color: #0e9ee4;
  text-decoration: none;
}

.footer a {
  color: #FFF;
  text-decoration: none;
}
.footer a:hover, .footer a:focus, .footer a:active {
  color: #000;
}

.textFooterAmasci.textFooterAmasci, .courseDetails__text a {
  color: rgba(0, 0, 0, 0.5);
}
.textFooterAmasci.textFooterAmasci:hover, .textFooterAmasci.textFooterAmasci:focus, .textFooterAmasci.textFooterAmasci:active, .courseDetails__text a:hover, .courseDetails__text a:focus, .courseDetails__text a:active {
  color: #000;
}

a[href^="tel:"] {
  color: #000;
}
.header a[href^="tel:"], .footer a[href^="tel:"] {
  color: #FFF;
}

/* ==========================================================================
	BUTTON STYLES
========================================================================== */
.form-module__button, .submit, .btn {
  transition: all 0.4s ease;
  padding: 12px 16px;
  padding: 0.75rem 1rem;
  text-decoration: none;
  position: relative;
  text-align: center;
  display: inline-block;
  min-width: 160px;
  color: #FFF;
  font-family: "Roboto", sans-serif;
  cursor: pointer;
}
.form-module__button:last-of-type, .submit:last-of-type, .btn:last-of-type {
  margin-bottom: 0;
}
.btn--base {
  background-color: #f36d21;
  color: #FFF;
}
.btn--base:hover, .btn--base:focus, .btn--base:active {
  background-color: #0e9ee4;
  color: #FFF;
}
.btn--white {
  background-color: #FFF;
  color: #f36d21;
}
.btn--white:hover, .btn--white:focus, .btn--white:active {
  background-color: #FFF;
  color: #f36d21;
}

/*---------------------------------------------------
    COLOUR STYLES v1.1
  ---------------------------------------------------
    A set of common SASS colour styles by Terry Upton (Amasci Creative Limited)
	These should be unique for every project.
-----------------------------------------------------*/
/* ==========================================================================
   COLOUR STYLES
========================================================================== */
.color--base {
  color: #f36d21 !important;
}

.color--sub {
  color: #000 !important;
}

.required {
  color: #f36d21;
  font-weight: bold;
}

.color-error {
  color: #b94a48 !important;
}

/* ==========================================================================
	 IMAGES
========================================================================== */
img {
  /* Responsive images (ensure images don't scale beyond their parents) */
  max-width: 100%;
  /* Part 1: Set a maxium relative to the parent */
  height: auto;
  /* Part 2: Scale the height according to the width, otherwise you get stretching */
  vertical-align: middle;
  -ms-interpolation-mode: bicubic;
  font-style: italic;
  /*So that `alt` text is visually offset if images don’t load */
}

svg {
  max-height: 100%;
}

/* ==========================================================================
	 IMAGE PLACEMENTS
========================================================================== */
.img-right {
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  margin-left: 24px;
  margin-left: 1.5rem;
  float: right;
}

.img-left {
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  margin-right: 24px;
  margin-right: 1.5rem;
  float: left;
}

.img-center {
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.entryTeaserCertificate__logo--no-imge, .entryTeaserClient__logo--no-imge, .client--no-imge, .event__img--no-imge, .team__img--no-imge, .logoListing__img--no-imge {
  position: relative;
  background-color: #f3f3f3;
}
.entryTeaserCertificate__logo--no-imge:before, .entryTeaserClient__logo--no-imge:before, .client--no-imge:before, .event__img--no-imge:before, .team__img--no-imge:before, .logoListing__img--no-imge:before {
  content: "No Image Avalable";
  text-align: center;
  display: block;
  color: #b3b3b3;
  font-size: 14px;
  font-size: 0.875rem;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

/* ==========================================================================
	 LAZY IMAGES
========================================================================== */
img[data-sizes=auto] {
  display: block;
  width: 100%;
}

.lazyload {
  opacity: 0;
}
.no-js .lazyload {
  display: none;
}

.lazyloading {
  background-color: #f3f3f3;
  background-image: url("/assets/img/loading.svg");
  background-repeat: no-repeat;
  background-position: center center;
}

.lazyloaded {
  animation-name: fadeIn;
  animation-iteration-count: 1s;
  animation-duration: 1s;
  animation-delay: 0s;
  animation-timing-function: ease;
  animation-fill-mode: both;
  backface-visibility: hidden;
}

.figure {
  margin-top: 48px;
  margin-top: 3rem;
  margin-bottom: 48px;
  margin-bottom: 3rem;
}
@media (min-width: 59.375em) {
  .figure--fullwidth {
    margin-left: -5.7142857143%;
  }
}

.figcaption {
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 1.5384615385;
  margin-bottom: 0;
  color: #000;
  font-style: italic;
  margin-top: 6px;
  margin-top: 0.375rem;
}

.figure--ml0 > .figure--fullwidth {
  margin-left: 0;
}

/* ==========================================================================
	 MISC/UNIQUE IMAGE STYLES
========================================================================== */
.header .logo {
  width: 130px;
  height: 40px;
  display: inline-block;
  position: relative;
  margin-right: 20px;
  margin-right: 1.25rem;
}
@media (min-width: 37.5em) {
  .header .logo {
    margin-right: 30px;
    margin-right: 1.875rem;
  }
}
.header .logo:after {
  content: "";
  position: absolute;
  top: 0;
  right: -15px;
  right: -0.9375rem;
  height: 100%;
  display: block;
  width: 1px;
  background: rgba(255, 255, 255, 0.5);
}
@media (min-width: 37.5em) {
  .header .logo:after {
    right: -20px;
    right: -1.25rem;
  }
}

.header .logoCircle {
  width: 45px;
  height: 45px;
  position: relative;
  top: -2px;
  top: -0.125rem;
}

.testing__cableImg {
  margin-bottom: 12px;
  margin-bottom: 0.75rem;
  border-radius: 50%;
  box-shadow: 0px 0px 0px 3px #FFF;
}

/* ==========================================================================
	 VIDEOS
========================================================================== */
.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  margin-bottom: 20px;
  margin-bottom: 1.25rem;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.galleryVideos__item {
  list-style: none;
  margin-bottom: 36px;
  margin-bottom: 2.25rem;
}
@media (min-width: 59.375em) {
  .galleryVideos__item {
    width: 31.4285714286%;
    float: left;
  }
  .galleryVideos__item:nth-of-type(3n + 1) {
    margin-left: 0;
    margin-right: -100%;
    clear: both;
    margin-left: 0;
  }
  .galleryVideos__item:nth-of-type(3n + 2) {
    margin-left: 34.2857142857%;
    margin-right: -100%;
    clear: none;
  }
  .galleryVideos__item:nth-of-type(3n + 3) {
    margin-left: 68.5714285714%;
    margin-right: -100%;
    clear: none;
  }
}
.galleryVideos__title {
  color: #000;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.2;
  margin-bottom: 12px;
  margin-bottom: 0.75rem;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 6px;
  padding-bottom: 0.375rem;
}
.galleryVideos p {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.5;
  margin-bottom: 0;
}

/* ==========================================================================
	ICON STYLES
========================================================================== */
.icon {
  display: inline-block;
  fill: currentColor;
  position: relative;
  vertical-align: middle;
  top: -2px;
  top: -0.125rem;
}

.icon-close {
  transition: all 0.4s ease;
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  overflow: hidden;
  top: 2px;
}
.icon-close::before, .icon-close::after {
  transition: all 0.4s ease;
  content: "";
  position: absolute;
  height: 2px;
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -1px;
  background: #b94a48;
}
.icon-close::before {
  transform: rotate(45deg);
}
.icon-close::after {
  transform: rotate(-45deg);
}
.icon-close.black::before, .icon-close.black::after {
  height: 8px;
  margin-top: -4px;
}

.icon-date {
  width: 16px;
  height: 16px;
  margin-right: 3px;
  margin-right: 0.1875rem;
}
.icon-date--small {
  width: 14px;
  height: 14px;
}

.icon-people {
  width: 58px;
  height: 36px;
}

.icon-money {
  width: 30px;
  height: 36px;
}

.icon-location {
  width: 34px;
  height: 36px;
}

.icon-error {
  width: 18px;
  height: 18px;
  margin-right: 2px;
  margin-right: 0.125rem;
}

/* ==========================================================================
	SOCIAL ICON STYLES
========================================================================== */
.icon-social {
  transition: all 0.2s ease-in-out;
  width: 40px;
  height: 40px;
  fill: currentColor;
}
.footer .icon-social {
  color: #FFF;
}
.icon-social:hover, .icon-social:focus, .icon-social:active {
  transform: scale(1.4);
  z-index: 10;
  color: #f36d21;
}

.icon-twitter {
  transition: all 0.4s ease;
  color: #a0a0a0;
}

.icon-facebook {
  transition: all 0.4s ease;
  color: #a0a0a0;
}

.icon-googleplus {
  transition: all 0.4s ease;
  color: #a0a0a0;
}

.icon-linkedin {
  transition: all 0.4s ease;
  color: #a0a0a0;
}

.icon-youtube {
  transition: all 0.4s ease;
  color: #a0a0a0;
}

.icon-email-share {
  transition: all 0.4s ease;
  /*color:$color-text;*/
  color: #a0a0a0;
}

.social-list {
  font-size: 0;
}
.social-list li {
  list-style: none;
  display: inline-block;
}
.social-list--share li {
  margin-right: 3px;
  margin-right: 0.1875rem;
}
.social-list--follow li {
  margin-right: 8px;
  margin-right: 0.5rem;
}

/* ==========================================================================
  BLOCKQUOTE
========================================================================== */
blockquote {
  quotes: "“" "”" "‘" "’";
}
blockquote p {
  color: #666;
  font-style: italic;
  font-family: "Roboto", sans-serif;
}
blockquote p:before {
  content: open-quote;
}
blockquote p:after {
  content: close-quote;
}
blockquote cite {
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 1.6;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  display: block;
  color: #f36d21;
}
@media (min-width: 37.5em) {
  blockquote cite {
    margin: 12px 30px 0px 24px;
    margin: 0.75rem 1.875rem 0rem 1.5rem;
    padding-left: 20px;
    padding-left: 1.25rem;
  }
}
blockquote cite:before {
  content: "— ";
}

/* ==========================================================================
	LAYOUT MODULES
========================================================================== */
@media (min-width: 59.375em) {
  .home--primary {
    width: 57.1428571429%;
    float: left;
    margin-left: 4.2857142857%;
    margin-right: -100%;
  }
}
@media (min-width: 68.75em) {
  .home--primary {
    width: 52.8571428571%;
    float: left;
    margin-left: 4.2857142857%;
    margin-right: -100%;
  }
}

@media (min-width: 59.375em) {
  .article--primary {
    width: 57.1428571429%;
    float: left;
    margin-left: 4.2857142857%;
    margin-right: -100%;
  }
}
@media (min-width: 68.75em) {
  .article--primary {
    width: 52.8571428571%;
    float: left;
    margin-left: 4.2857142857%;
    margin-right: -100%;
  }
}

@media (min-width: 59.375em) {
  .article--center {
    width: 64.7058823529%;
    float: left;
    margin-right: 5.8823529412%;
    float: none;
    margin: 0 auto;
  }
}

@media (min-width: 59.375em) {
  .results--primary {
    width: 91.4285714286%;
    float: left;
    margin-left: 4.2857142857%;
    margin-right: -100%;
  }
}

.article--sidebar {
  padding-top: 60px;
  padding-top: 3.75rem;
}
@media (min-width: 59.375em) {
  .article--sidebar {
    width: 31.4285714286%;
    float: right;
    margin-right: 0;
  }
}

@media (min-width: 59.375em) {
  .article--share {
    width: 31.4285714286%;
    float: right;
    margin-right: 0;
    float: none;
    position: absolute;
    right: 24px;
    right: 1.5rem;
    top: 0;
  }
}

.home--sidebar {
  padding-top: 60px;
  padding-top: 3.75rem;
}
@media (min-width: 59.375em) {
  .home--sidebar {
    width: 31.4285714286%;
    float: right;
    margin-right: 0;
    padding-top: 0;
  }
}

.main {
  position: relative;
  padding-top: 60px;
  padding-top: 3.75rem;
}
.main--padding-bottom {
  padding-bottom: 60px;
  padding-bottom: 3.75rem;
}

button.read-bio {
  display: none;
  background: #f36d21;
  border: 0 none;
  color: white;
  font-size: 13px;
  padding: 10px;
}

.sticky-header--social-share {
  background: #eee;
  display: block;
  padding: 2px 0 0 0;
  height: 60px;
  width: 100%;
  position: fixed;
  top: -60px;
  z-index: 999;
  transition: top 0.3s;
}
.sticky-header--social-share.fixed {
  top: 0;
  position: fixed;
}
.sticky-header--social-share .container {
  padding-top: 10px;
}
@media screen and (max-width: 420px) {
  .sticky-header--social-share .social-list li {
    width: 28px;
    height: 28px;
    margin-top: 5px;
  }
}
.sticky-header--social-share .panel__title {
  display: inline-block;
  border: 0 none;
  width: auto;
  float: left;
  margin: 0;
  padding: 0.5rem 0;
}
.sticky-header--social-share .social-list {
  float: right;
  display: block;
}

/* ==========================================================================
  Olark
========================================================================== */
#olark-wrapper .olark-launch-button .olark-button-text {
  font-size: 12px !important;
}

/* IE9, IE10, IE11 */
@media screen and (min-width: 0\0 ) {
  #olark-wrapper .olark-notification-badge .olark-notification-badge-text {
    line-height: 15px !important;
  }
}
.tooltip {
  position: relative;
}

.tooltip > summary {
  cursor: pointer;
}

.tooltip > summary::-webkit-details-marker {
  display: none;
}

.tooltip > article {
  color: #FFF;
  background-color: #666;
  border-radius: 0.25em;
  left: 0;
  padding: 0.5em;
  position: absolute;
  top: 100%;
  z-index: 1;
}

.details-polyfill:not([open]) > *:not(summary) {
  display: none;
}

/* ==========================================================================
	BASE FORM STYLES
========================================================================== */
button,
select,
option {
  cursor: pointer;
}

.cableSearch__form button:active, .cableSearch__form input:active, .form input[type=text]:active,
.form input[type=email]:active,
.form input[type=tel]:active,
.form input[type=number]:active,
.form input[type=password]:active,
.form input[type=search]:active,
.form select:active,
.form textarea:active,
.cableSearch__form button:focus,
.cableSearch__form input:focus,
.form input[type=text]:focus,
.form input[type=email]:focus,
.form input[type=tel]:focus,
.form input[type=number]:focus,
.form input[type=password]:focus,
.form input[type=search]:focus,
.form select:focus,
.form textarea:focus,
textarea:active,
textarea:focus {
  cursor: text;
  outline: none;
}

.cableSearch__form button, .cableSearch__form input, .form input[type=text],
.form input[type=email],
.form input[type=tel],
.form input[type=number],
.form input[type=password],
.form input[type=search],
.form select,
.form textarea {
  color: #666;
  border: 1px solid #dedede;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
}
@media (false-max: 59.375em) {
  .cableSearch__form button:focus, .cableSearch__form input:focus, .form input[type=text]:focus,
  .form input[type=email]:focus,
  .form input[type=tel]:focus,
  .form input[type=number]:focus,
  .form input[type=password]:focus,
  .form input[type=search]:focus,
  .form select:focus,
  .form textarea:focus {
    font-size: 16px;
    font-size: 1rem;
  }
}

select {
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2219%22%20height%3D%229%22%20viewBox%3D%220%200%2019%209%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ctitle%3Eselect%3C%2Ftitle%3E%3Cg%20id%3D%22Icons-export%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Cg%20id%3D%22select%22%20fill%3D%22%23B4B4B4%22%3E%3Cpath%20d%3D%22M19%200L9.5%209%200%200h19z%22%20id%3D%22Rectangle-74%22%2F%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-color: #FFF;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.contact_widget_form select {
  background-image: url("../img/contact/arrow-down.svg");
}

select::-ms-expand {
  display: none !important;
}

/* ==========================================================================
	CHECKBOX STYLES
========================================================================== */
.checkbox {
  position: relative;
  border: 1px solid #f36d21;
  float: left;
  margin-right: 6px;
  margin-right: 0.375rem;
  margin-bottom: 12px;
  margin-bottom: 0.75rem;
  width: 90px;
  text-align: center;
}
.checkbox span {
  display: block;
  padding: 6px 10px;
  padding: 0.375rem 0.625rem;
  transition: all 0.4s ease;
}
.checkbox span:hover, .checkbox span:focus, .checkbox span:active {
  color: #FFF;
}
.checkbox input {
  position: absolute;
  opacity: 0;
  z-index: -1;
  width: 100%;
}
.checkbox input:checked + span {
  background-color: #f36d21;
  color: #FFF;
}
.checkbox span:hover, .checkbox input:checked + span {
  background-color: #000;
}

/* ==========================================================================
	ALL FORM STYLES
========================================================================== */
.required {
  color: #000;
}
.required:before {
  content: "*";
  margin-right: 3px;
  color: #b94a48;
}

.submit {
  background-color: #f36d21;
  color: #FFF;
  position: relative;
}
.submit:hover, .submit:focus, .submit:active {
  background-color: #000;
}
.submit--arrow:after {
  border: 2px solid currentColor;
  border-width: 2px 2px 0 0;
  transform: rotate(45deg);
  width: 8px;
  height: 8px;
  content: "";
  position: relative;
  display: inline-block;
  top: -1px;
  top: -0.0625rem;
  margin-left: 4px;
  margin-left: 0.25rem;
}

.instructions {
  font-size: 11px;
  font-size: 0.6875rem;
  line-height: 1.4545454545;
  margin-bottom: 12px;
  margin-bottom: 0.75rem;
  font-style: italic;
}

.form label {
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 1.8461538462;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  font-weight: 600;
}
.form input[type=text],
.form input[type=email],
.form input[type=tel],
.form input[type=number],
.form input[type=password],
.form input[type=search],
.form input[type=tel],
.form select,
.form textarea {
  /* WebKit browsers */
  /* Mozilla Firefox 4 to 18 */
  /* Mozilla Firefox 19+ */
  /* Internet Explorer 10+ */
  transition: box-shadow 0.15s;
}
.form input[type=text]::-webkit-input-placeholder,
.form input[type=email]::-webkit-input-placeholder,
.form input[type=tel]::-webkit-input-placeholder,
.form input[type=number]::-webkit-input-placeholder,
.form input[type=password]::-webkit-input-placeholder,
.form input[type=search]::-webkit-input-placeholder,
.form input[type=tel]::-webkit-input-placeholder,
.form select::-webkit-input-placeholder,
.form textarea::-webkit-input-placeholder {
  color: rgba(179, 179, 179, 0.8);
  font-size: 14px;
  font-size: 0.875rem;
}
.form input[type=text]:-moz-placeholder,
.form input[type=email]:-moz-placeholder,
.form input[type=tel]:-moz-placeholder,
.form input[type=number]:-moz-placeholder,
.form input[type=password]:-moz-placeholder,
.form input[type=search]:-moz-placeholder,
.form input[type=tel]:-moz-placeholder,
.form select:-moz-placeholder,
.form textarea:-moz-placeholder {
  color: #b3b3b3;
  font-size: 14px;
  font-size: 0.875rem;
}
.form input[type=text]::-moz-placeholder,
.form input[type=email]::-moz-placeholder,
.form input[type=tel]::-moz-placeholder,
.form input[type=number]::-moz-placeholder,
.form input[type=password]::-moz-placeholder,
.form input[type=search]::-moz-placeholder,
.form input[type=tel]::-moz-placeholder,
.form select::-moz-placeholder,
.form textarea::-moz-placeholder {
  color: #b3b3b3;
  font-size: 14px;
  font-size: 0.875rem;
}
.form input[type=text]:-ms-input-placeholder,
.form input[type=email]:-ms-input-placeholder,
.form input[type=tel]:-ms-input-placeholder,
.form input[type=number]:-ms-input-placeholder,
.form input[type=password]:-ms-input-placeholder,
.form input[type=search]:-ms-input-placeholder,
.form input[type=tel]:-ms-input-placeholder,
.form select:-ms-input-placeholder,
.form textarea:-ms-input-placeholder {
  color: rgba(179, 179, 179, 0.8);
  font-size: 14px;
  font-size: 0.875rem;
}
.form input[type=text]:focus, .form input[type=text].required:focus,
.form input[type=email]:focus,
.form input[type=email].required:focus,
.form input[type=tel]:focus,
.form input[type=tel].required:focus,
.form input[type=number]:focus,
.form input[type=number].required:focus,
.form input[type=password]:focus,
.form input[type=password].required:focus,
.form input[type=search]:focus,
.form input[type=search].required:focus,
.form input[type=tel]:focus,
.form input[type=tel].required:focus,
.form select:focus,
.form select.required:focus,
.form textarea:focus,
.form textarea.required:focus {
  border: 1px solid rgba(243, 109, 33, 0.5);
  box-shadow: 0 0 0.2rem 0.1rem rgba(243, 109, 33, 0.25);
  outline: none;
}
.form input[type=text].required,
.form input[type=email].required,
.form input[type=tel].required,
.form input[type=number].required,
.form input[type=password].required,
.form input[type=search].required,
.form input[type=tel].required,
.form select.required,
.form textarea.required {
  font-weight: 400;
}
.form input[type=text].has-error, .form input[type=text].error,
.form input[type=email].has-error,
.form input[type=email].error,
.form input[type=tel].has-error,
.form input[type=tel].error,
.form input[type=number].has-error,
.form input[type=number].error,
.form input[type=password].has-error,
.form input[type=password].error,
.form input[type=search].has-error,
.form input[type=search].error,
.form input[type=tel].has-error,
.form input[type=tel].error,
.form select.has-error,
.form select.error,
.form textarea.has-error,
.form textarea.error {
  border: 1px solid #b94a48;
  background-color: #f2dede;
  color: #b94a48;
  /* WebKit browsers */
  /* Mozilla Firefox 4 to 18 */
  /* Mozilla Firefox 19+ */
  /* Internet Explorer 10+ */
}
.form input[type=text].has-error::-webkit-input-placeholder, .form input[type=text].error::-webkit-input-placeholder,
.form input[type=email].has-error::-webkit-input-placeholder,
.form input[type=email].error::-webkit-input-placeholder,
.form input[type=tel].has-error::-webkit-input-placeholder,
.form input[type=tel].error::-webkit-input-placeholder,
.form input[type=number].has-error::-webkit-input-placeholder,
.form input[type=number].error::-webkit-input-placeholder,
.form input[type=password].has-error::-webkit-input-placeholder,
.form input[type=password].error::-webkit-input-placeholder,
.form input[type=search].has-error::-webkit-input-placeholder,
.form input[type=search].error::-webkit-input-placeholder,
.form input[type=tel].has-error::-webkit-input-placeholder,
.form input[type=tel].error::-webkit-input-placeholder,
.form select.has-error::-webkit-input-placeholder,
.form select.error::-webkit-input-placeholder,
.form textarea.has-error::-webkit-input-placeholder,
.form textarea.error::-webkit-input-placeholder {
  color: rgba(185, 74, 72, 0.8);
  font-size: 15px;
  font-size: 0.9375rem;
}
.form input[type=text].has-error:-moz-placeholder, .form input[type=text].error:-moz-placeholder,
.form input[type=email].has-error:-moz-placeholder,
.form input[type=email].error:-moz-placeholder,
.form input[type=tel].has-error:-moz-placeholder,
.form input[type=tel].error:-moz-placeholder,
.form input[type=number].has-error:-moz-placeholder,
.form input[type=number].error:-moz-placeholder,
.form input[type=password].has-error:-moz-placeholder,
.form input[type=password].error:-moz-placeholder,
.form input[type=search].has-error:-moz-placeholder,
.form input[type=search].error:-moz-placeholder,
.form input[type=tel].has-error:-moz-placeholder,
.form input[type=tel].error:-moz-placeholder,
.form select.has-error:-moz-placeholder,
.form select.error:-moz-placeholder,
.form textarea.has-error:-moz-placeholder,
.form textarea.error:-moz-placeholder {
  color: #b94a48;
  font-size: 15px;
  font-size: 0.9375rem;
}
.form input[type=text].has-error::-moz-placeholder, .form input[type=text].error::-moz-placeholder,
.form input[type=email].has-error::-moz-placeholder,
.form input[type=email].error::-moz-placeholder,
.form input[type=tel].has-error::-moz-placeholder,
.form input[type=tel].error::-moz-placeholder,
.form input[type=number].has-error::-moz-placeholder,
.form input[type=number].error::-moz-placeholder,
.form input[type=password].has-error::-moz-placeholder,
.form input[type=password].error::-moz-placeholder,
.form input[type=search].has-error::-moz-placeholder,
.form input[type=search].error::-moz-placeholder,
.form input[type=tel].has-error::-moz-placeholder,
.form input[type=tel].error::-moz-placeholder,
.form select.has-error::-moz-placeholder,
.form select.error::-moz-placeholder,
.form textarea.has-error::-moz-placeholder,
.form textarea.error::-moz-placeholder {
  color: #b94a48;
  font-size: 15px;
  font-size: 0.9375rem;
}
.form input[type=text].has-error:-ms-input-placeholder, .form input[type=text].error:-ms-input-placeholder,
.form input[type=email].has-error:-ms-input-placeholder,
.form input[type=email].error:-ms-input-placeholder,
.form input[type=tel].has-error:-ms-input-placeholder,
.form input[type=tel].error:-ms-input-placeholder,
.form input[type=number].has-error:-ms-input-placeholder,
.form input[type=number].error:-ms-input-placeholder,
.form input[type=password].has-error:-ms-input-placeholder,
.form input[type=password].error:-ms-input-placeholder,
.form input[type=search].has-error:-ms-input-placeholder,
.form input[type=search].error:-ms-input-placeholder,
.form input[type=tel].has-error:-ms-input-placeholder,
.form input[type=tel].error:-ms-input-placeholder,
.form select.has-error:-ms-input-placeholder,
.form select.error:-ms-input-placeholder,
.form textarea.has-error:-ms-input-placeholder,
.form textarea.error:-ms-input-placeholder {
  color: rgba(185, 74, 72, 0.8);
  font-size: 15px;
  font-size: 0.9375rem;
}
.form input[type=search] {
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}
.form__item {
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}

/* ==========================================================================
	USER INPUT FORM STYLES
========================================================================== */
.form--userInput input[type=text],
.form--userInput input[type=email],
.form--userInput input[type=tel],
.form--userInput input[type=password],
.form--userInput input[type=search],
.form--userInput select,
.form--userInput textarea {
  height: 45px;
  height: 2.8125rem;
  padding: 8px 6px;
  padding: 0.5rem 0.375rem;
  width: 100%;
}
.form--userInput textarea {
  height: auto;
}
.form--userInput input[type=text]:disabled {
  background: transparent;
}

/* ==========================================================================
	CPR PAGE FORM STYLES
========================================================================== */
@media (min-width: 68.75em) {
  .form--cpr .form__row {
    float: left;
    margin-right: -100%;
    width: 49.3%;
  }
  .form--cpr .form__row:nth-child(odd) {
    clear: both;
  }
  .form--cpr .form__row:nth-child(even) {
    clear: none;
    margin-left: 50.7%;
  }
  .form--cpr .form__row:nth-last-child(-n+2) {
    clear: both;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
  .form--cpr label[for=form-input-fileUpload] {
    display: inline-block;
    margin-top: 2rem;
  }
}
/* ==========================================================================
   HEADER SEARCH FORM STYLES
========================================================================== */
.form--searchHeader {
  font-size: 0;
}
.form--searchHeader p {
  display: inline-block;
}
.form--searchHeader input[type=search] {
  width: 70%;
  display: inline-block;
  vertical-align: top;
  height: 40px;
  height: 2.5rem;
  padding: 10px;
  padding: 0.625rem;
  font-size: 16px;
  font-size: 1rem;
}
.form--searchHeader input[type=search]:focus {
  /* WebKit browsers */
  /* Mozilla Firefox 4 to 18 */
  /* Mozilla Firefox 19+ */
  /* Internet Explorer 10+ */
  border: 1px solid rgba(255, 255, 255, 0.5);
  background-color: #000;
  color: #FFF;
  outline: none;
  box-shadow: none;
}
.form--searchHeader input[type=search]:focus::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  font-size: 0.875rem;
}
.form--searchHeader input[type=search]:focus:-moz-placeholder {
  color: white;
  font-size: 14px;
  font-size: 0.875rem;
}
.form--searchHeader input[type=search]:focus::-moz-placeholder {
  color: white;
  font-size: 14px;
  font-size: 0.875rem;
}
.form--searchHeader input[type=search]:focus:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  font-size: 0.875rem;
}
.form--searchHeader button {
  display: inline-block;
  vertical-align: top;
  height: 62px;
  height: 3.875rem;
  min-width: auto;
}
.form--searchHeader button:hover, .form--searchHeader button:focus, .form--searchHeader button:active {
  background-color: #0e9ee4;
}

/* ==========================================================================
	ERROR STYLES
========================================================================== */
.has-error, .error {
  margin-bottom: 0;
  color: #b94a48;
  font-weight: bold;
}

span.has-error,
span.error {
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 1.8461538462;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}

input.has-error,
input.error {
  margin-bottom: 4px;
  margin-bottom: 0.25rem;
}

/* ==========================================================================
	REQUIRED STYLES
========================================================================== */
.widget_icon {
  color: #F66C21;
  margin-right: 10px;
}

.toggle_widget {
  margin-left: 10px;
  -ms-transform: rotate(267deg); /* IE 9 */
  -webkit-transform: rotate(267deg); /* Safari 3-8 */
  transform: rotate(267deg);
  transition: all 0.3s ease-in-out;
}
.active .toggle_widget {
  -ms-transform: rotate(360deg); /* IE 9 */
  -webkit-transform: rotate(360deg); /* Safari 3-8 */
  transform: rotate(360deg);
}

.contact-us-widget-list .contact-wiget-item {
  margin: 0 0 1.5rem 0;
  padding: 0 0 1.5rem 0;
  border-bottom: 1px solid #d9d9d9;
}
.contact-us-widget-list .contact-wiget-item a {
  color: #000;
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 1.6;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  font-weight: bold;
}
.contact-us-widget-list .contact-wiget-item .contact_widget_form {
  margin-top: 1.5rem;
}

.widget_back_button {
  margin: 0 0 1.5rem 0;
  padding: 0 0 1.5rem 0;
  border-bottom: 1px solid #d9d9d9;
}
.widget_back_button a {
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 1.6;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  font-weight: bold;
  color: #000;
}

.contact_widget_form h4 {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
}
.contact_widget_form a {
  color: #f36d21;
  font-weight: normal;
}
.contact_widget_form h3 {
  margin: 0 0 1.5rem 0;
  font-weight: bold;
  color: #000;
}
.contact_widget_form .errors {
  margin: 10px 0 0 0 !important;
  padding: 0;
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 1.8461538462;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  list-style: none;
}
.contact_widget_form .errors li {
  list-style: none;
}
.contact_widget_form .has-error input, .contact_widget_form .has-error select, .contact_widget_form .has-error textarea,
.contact_widget_form .error input,
.contact_widget_form .error select,
.contact_widget_form .error textarea {
  border: 1px solid #b94a48;
}
.contact_widget_form button {
  background: #f36d21;
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 1.6;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  color: #ffffff;
  border: 0;
  display: inline-block;
  line-height: 100%;
  height: 45px;
  padding: 10px 20px;
  margin: 0;
}
.contact_widget_form .row {
  margin: 0 0 1.5rem 0;
}
.contact_widget_form .row:last-child {
  margin-bottom: 0;
}
.contact_widget_form .form-group .custom_upload {
  display: block;
  margin: 0;
  width: 100%;
  font-family: "Roboto", sans-serif;
  border: 1px solid #dedede;
  font-weight: normal;
  position: relative;
  overflow: hidden;
}
.contact_widget_form .form-group .custom_upload .upload_button {
  position: absolute;
  top: -1px;
  right: 0;
  display: block;
  height: 45px;
  line-height: 45px;
  font-weight: bold !important;
  background: #D9D9D9;
  color: #6A6A6A !important;
  padding: 0 20px;
}
.contact_widget_form .form-group #form-input-topic, .contact_widget_form .form-group .holding_text {
  color: #d1d1d1;
}
.contact_widget_form .form-group .populated {
  color: #6A6A6A !important;
}
.contact_widget_form .form-group textarea {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.7142857143;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  color: #666666;
  margin: 0 !important;
  padding: 10px 10px;
  min-height: 120px;
  line-height: 100%;
}
.contact_widget_form .form-group.col-lg-6 {
  width: 49%;
  float: left;
}
.contact_widget_form .form-group.col-lg-6:first-child {
  margin-right: 2%;
}
.contact_widget_form .form-group.col-lg-12 {
  width: 100%;
  float: none;
}
.contact_widget_form#technical_support .form-group .custom_upload,
.contact_widget_form#technical_support .form-group input,
.contact_widget_form#technical_support .form-group select {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.71429;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  color: #666;
  margin: 0 !important;
  padding: 0 10px;
  height: 45px;
  line-height: 45px;
}
.contact_widget_form#technical_support .form-group input,
.contact_widget_form#technical_support .form-group label,
.contact_widget_form#technical_support .form-group select,
.contact_widget_form#technical_support .form-group textarea {
  display: block;
  margin: 0;
  width: 100%;
  font-family: Roboto, sans-serif;
  font-weight: 400;
}
.contact_widget_form#technical_support input[type=file] {
  padding: 0;
  line-height: 0;
  height: auto;
}

/* MailChimp Form Embed Code - Classic - 12/17/2015 v10.7 */
#mc_embed_signup form {
  display: block;
  position: relative;
  text-align: left;
  padding: 10px 0 10px 3%;
}

#mc_embed_signup h2 {
  font-weight: bold;
  padding: 0;
  margin: 15px 0;
  font-size: 1.6em;
}

#mc_embed_signup input {
  border: 1px solid #ABB0B2;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

#mc_embed_signup input[type=checkbox] {
  -webkit-appearance: checkbox;
}

#mc_embed_signup input[type=radio] {
  -webkit-appearance: radio;
}

#mc_embed_signup input:focus {
  border-color: #333;
}

#mc_embed_signup .button {
  clear: both;
  background-color: #aaa;
  border: 0 none;
  border-radius: 4px;
  transition: all 0.23s ease-in-out 0s;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  font-weight: normal;
  height: 32px;
  line-height: 32px;
  margin: 0 5px 10px 0;
  padding: 0 22px;
  text-align: center;
  text-decoration: none;
  vertical-align: top;
  white-space: nowrap;
  width: auto;
}

#mc_embed_signup .button:hover {
  background-color: #777;
}

#mc_embed_signup .small-meta {
  font-size: 11px;
}

#mc_embed_signup .nowrap {
  white-space: nowrap;
}

#mc_embed_signup .mc-field-group {
  clear: left;
  position: relative;
  width: 96%;
  padding-bottom: 5%;
  min-height: 50px;
}

#mc_embed_signup .size1of2 {
  clear: none;
  float: left;
  display: inline-block;
  width: 46%;
  margin-right: 4%;
}

* html #mc_embed_signup .size1of2 {
  margin-right: 2%; /* Fix for IE6 double margins. */
}

#mc_embed_signup .mc-field-group label {
  display: block;
  margin-bottom: 3px;
}

#mc_embed_signup .mc-field-group input {
  display: block;
  width: 100%;
  padding: 8px 0;
  text-indent: 2%;
}

#mc_embed_signup .mc-field-group select {
  display: inline-block;
  width: 99%;
  padding: 5px 0;
  margin-bottom: 2px;
}

#mc_embed_signup .datefield, #mc_embed_signup .phonefield-us {
  padding: 5px 0;
}

#mc_embed_signup .datefield input, #mc_embed_signup .phonefield-us input {
  display: inline;
  width: 60px;
  margin: 0 2px;
  letter-spacing: 1px;
  text-align: center;
  padding: 5px 0 2px 0;
}

#mc_embed_signup .phonefield-us .phonearea input, #mc_embed_signup .phonefield-us .phonedetail1 input {
  width: 40px;
}

#mc_embed_signup .datefield .monthfield input, #mc_embed_signup .datefield .dayfield input {
  width: 30px;
}

#mc_embed_signup .datefield label, #mc_embed_signup .phonefield-us label {
  display: none;
}

#mc_embed_signup .indicates-required {
  text-align: right;
  font-size: 11px;
  margin-right: 4%;
}

#mc_embed_signup .asterisk {
  color: #e85c41;
  font-size: 150%;
  font-weight: normal;
  position: relative;
  top: 5px;
}

#mc_embed_signup .clear {
  clear: both;
}

#mc_embed_signup .mc-field-group.input-group ul {
  margin: 0;
  padding: 5px 0;
  list-style: none;
}

#mc_embed_signup .mc-field-group.input-group ul li {
  display: block;
  padding: 3px 0;
  margin: 0;
}

#mc_embed_signup .mc-field-group.input-group label {
  display: inline;
}

#mc_embed_signup .mc-field-group.input-group input {
  display: inline;
  width: auto;
  border: none;
}

#mc_embed_signup div#mce-responses {
  float: left;
  top: -1.4em;
  padding: 0em 0.5em 0em 0.5em;
  overflow: hidden;
  width: 90%;
  margin: 0 5%;
  clear: both;
}

#mc_embed_signup div.response {
  margin: 1em 0;
  padding: 1em 0.5em 0.5em 0;
  font-weight: bold;
  float: left;
  top: -1.5em;
  z-index: 1;
  width: 80%;
}

#mc_embed_signup #mce-error-response {
  display: none;
}

#mc_embed_signup #mce-success-response {
  color: #529214;
  display: none;
}

#mc_embed_signup label.error {
  display: block;
  float: none;
  width: auto;
  margin-left: 1.05em;
  text-align: left;
  padding: 0.5em 0;
}

#mc-embedded-subscribe {
  clear: both;
  width: auto;
  display: block;
  margin: 1em 0 1em 5%;
}

#mc_embed_signup #num-subscribers {
  font-size: 1.1em;
}

#mc_embed_signup #num-subscribers span {
  padding: 0.5em;
  border: 1px solid #ccc;
  margin-right: 0.5em;
  font-weight: bold;
}

#mc_embed_signup #mc-embedded-subscribe-form div.mce_inline_error {
  display: inline-block;
  margin: 2px 0 0;
  padding: 5px 10px;
  background-color: rgba(255, 255, 255, 0.85);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: normal;
  z-index: 1;
  color: #e85c41;
}

#mc_embed_signup #mc-embedded-subscribe-form input.mce_inline_error {
  border: 2px solid #e85c41;
}

#mc_embed_signup.custom-mailchimp-popup {
  background: rgba(0, 0, 0, 0.8);
  clear: left;
  font: 16px Helvetica, Arial, sans-serif;
  position: fixed;
  top: 0;
  margin: 0 auto;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  max-height: 100%;
  overflow-y: auto;
  display: none;
}
#mc_embed_signup.custom-mailchimp-popup .popup__header {
  max-width: 501px;
  margin: 0 auto;
  position: relative;
  min-height: 20px;
  background: black;
}
#mc_embed_signup.custom-mailchimp-popup .popup__header .close {
  cursor: pointer;
  position: fixed;
  top: 10px;
  right: 10px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: white;
  text-align: center;
  line-height: 25px;
}
#mc_embed_signup.custom-mailchimp-popup h3 {
  color: #666;
}
#mc_embed_signup.custom-mailchimp-popup form {
  height: 100%;
  max-width: 501px;
  margin: 0 auto;
  background: white;
  padding: 20px;
}
#mc_embed_signup.custom-mailchimp-popup form .mc-field-group {
  margin: 0;
  width: 100%;
}
#mc_embed_signup.custom-mailchimp-popup form .mc-field-group label {
  font-size: 14px;
  color: black;
}
#mc_embed_signup.custom-mailchimp-popup input, #mc_embed_signup.custom-mailchimp-popup button {
  margin: 0;
}
#mc_embed_signup.custom-mailchimp-popup .email {
  font: 16px Helvetica, Arial, sans-serif;
  margin-bottom: 0;
}
#mc_embed_signup.custom-mailchimp-popup #mce-responses {
  float: left;
  top: -1.4em;
  padding: 0 0.5em;
  overflow: hidden;
  width: 100%;
  margin: 0;
  clear: both;
}
#mc_embed_signup.custom-mailchimp-popup .response {
  margin: 1em 0;
  padding: 0;
  font-weight: 700;
  float: left;
  top: -1.5em;
  z-index: 1;
  width: 100%;
}
@media only screen and (min-width: 500px) {
  #mc_embed_signup.custom-mailchimp-popup {
    padding: 5% 0;
    position: fixed;
  }
  #mc_embed_signup.custom-mailchimp-popup .popup__header .close {
    position: absolute;
    top: -10px;
    right: -10px;
  }
  #mc_embed_signup.custom-mailchimp-popup form {
    height: auto;
  }
}

/* ==========================================================================
  HEADER STYLES
========================================================================== */
.header {
  position: relative;
}

.header__topBar {
  padding-top: 12px;
  padding-top: 0.75rem;
  padding-bottom: 12px;
  padding-bottom: 0.75rem;
  background-color: #f36d21;
  position: relative;
  z-index: 100;
}

.header__logos {
  display: inline-block;
}

.header__topNav {
  display: none;
}
@media (min-width: 47.5em) {
  .header__topNav {
    margin-top: 12px;
    margin-top: 0.75rem;
    display: inline-block;
    float: right;
    text-align: right;
  }
}

/* ==========================================================================
  HEADER MAIN MENU AREA STYLES
========================================================================== */
.header__navArea--with-slider {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 100;
}

.header__nav {
  position: relative;
  z-index: 500;
  background-color: rgba(0, 0, 0, 0.5);
}
.header__nav .container {
  margin-right: 0;
}

.header__tel {
  display: none;
  padding: 12px 0px;
  padding: 0.75rem 0rem;
}
@media (min-width: 68.75em) {
  .header__tel {
    width: 22.8571428571%;
    float: right;
    margin-right: 0;
    text-align: right;
  }
}

/* ==========================================================================
  HEADER SEARCH STYLES
========================================================================== */
.header__search {
  display: block;
  height: 100%;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: all 0.4s ease;
  width: 100%;
  z-index: -1;
}
@media (min-width: 59.375em) {
  .header__search {
    position: absolute;
  }
}
.js_search_is_open .header__search {
  background-color: rgba(0, 0, 0, 0.95);
  opacity: 1;
  z-index: 600;
}
.header__search.header__search--force-visible {
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
  z-index: 0;
  position: relative;
}
@media (min-width: 59.375em) {
  .header__search.header__search--force-visible {
    position: absolute;
  }
}
.header__search.header__search--force-visible .search-header {
  padding: 6% 3%;
}

/* ==========================================================================
  HEADER NAV STYLES
========================================================================== */
.header__nav {
  display: none;
}
@media (min-width: 47.5em) {
  .header__nav {
    display: block;
  }
}
.header__nav.is-active {
  animation-name: fadeInDown;
  animation-iteration-count: 1s;
  animation-duration: 1s;
  animation-delay: 0s;
  animation-timing-function: ease;
  animation-fill-mode: both;
  backface-visibility: hidden;
  background-color: #000;
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
}

/* ==========================================================================
	HEADER BTTON STYLES
========================================================================== */
.header__btns {
  width: 40%;
  float: right;
  margin-right: 0;
  text-align: right;
  margin-top: 8px;
  margin-top: 0.5rem;
  width: calc(60px + 1.75rem);
}
@media (min-width: 47.5em) {
  .header__btns {
    display: none;
  }
}

.header__btn {
  text-align: center;
  position: relative;
  background-color: #f36d21;
  display: inline-block;
  vertical-align: middle;
  padding: 4px;
  padding: 0.25rem;
}
.header__btn + .header__btn {
  margin-left: 12px;
  margin-left: 0.75rem;
}
.header__btn .icon {
  vertical-align: middle;
  height: 24px;
  width: 24px;
}
.header__btn .header__btn-icon {
  color: #FFF;
}

/* ==========================================================================
   FOOTER STYLES
========================================================================== */
.footer {
  width: 100%;
  position: relative;
  background-color: #f36d21;
  padding-top: 40px;
  padding-top: 2.5rem;
}

.footer__legals {
  clear: both;
  margin-top: 60px;
  margin-top: 3.75rem;
}
@media (min-width: 59.375em) {
  .footer__copyright {
    width: 40%;
    display: inline-block;
  }
}
.footer__nav {
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}
@media (min-width: 59.375em) {
  .footer__nav {
    display: inline-block;
    text-align: right;
    width: 57.1428571429%;
  }
}

@media (min-width: 68.75em) {
  .footer__top {
    width: 65.7142857143%;
    float: left;
    margin-right: 2.8571428571%;
  }
}

.footer__logo {
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}
@media (min-width: 37.5em) {
  .footer__logo {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    margin-right: 30px;
    margin-right: 1.875rem;
    padding-right: 30px;
    padding-right: 1.875rem;
    margin-bottom: 0;
  }
  .footer__logo:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    display: block;
    width: 1px;
    background: rgba(255, 255, 255, 0.5);
  }
}
.footer__logo img {
  width: 180px;
  height: 57px;
}
@media (min-width: 47.5em) {
  .footer__logo img {
    width: 143px;
    height: 45px;
  }
}

@media (min-width: 37.5em) {
  .footer__social {
    display: inline-block;
    vertical-align: middle;
  }
}
@media (min-width: 47.5em) {
  .footer__social {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    margin-right: 30px;
    margin-right: 1.875rem;
    padding-right: 30px;
    padding-right: 1.875rem;
  }
  .footer__social:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    display: block;
    width: 1px;
    background: rgba(255, 255, 255, 0.5);
  }
}

.footer__contact {
  margin-top: 24px;
  margin-top: 1.5rem;
}
@media (min-width: 47.5em) {
  .footer__contact {
    margin-top: 0;
    display: inline-block;
    vertical-align: middle;
  }
}

/* ==========================================================================
   FOOTER SEARCH STYLES
========================================================================== */
.footer__search {
  margin-top: 60px;
  margin-top: 3.75rem;
}
@media (min-width: 68.75em) {
  .footer__search {
    width: 31.4285714286%;
    float: right;
    margin-right: 0;
    margin-top: 12px;
    margin-top: 0.75rem;
  }
}

.footer__search {
  color: #FFF;
  position: relative;
}
.footer__search input {
  width: 80%;
  padding-bottom: 12px;
  padding-bottom: 0.75rem;
  padding-left: 24px;
  padding-left: 1.5rem;
  background-color: transparent;
  color: inherit;
  border: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  font-size: 13px;
  font-size: 0.8125rem;
  /* WebKit browsers */
  /* Mozilla Firefox 4 to 18 */
  /* Mozilla Firefox 19+ */
  /* Internet Explorer 10+ */
}
.footer__search input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
  font-size: 15px;
  font-size: 0.9375rem;
}
.footer__search input:-moz-placeholder {
  color: white;
  font-size: 15px;
  font-size: 0.9375rem;
}
.footer__search input::-moz-placeholder {
  color: white;
  font-size: 15px;
  font-size: 0.9375rem;
}
.footer__search input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
  font-size: 15px;
  font-size: 0.9375rem;
}
.footer__search .icon {
  width: 16px;
  height: 16px;
}
.footer__search__icon {
  position: absolute;
  top: 2px;
  top: 0.125rem;
}

/* ==========================================================================
	FLEX NAV STYLES
========================================================================== */
.flexnav ul ul {
  opacity: 0;
}
.flexnav li {
  position: relative;
}
.flexnav li a {
  position: relative;
}
.flexnav li ul {
  width: 100%;
  z-index: 900;
}
@media (min-width: 47.5em) {
  .flexnav li ul {
    position: absolute;
    top: auto;
    width: 350px;
  }
}
@media (min-width: 47.5em) {
  .flexnav li ul ul {
    margin-left: 100%;
    top: 0;
  }
}
.flexnav li ul li {
  position: relative;
  overflow: hidden;
}
@media (min-width: 47.5em) {
  .flexnav li ul li {
    display: block;
  }
}
.flexnav li ul li a {
  transition: all 0.4s ease;
  display: block;
}
.flexnav li ul.flexnav-show {
  opacity: 1;
  animation-name: fadeInDown;
  animation-iteration-count: 1s;
  animation-duration: 1s;
  animation-delay: 0s;
  animation-timing-function: ease;
  animation-fill-mode: both;
  backface-visibility: hidden;
}
.flexnav li ul.flexnav-show li {
  overflow: visible;
}

.flexnav-show + .touch-button .arrow {
  transition: all 0.4s ease;
  transform: rotate(-45deg);
}
@media (min-width: 47.5em) {
  .flexnav-show + .touch-button .arrow {
    transition: all 0.4s ease;
    transform: rotate(135deg);
    color: #f36d21;
  }
}

/* ==========================================================================
	NAVIGATION STYLES
========================================================================== */
/* ==========================================================================
	 MAIN NAV
========================================================================== */
.nav--main {
  font-size: 0;
}
.nav--main li {
  position: relative;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.7142857143;
  margin-bottom: 0;
  color: #FFF;
  list-style: none;
}
@media (min-width: 47.5em) {
  .nav--main li {
    display: inline-block;
    margin-bottom: 0;
  }
  .nav--main li:not(:last-child) {
    margin-right: -4px;
    margin-right: -0.25rem;
  }
}
@media (min-width: 47.5em) and (min-width: 68.75em) {
  .nav--main li:not(:last-child) {
    margin-right: 16px;
    margin-right: 1rem;
  }
}
.nav--main li.item-with-ul a {
  padding-right: 27px;
  padding-right: 1.6875rem;
}
.nav--main a {
  position: relative;
  display: block;
  color: currentColor;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding: 12px;
  padding: 0.75rem;
}
@media (min-width: 47.5em) {
  .nav--main a {
    border-bottom: 0;
    padding: 12px;
    padding: 0.75rem;
  }
}
.nav--main a:hover, .nav--main a:focus, .nav--main a:active {
  color: #f36d21;
  text-decoration: none;
}
.nav--main .nav-item--is-top-parent, .nav--main .nav-item--is-current {
  color: #f36d21;
}
.nav--main .touch-button {
  display: inline-block;
  position: absolute;
  z-index: 999;
  top: 6px;
  top: 0.375rem;
  right: 10px;
  right: 0.625rem;
  width: 35px;
  width: 2.1875rem;
  height: 35px;
  height: 2.1875rem;
  line-height: 32px;
  line-height: 2rem;
  background-color: #ee5e0d;
  text-align: center;
}
@media (min-width: 47.5em) {
  .nav--main .touch-button {
    right: -2px;
    right: -0.125rem;
    background-color: transparent;
  }
}
.nav--main .touch-button:hover, .nav--main .touch-button:focus, .nav--main .touch-button:active {
  cursor: pointer;
}
.nav--main li ul li {
  font-size: 13px;
  font-size: 0.8125rem;
  margin-right: 0 !important;
}
.nav--main li ul li a {
  display: block;
  padding: 12px 24px;
  padding: 0.75rem 1.5rem;
  background: #000;
  color: currentColor;
}
.nav--main li ul li a:hover, .nav--main li ul li a:focus, .nav--main li ul li a:active {
  padding-left: 36px;
  padding-left: 2.25rem;
  color: #FFF;
  background-color: #0e9ee4;
}
.nav--main .hovered > a {
  background-color: #000;
  color: #f36d21;
}
.nav--main .arrow {
  border: 1px solid currentColor;
  border-width: 1px 1px 0 0;
  transform: rotate(135deg);
  width: 7px;
  height: 7px;
  display: inline-block;
  position: relative;
  color: #FFF;
}

/* ==========================================================================
	 TOP NAV
========================================================================== */
.nav--top li {
  display: inline-block;
  list-style: none;
  font-size: 12px;
  font-size: 0.75rem;
}
.nav--top li:not(:last-of-type) {
  margin-right: 60px;
  margin-right: 3.75rem;
}
.nav--top a {
  display: block;
  color: #FFF;
}
.nav--top .icon {
  height: 14px;
  width: 14px;
  margin-right: 2px;
  margin-right: 0.125rem;
}

/* ==========================================================================
	 TOP NAV
========================================================================== */
.nav--footer li {
  list-style: none;
  font-size: 12px;
  font-size: 0.75rem;
  text-align: right;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  margin-top: 10px;
  margin-top: 0.625rem;
}
@media (min-width: 37.5em) {
  .nav--footer li {
    margin-top: 0px;
    margin-top: 0rem;
  }
}
.nav--footer li:not(:last-of-type) {
  margin-right: 5px;
  margin-right: 0.3125rem;
  padding-right: 5px;
  padding-right: 0.3125rem;
}
.nav--footer li:not(:last-of-type):after {
  content: "";
  position: absolute;
  top: 3px;
  top: 0.1875rem;
  bottom: 0;
  right: 0;
  height: 10px;
  height: 0.625rem;
  display: block;
  width: 1px;
  background: rgba(255, 255, 255, 0.5);
}
@media (min-width: 37.5em) {
  .nav--footer li:not(:last-of-type) {
    margin-right: 15px;
    margin-right: 0.9375rem;
    padding-right: 15px;
    padding-right: 0.9375rem;
  }
}
.nav--footer a {
  display: block;
  color: #FFF;
}

/* ==========================================================================
	 SIDEBAR SCROLLING  NAV
========================================================================== */
.nav--scroll li {
  list-style: none;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}
.nav--scroll a:before {
  border: 2px solid currentColor;
  border-width: 2px 2px 0 0;
  transform: rotate(135deg);
  width: 8px;
  height: 8px;
  content: "";
  position: relative;
  display: inline-block;
  top: -3px;
  top: -0.1875rem;
  margin-right: 4px;
  margin-right: 0.25rem;
}

/* ==========================================================================
	 SIDEBAR  NAV
========================================================================== */
.nav--sidebar li {
  list-style: none;
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 1.5;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}
.nav--sidebar li:first-child {
  margin-top: 24px;
  margin-top: 1.5rem;
}
.nav--sidebar .date {
  font-size: 11px;
  font-size: 0.6875rem;
  line-height: 1;
  margin-bottom: 6px;
  margin-bottom: 0.375rem;
}

/* ==========================================================================
	CONTACT STYLES
========================================================================== */
.container--contact h1, .container--contact h2, .container--contact h3, .container--contact h4, .container--contact .lead {
  font-weight: 500;
  padding-top: 30px;
}
.container--contact h1 {
  margin-right: 0;
  margin-bottom: 0;
  padding-top: 0;
  font-weight: 400;
}
.container--contact h2 {
  color: #666;
}
.container--contact h3 {
  color: black;
}
.container--contact h4 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  padding-bottom: 14px;
  margin-bottom: 14px;
}
.container--contact .lead {
  padding-top: 10px;
  margin-bottom: 10px;
}
.container--contact .lead p {
  margin-bottom: 0;
}

.tel {
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}

.email {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}

.address {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.7142857143;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  margin-bottom: 30px;
}

.map-wrapper {
  position: relative;
}
.map-wrapper .map__overlay {
  background-color: #f36d21;
  color: white;
  font-size: 12px;
  line-height: 1.4;
  position: absolute;
  padding: 28px 28px 28px 50px;
  left: 10px;
  bottom: 35px;
  max-width: 245px;
  max-height: 200px;
}
@media (min-width: 59.375em) {
  .map-wrapper .map__overlay {
    top: 42px;
    left: auto;
    right: 15%;
    bottom: unset;
    height: 184px;
  }
}
.map-wrapper .map__overlay img.pin-icon {
  width: 22px;
  position: absolute;
  left: 20px;
}

.support-icon {
  max-height: 90px;
}

.contact-icon {
  max-height: 25px;
  max-width: 25px;
  float: left;
  margin-right: 10px;
}

.btn--support {
  display: inline-block;
  background-color: #f36d21;
  border: 0 none;
  padding: 15px 30px;
  color: white;
}
.btn--support:hover, .btn--support:focus, .btn--support:visited, .btn--support:active {
  color: white;
}
.btn--support:hover {
  background-color: #000;
}
.btn--support.pagination__link--next:after {
  margin-left: 5px;
}

.executive {
  background-color: #f36d21;
  color: white;
  padding: 30px 30px 20px;
  margin: 65px 10px 0;
}
.executive h1, .executive h2, .executive h3, .executive h4, .executive h5, .executive p, .executive a {
  color: white;
  margin: 0;
  padding: 0 0 10px 0;
  border: 0 none;
}
.executive img {
  display: block;
  text-align: center;
  margin: 30px auto;
  max-width: 133px;
  max-height: 133px;
}
@media (min-width: 68.75em) {
  .executive img {
    margin: -96px auto 30px;
  }
}

@media (min-width: 59.375em) {
  .faq {
    padding-left: 60px;
    padding-right: 60px;
  }
}
.faq h1 {
  padding-top: 10px;
  padding-bottom: 30px;
  font-weight: normal;
}
.faq .faq__item .faq__item__question {
  font-weight: 600;
  margin-bottom: 0;
}

/* ==========================================================================
	GOOGLE MAP STYLES
========================================================================== */
.map {
  clear: both;
  width: 100%;
}

#map-google {
  height: 280px;
  width: 100%;
}
@media (min-width: 59.375em) {
  #map-google {
    height: 450px;
  }
}
#map-google img {
  max-width: inherit;
}

/* ==========================================================================
	TERM STYLES
========================================================================== */
.term {
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}
.term:last-of-type {
  border: 0;
}

/* ==========================================================================
  HEADER STYLES
========================================================================== */
.banner {
  background-color: #000;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.banner--active {
  animation-name: fadeIn;
  animation-iteration-count: 1s;
  animation-duration: 1s;
  animation-delay: 0s;
  animation-timing-function: ease;
  animation-fill-mode: both;
  backface-visibility: hidden;
}
.banner--spacing {
  padding-bottom: 35%;
}
@media (min-width: 47.5em) {
  .banner--spacing {
    padding-bottom: 25%;
  }
}
.banner--spacing--smaller {
  padding-bottom: 35%;
}
@media (min-width: 47.5em) {
  .banner--spacing--smaller {
    padding-bottom: 25%;
  }
}
@media (min-width: 68.75em) {
  .banner--spacing--smaller {
    padding-bottom: 21%;
  }
}
.banner--spacing--smaller {
  padding-bottom: 40%;
}
@media (min-width: 47.5em) {
  .banner--spacing--smaller {
    padding-bottom: 25%;
  }
}
@media (min-width: 68.75em) {
  .banner--spacing--smaller {
    padding-bottom: 15%;
  }
}
.banner--spacing--no-padding {
  padding: 0;
}
@media (min-width: 59.375em) {
  .banner--spacing--no-padding {
    padding-bottom: 25%;
  }
}

/* ==========================================================================
  BANNEWR CAROUSEL STYLES
========================================================================== */
.banner--carousel {
  min-height: 400px;
  background-color: #666666;
}
@media screen and (max-width: 759px) {
  .banner--carousel .slick-dots--grey {
    position: absolute !important;
    width: 100% !important;
    z-index: 100 !important;
    top: -40px !important;
  }
}
.banner--carousel .btn {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}
@media screen and (max-width: 759px) {
  .banner--carousel {
    min-height: 600px;
    background-position: 100% 0;
    background-size: 200%;
    padding-top: 63%;
  }
}

.banner__content {
  max-width: 30rem;
  position: relative;
  z-index: 30;
  color: #FFF;
  padding-top: 30px;
  padding-top: 1.875rem;
  padding-bottom: 40px;
  padding-bottom: 2.5rem;
}
.banner__content p {
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 1.5;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}
@media screen and (max-width: 759px) {
  .banner__content {
    max-width: 100%;
    padding-top: 10px;
    padding-top: 0.625rem;
    padding-bottom: 10px;
    padding-bottom: 0.625rem;
  }
}

.banner__inline__title {
  margin-top: 24px;
  margin-bottom: 30px;
}
.banner__inline__title .banner__title {
  color: #fff;
  display: inline;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
  line-height: 1.4;
  font-family: "Roboto", sans-serif;
  font-size: 36px;
  font-size: 2.25rem;
  line-height: 1.2;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  display: inline;
  font-weight: bold;
  margin-bottom: 0;
}
@media (min-width: 37.5em) {
  .banner__inline__title .banner__title {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 1.2;
    margin-bottom: 24px;
    margin-bottom: 1.5rem;
  }
}
.banner__inline__title .banner__title span {
  line-height: 1.4;
  padding: 0.45rem 20px;
  border-left: 4px solid #f36c21;
  background-color: #000000;
}
@media screen and (max-width: 759px) {
  .banner__inline__title .banner__title span {
    display: inline-block;
    padding: 0 20px;
  }
}

.banner__copy {
  position: relative;
}
.banner__copy p {
  font-size: 18px;
  margin-bottom: 30px;
}
.banner__copy img {
  position: absolute;
  bottom: -97px;
  right: 22px;
}

.slick-current .banner__content {
  animation-name: fadeIn;
  animation-iteration-count: 1s;
  animation-duration: 1s;
  animation-delay: 0.5s;
  animation-timing-function: ease;
  animation-fill-mode: both;
  backface-visibility: hidden;
}
.slick-current .banner__title {
  animation-name: fadeIn;
  animation-iteration-count: 1s;
  animation-duration: 1s;
  animation-delay: 0.5s;
  animation-timing-function: ease;
  animation-fill-mode: both;
  backface-visibility: hidden;
}
.slick-current .banner__copy {
  animation-name: fadeIn;
  animation-iteration-count: 1s;
  animation-duration: 1s;
  animation-delay: 0.5s;
  animation-timing-function: ease;
  animation-fill-mode: both;
  backface-visibility: hidden;
}
.slick-current .btn {
  animation-name: fadeIn;
  animation-iteration-count: 1s;
  animation-duration: 1s;
  animation-delay: 0.5s;
  animation-timing-function: ease;
  animation-fill-mode: both;
  backface-visibility: hidden;
}

.fullscreen-videoPopup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  display: none;
}

.fullscreen-videoPopup.active {
  display: block;
}

.fullscreen-videoPopup__close {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 9;
  margin: 0 auto;
  padding: 10px;
  max-width: 800px;
  border-radius: 5px;
  background: black;
  color: white;
  text-align: center;
  cursor: pointer;
}

.embed-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  width: 100%;
}

/* ==========================================================================
	BREADCRUMB STYLES
========================================================================== */
.breadcrumb {
  position: absolute;
  top: -34px;
  top: -2.125rem;
  display: inline-block;
}

/* ==========================================================================
	BREADCRUMB NAV STYLES
========================================================================== */
.nav--breadcrumb {
  background-color: #FFF;
}
.nav--breadcrumb ul {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: auto;
  max-width: 300px;
}
@media (min-width: 37.5em) {
  .nav--breadcrumb ul {
    max-width: 500px;
  }
}
.nav--breadcrumb__item {
  list-style: none;
  display: inline;
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 500;
  margin-right: 20px;
  margin-right: 1.25rem;
  height: 34px;
  line-height: 38px;
}
.nav--breadcrumb__item:not(:last-of-type):after {
  content: "//";
  margin-left: 20px;
  margin-left: 1.25rem;
}
.nav--breadcrumb__item a {
  transition: all 0.4s ease;
  color: inherit;
}
.nav--breadcrumb__item a:hover, .nav--breadcrumb__item a:focus, .nav--breadcrumb__item a:active {
  color: #000;
}
.nav--breadcrumb__home {
  display: inline-block;
  transition: all 0.4s ease;
  background-color: #a0a0a0;
  margin-top: 0;
  height: 34px;
  width: 34px;
  line-height: 38px;
  text-align: center;
  color: #FFF;
}
.nav--breadcrumb__home:not(:only-child) {
  margin-right: 20px;
  margin-right: 1.25rem;
}
.nav--breadcrumb__home:after {
  display: none;
}
.nav--breadcrumb__home:hover, .nav--breadcrumb__home:focus, .nav--breadcrumb__home:active {
  transition: all 0.4s ease;
  background-color: #000;
}
.nav--breadcrumb__home a {
  color: inherit;
}
.nav--breadcrumb__home a:hover, .nav--breadcrumb__home a:focus, .nav--breadcrumb__home a:active {
  color: #FFF;
}
.nav--breadcrumb__current {
  color: #f36d21;
  font-weight: 700;
}
.nav--breadcrumb .icon {
  width: 15px;
  height: 15px;
}

/* ==========================================================================
   TABS
========================================================================== */
.tabs {
  clear: both;
}

/* ==========================================================================
   RESPONSIVE TABS
========================================================================== */
.r-tabs .r-tabs-nav {
  margin: 0;
  padding: 0;
  display: none;
}
@media (min-width: 55.625em) {
  .r-tabs .r-tabs-nav {
    display: block;
  }
}

.r-tabs .r-tabs-tab {
  display: inline-block;
  margin: 0;
  list-style: none;
}

.r-tabs .r-tabs-panel {
  display: none;
}

.r-tabs .r-tabs-accordion-title {
  display: block;
}
@media (min-width: 55.625em) {
  .r-tabs .r-tabs-accordion-title {
    display: none;
  }
}

.r-tabs .r-tabs-panel.r-tabs-state-active {
  display: block;
}

/* ==========================================================================
   BAR-UNDER-SELECTED-TAB VARIANT OF RESPONSIVE TABS
========================================================================== */
.tabs--bar.r-tabs {
  border-top: 1px solid #d9d9d9;
}
@media (min-width: 59.375em) {
  .tabs--bar.r-tabs {
    border-style: none;
  }
}
.tabs--bar .r-tabs-nav {
  border-bottom: 1px solid #d9d9d9;
}
.tabs--bar .r-tabs-anchor {
  border-color: #d9d9d9;
  border-style: none none solid;
  border-width: 1px;
  display: block;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: bold;
  padding: 24px;
  padding: 1.5rem;
  position: relative;
}
@media (min-width: 59.375em) {
  .tabs--bar .r-tabs-anchor {
    border-style: none;
  }
}
@media (min-width: 68.75em) {
  .tabs--bar .r-tabs-anchor {
    padding: 24px 48px;
    padding: 1.5rem 3rem;
  }
}
.tabs--bar .r-tabs-anchor::before {
  color: #f36d21;
  content: "▼";
  display: block;
  position: absolute;
  right: 12px;
  right: 0.75rem;
  font-size: 14px;
  font-size: 0.875rem;
  top: 50%;
  transform: translateY(-50%);
}
@media (min-width: 59.375em) {
  .tabs--bar .r-tabs-anchor::before {
    display: none;
  }
}
.tabs--bar .r-tabs-state-active > .r-tabs-anchor {
  color: #666;
  position: relative;
}
.tabs--bar .r-tabs-state-active > .r-tabs-anchor::before {
  content: "▲";
}
.tabs--bar .r-tabs-state-active > .r-tabs-anchor::after {
  background-color: #f36d21;
  content: "";
  display: block;
  height: 5px;
  left: 0;
  position: absolute;
  top: 100%;
  width: 100%;
  transform: translateY(-2px);
}
.tabs--bar .r-tabs-panel {
  padding: 24px;
  padding: 1.5rem;
}

.tabs--border-bar {
  border-bottom: 1px solid #d9d9d9;
}
@media (min-width: 59.375em) {
  .tabs--border-bar {
    border-bottom-style: none;
  }
}

.tabs--cpr .island-top {
  margin-top: 0 !important;
}
.tabs--cpr .figure--fullwidth {
  margin-left: 0;
}
.tabs--cpr .figcaption {
  display: none;
}
@media (min-width: 59.375em) {
  .tabs--cpr .media__img {
    max-width: 300px;
  }
}

.tabs--container > .r-tabs-nav {
  margin-left: auto;
  margin-right: auto;
}
.tabs--container .r-tabs-panel {
  padding-left: 0;
  padding-right: 0;
}
.tabs--container .figure--fullwidth {
  margin-bottom: 0;
  margin-top: 0;
}

/* ==========================================================================
   TABS SEARCH
========================================================================== */
.cableSearch .r-tabs {
  position: relative;
}
@media (min-width: 55.625em) {
  .cableSearch .r-tabs {
    top: -72px;
    top: -4.5rem;
    margin-bottom: -72px;
    margin-bottom: -4.5rem;
  }
}
.cableSearch .r-tabs-nav {
  font-size: 0;
  position: relative;
}
.cableSearch .r-tabs .r-tabs-nav .r-tabs-tab {
  position: relative;
}
.cableSearch .r-tabs .r-tabs-nav .r-tabs-tab:not(:last-of-type) {
  margin-right: 1px;
  margin-right: 0.0625rem;
}
.cableSearch .r-tabs .r-tabs-nav .r-tabs-anchor {
  display: block;
  font-family: "Roboto", sans-serif;
  font-size: 19px;
  font-size: 1.1875rem;
  padding: 24px 36px;
  padding: 1.5rem 2.25rem;
  background-color: #d9d9d9;
  color: #888888;
  text-align: center;
}
.cableSearch .r-tabs .r-tabs-nav .r-tabs-anchor:hover, .cableSearch .r-tabs .r-tabs-nav .r-tabs-anchor:focus, .cableSearch .r-tabs .r-tabs-nav .r-tabs-anchor:active {
  background-color: silver;
  color: #6f6f6f;
}
.cableSearch .r-tabs .r-tabs-nav .r-tabs-state-active .r-tabs-anchor {
  background-color: #f36d21;
  color: #FFF;
  border-bottom: 1px solid #f36d21;
}
.cableSearch .r-tabs .r-tabs-panel {
  z-index: 100;
}
.cableSearch .r-tabs .r-tabs-accordion-title .r-tabs-anchor {
  display: block;
  background-color: #d9d9d9;
  color: #888888;
  font-family: "Roboto", sans-serif;
  padding: 24px;
  padding: 1.5rem;
  font-size: 18px;
  font-size: 1.125rem;
  border-bottom: 1px solid #FFF;
}
.cableSearch .r-tabs .r-tabs-accordion-title .r-tabs-anchor:after {
  content: "▼";
  position: absolute;
  right: 12px;
  right: 0.75rem;
  font-size: 14px;
  font-size: 0.875rem;
}
.cableSearch .r-tabs .r-tabs-accordion-title.r-tabs-state-active .r-tabs-anchor {
  background-color: #a54109;
  color: #FFF;
  border-bottom: 1px solid #a54109;
}
.cableSearch .r-tabs .r-tabs-accordion-title.r-tabs-state-active .r-tabs-anchor:after {
  content: "▲";
}

/* ==========================================================================
   TABS CLIENTS
========================================================================== */
.clientTabs .r-tabs {
  position: relative;
}
@media (min-width: 55.625em) {
  .clientTabs .r-tabs {
    top: -64px;
    top: -4rem;
    margin-bottom: -64px;
    margin-bottom: -4rem;
  }
}
.clientTabs .r-tabs-nav {
  font-size: 0;
  position: relative;
  border-bottom: 1px solid #d9d9d9;
}
.clientTabs .r-tabs .r-tabs-nav .r-tabs-tab {
  position: relative;
}
.clientTabs .r-tabs .r-tabs-nav .r-tabs-tab:not(:last-of-type) {
  margin-right: 1px;
  margin-right: 0.0625rem;
}
.clientTabs .r-tabs .r-tabs-nav .r-tabs-anchor {
  display: block;
  font-family: "Roboto", sans-serif;
  font-size: 19px;
  font-size: 1.1875rem;
  padding: 20px;
  padding: 1.25rem;
  background-color: #FFF;
  color: #f36d21;
  font-weight: 600;
  min-width: 180px;
  text-align: center;
  border-bottom: 4px solid #FFF;
}
.clientTabs .r-tabs .r-tabs-nav .r-tabs-anchor:hover, .clientTabs .r-tabs .r-tabs-nav .r-tabs-anchor:focus, .clientTabs .r-tabs .r-tabs-nav .r-tabs-anchor:active {
  color: #6f6f6f;
  border-bottom: 4px solid #f36d21;
}
.clientTabs .r-tabs .r-tabs-nav .r-tabs-state-active .r-tabs-anchor {
  background-color: #FFF;
  color: #888888;
  border-bottom: 4px solid #f36d21;
}
.clientTabs .r-tabs .r-tabs-panel {
  z-index: 100;
}
.clientTabs .r-tabs .r-tabs-accordion-title .r-tabs-anchor {
  display: block;
  background-color: #FFF;
  font-family: "Roboto", sans-serif;
  padding: 24px;
  padding: 1.5rem;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 600;
  border-top: 1px solid #d9d9d9;
}
.clientTabs .r-tabs .r-tabs-accordion-title .r-tabs-anchor:after {
  content: "▼";
  position: absolute;
  right: 12px;
  right: 0.75rem;
  font-size: 14px;
  font-size: 0.875rem;
}
.clientTabs .r-tabs .r-tabs-accordion-title .r-tabs-anchor:hover {
  color: #f36d21;
}
.clientTabs .r-tabs .r-tabs-accordion-title.r-tabs-state-active .r-tabs-anchor {
  color: #888888;
  border-bottom: 4px solid #f36d21;
}
.clientTabs .r-tabs .r-tabs-accordion-title.r-tabs-state-active .r-tabs-anchor:after {
  content: "▲";
}

/* ==========================================================================
   cableSearch STYLES
========================================================================== */
.cableSearch--padding-top {
  margin: 120px 0px 0px;
  margin: 7.5rem 0rem 0rem;
}

.cableSearch {
  background-color: #f36d21;
  color: #FFF;
  margin: 60px 0px 0px;
  margin: 3.75rem 0rem 0rem;
}

@media (min-width: 62.5em) {
  .cableSearch .tabs {
    padding: 0px 60px;
    padding: 0rem 3.75rem;
  }
}
.cableSearch__tab {
  padding-top: 24px;
  padding-top: 1.5rem;
  padding-bottom: 24px;
  padding-bottom: 1.5rem;
}
.cableSearch__icon {
  margin-bottom: 12px;
  margin-bottom: 0.75rem;
  float: left;
  margin-right: 2.8571428571%;
}
@media (min-width: 47.5em) {
  .cableSearch__icon {
    float: none;
    width: 22.8571428571%;
    display: inline-block;
    vertical-align: middle;
    margin-right: 2.8571428571%;
    height: 30vw;
  }
}
.cableSearch__icon svg {
  width: 37px;
  height: 50px;
}
@media (min-width: 47.5em) {
  .cableSearch__icon svg {
    height: 100%;
    width: 100%;
  }
}
.cableSearch__content {
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}
@media (min-width: 47.5em) {
  .cableSearch__content {
    width: 74.2857142857%;
    display: inline-block;
    vertical-align: middle;
  }
}
@media (min-width: 68.75em) {
  .cableSearch__content {
    width: 61.4285714286%;
  }
}
.cableSearch__title {
  color: currentColor;
  position: relative;
  padding-bottom: 12px;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 26px;
  font-size: 1.625rem;
  line-height: 1.8461538462;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}
@media (min-width: 37.5em) {
  .cableSearch__title {
    font-size: 36px;
    font-size: 2.25rem;
    line-height: 1.3333333333;
    margin-bottom: 24px;
    margin-bottom: 1.5rem;
  }
}
.cableSearch__titleIcon {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
}
.cableSearch__titleIcon .icon {
  width: 18px;
  height: 18px;
}
@media (min-width: 37.5em) {
  .cableSearch__titleIcon .icon {
    width: 26px;
    height: 26px;
  }
}
.cableSearch__map {
  height: 80vh;
}
.cableSearch p {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.625;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}

/* ==========================================================================
   cableSearch FORM STYLES
========================================================================== */
.cableSearch__form {
  font-size: 0;
  overflow: hidden;
}
.cableSearch__form input {
  width: 100%;
  display: inline-block;
  border: 0;
  height: 40px;
  height: 2.5rem;
  padding: 10px;
  padding: 0.625rem;
  margin: 0;
  color: #666;
  font-size: 16px;
  font-size: 1rem;
  vertical-align: bottom;
}
@media (min-width: 47.5em) {
  .cableSearch__form input {
    width: 70%;
  }
}
.cableSearch__form button {
  display: inline-block;
  color: #FFF;
  background-color: #000;
  border: 0;
  margin: 0;
  height: 60px;
  height: 3.75rem;
  width: 60px;
  width: 3.75rem;
  vertical-align: bottom;
  width: 100%;
}
@media (min-width: 47.5em) {
  .cableSearch__form button {
    width: auto;
  }
}
.cableSearch__form .icon {
  width: 24px;
  height: 24px;
}

/* ==========================================================================
	SEARCH FORM STLYES
========================================================================== */
.search-header {
  padding: 3%;
}
@media (max-width: 59.375em) {
  .search-header {
    text-align: center;
    height: 100%;
    width: 100%;
    display: table;
  }
}
@media (max-width: 59.375em) and (min-width: 37.5em) {
  .search-header {
    padding: 20%;
  }
}
.search-header .search__title {
  color: #FFF;
  margin-bottom: 12px;
  margin-bottom: 0.75rem;
}
@media (max-width: 37.5em) {
  .search-header .search__title {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
.search-header .label {
  font-size: 18px;
  font-size: 1.125rem;
  color: #b94a48;
  position: relative;
}
.js_search_is_open .search-header {
  animation-name: fadeInUp;
  animation-iteration-count: 1s;
  animation-duration: 1s;
  animation-delay: 0s;
  animation-timing-function: ease;
  animation-fill-mode: both;
  backface-visibility: hidden;
}
.search-header__content {
  color: #FFF;
}
@media (max-width: 59.375em) {
  .search-header__content {
    vertical-align: middle;
    display: table-cell;
  }
}
.search-header p {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.5;
  margin-bottom: 6px;
  margin-bottom: 0.375rem;
}
.search-header__close {
  margin-bottom: 12px;
  margin-bottom: 0.75rem;
}

/* ==========================================================================
	MAIN SEARCH STYLES
========================================================================== */
.search-results {
  clear: both;
  display: flex;
  flex-flow: row wrap;
}
@media (min-width: 37.5em) {
  .search-results {
    margin-left: -10px;
    margin-right: -10px;
  }
}
.search-results__item {
  position: relative;
  list-style: none;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  padding-bottom: 12px;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid white;
  width: 100%;
}
@media (min-width: 37.5em) {
  .search-results__item {
    border: 0;
    width: 50%;
    padding: 0 10px 12px;
  }
}
@media (min-width: 59.375em) {
  .search-results__item {
    width: 33%;
  }
}

.search-filters {
  width: 100%;
  float: left;
  margin-bottom: 30px;
}
@media (min-width: 59.375em) {
  .search-filters {
    width: 20%;
    margin-right: 5%;
  }
}
.search-filters__section {
  padding: 30px 0;
}
.search-filters__section h2 {
  color: black;
  margin: 0;
}
.search-filters__section h4 {
  color: black;
  font-size: 14px;
  font-weight: bold;
  position: relative;
  margin-bottom: 30px;
  cursor: pointer;
}
.search-filters__section h4:after {
  content: "v";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  color: #f36d21;
  transform: rotate(180deg);
  border: 2px solid currentColor;
  border-width: 2px 2px 0 0;
  width: 8px;
  height: 8px;
  content: "";
  display: inline-block;
  top: 4px;
  right: 4px;
  transform: rotate(135deg);
}
.search-filters__section input[type=text] {
  width: 100%;
  height: 40px;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #d9d9d9;
}
.search-filters__section input[type=text]::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  opacity: 0.6; /* Firefox */
}
.search-filters__section input[type=text]:-ms-input-placeholder { /* Internet Explorer 10-11 */
  opacity: 0.6;
}
.search-filters__section input[type=text]::-ms-input-placeholder { /* Microsoft Edge */
  opacity: 0.6;
}
.search-filters__section form {
  max-height: 300px;
  overflow: auto;
}
.search-filters__section form label {
  display: inline-block;
  width: 100%;
  margin-bottom: 15px;
  font-size: 14px;
  position: relative;
  padding-left: 20px;
}
.search-filters__section form label.disabled {
  opacity: 0.6;
}
.search-filters__section form label input {
  position: absolute;
  left: 0;
}
.search-filters__section form label.one-of-three {
  min-height: unset;
}
.search-filters__section form .instafilta-match {
  color: #f36d21;
  font-weight: bold;
}
.search-filters__section.closed {
  min-height: 0;
}
.search-filters__section.closed h4:after {
  transform: rotate(-45deg);
}
.search-filters__section.closed h4 ~ * {
  display: none !important;
}
.search-filters__section .filters-cta {
  color: #f36d21;
}
.search-filters__section.open-close h2 {
  position: relative;
  cursor: pointer;
}
.search-filters__section.open-close h2:after {
  content: "v";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  color: #f36d21;
  transform: rotate(180deg);
  border: 2px solid currentColor;
  border-width: 2px 2px 0 0;
  width: 8px;
  height: 8px;
  content: "";
  display: inline-block;
  top: 4px;
  right: 4px;
  transform: rotate(135deg);
}
.search-filters__section.open-close.closed {
  max-height: unset;
}
.search-filters__section.open-close.closed h2:after {
  transform: rotate(-45deg);
}
@media (min-width: 59.375em) {
  .search-filters__section.hideondesktop {
    display: none;
  }
}
.search-filters__section.search-filters__section--item {
  display: none;
}
@media (min-width: 59.375em) {
  .search-filters__section.search-filters__section--item {
    display: block;
  }
}
.search-filters .clearFilters {
  width: 100%;
  background-color: #d9d9d9;
  color: #797979;
  display: inline-block;
  text-align: center;
  padding: 10px;
  font-weight: bold;
  border: 0;
  outline: none;
}
.search-filters .appliedFilters {
  padding-bottom: 10px;
}
.search-filters .appliedFilters .filter-item {
  position: relative;
  cursor: pointer;
}
.search-filters .appliedFilters .filter-item:after {
  content: "×";
  position: absolute;
  right: 0;
  top: 0;
}
#products .search-filters {
  width: 100%;
}
@media (min-width: 59.375em) {
  #products .search-filters {
    border-bottom: 1px solid #d9d9d9;
  }
}
#products .search-filters .hideondesktop {
  display: block;
}
@media (min-width: 59.375em) {
  #products .search-filters .search-filters__section--item {
    border: 0 none;
  }
}
@media (min-width: 59.375em) {
  #products .search-filters .applied-filters__client {
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
  }
  #products .search-filters .applied-filters__client .appliedFilters {
    display: inline-block;
    width: calc(100% - 150px);
    clear: both;
  }
  #products .search-filters .applied-filters__client .appliedFilters .filter-item {
    display: inline-block;
    float: left;
    padding: 10px 20px 10px 0;
    margin-right: 20px;
  }
  #products .search-filters .applied-filters__client .appliedFilters .filter-item:after {
    top: 10px;
  }
  #products .search-filters .applied-filters__client .clearFilters {
    float: right;
    padding: 10px 20px;
    width: 150px;
  }
}

.search-container {
  clear: both;
  width: 100%;
  margin-top: 83px;
}
@media (min-width: 59.375em) {
  .search-container {
    width: 75%;
    float: left;
    clear: unset;
  }
}
.search-container .entryTeaserCertificate__logo {
  width: 100%;
}

.keyword {
  color: #000;
  font-style: italic;
}

.search-header__form--company h3.search__title {
  font-size: 2rem;
  font-weight: bold;
  margin: 2rem 0 1rem;
}
.search-header__form--company input[type=search], .search-header__form--company select {
  display: inline-block;
  vertical-align: top;
  height: 40px;
  height: 2.5rem;
  padding: 10px;
  padding: 0.625rem;
  font-size: 16px;
  font-size: 1rem;
  float: left;
}
.search-header__form--company input[type=search] {
  display: none;
}
.search-header__form--company select {
  width: 100%;
  height: 62px;
}
.search-header__form--company label {
  display: block;
  margin-bottom: 0;
  font-weight: normal;
  font-size: 18px;
  text-align: left;
}
@media (min-width: 37.5em) {
  .search-header__form--company label {
    text-align: center;
  }
}
.search-header__form--company .form__select {
  width: 100%;
  position: relative;
}
@media (min-width: 37.5em) {
  .search-header__form--company .form__select {
    width: 30%;
    float: left;
    margin-right: 30px;
  }
}
.search-header__form--company .form__select:after {
  content: "";
  position: absolute;
  bottom: -62px;
  right: 0;
  height: 62px;
  width: 62px;
  background-color: #f36d21;
  color: white;
  text-align: center;
  font-size: 20px;
  padding: 20px 15px;
  cursor: pointer;
  pointer-events: none;
  transition: all 0.4s ease;
}
@media (min-width: 37.5em) {
  .search-header__form--company .form__select:after {
    bottom: 0;
  }
}
.search-header__form--company .form__select label:after {
  position: absolute;
  border: 2px solid currentColor;
  border-width: 2px 2px 0 0;
  width: 15px;
  height: 15px;
  content: "";
  display: inline-block;
  top: 53px;
  right: 24px;
  transform: rotate(135deg);
  cursor: pointer;
  pointer-events: none;
  z-index: 99;
}
.search-header__form--company .form__select:hover:after {
  background-color: #0e9ee4;
}
.search-header__form--company .form__search {
  width: 100%;
  float: left;
  overflow: hidden;
}
.search-header__form--company .form__search label {
  margin-top: 0.7rem;
}
.search-header__form--company .form__search input[type=search] {
  width: 100%;
  margin-bottom: 0.7rem;
}
.search-header__form--company .form__search button {
  width: 100%;
  font-size: 18px;
  margin-bottom: 2rem;
}
@media (min-width: 37.5em) {
  .search-header__form--company .form__search {
    width: 60%;
    float: left;
  }
  .search-header__form--company .form__search label {
    margin-top: 0;
  }
  .search-header__form--company .form__search input[type=search] {
    width: calc(100% - 140px);
    margin-bottom: 1.5rem;
  }
  .search-header__form--company .form__search button {
    width: unset;
    margin-bottom: unset;
  }
}
form.page-links {
  border-top: 1px solid #d9d9d9;
  padding: 30px 0 0;
}
form.page-links label {
  display: inline-block;
  width: 32px;
  height: 28px;
  font-size: 16px;
  color: #f36d21;
  cursor: pointer;
}
form.page-links label.active {
  color: black;
}
form.page-links label.disabled {
  color: #e3e3e3;
  cursor: inherit;
}
form.page-links input[type=checkbox] {
  position: absolute;
  visibility: hidden;
}

button.back-to-filters {
  background: #f36d21;
  border: 0 none;
  padding: 15px 30px;
  color: white;
}
button.back-to-filters.pagination__link--prev:before {
  margin-right: 5px;
}

.pageTitle.underline {
  padding-bottom: 30px;
}

/* ==========================================================================
	PANEL STYLES
========================================================================== */
.panel__title {
  font-family: "Roboto", sans-serif;
  color: #000;
  font-weight: 500;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.2;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 12px;
  padding-bottom: 0.75rem;
}
.panel__title--small {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.2;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}
.article--sidebar .panel:not(:last-of-type) {
  margin-bottom: 60px;
  margin-bottom: 3.75rem;
}
.panel td, .panel th {
  padding: 5px 5px;
  border: 1px solid #d9d9d9;
  vertical-align: top;
}
.panel td p, .panel th p {
  margin-top: 0px;
  margin-bottom: 0px;
}

.panel--notes p {
  font-family: "Roboto", sans-serif;
  color: #f36d21;
  font-size: 17px;
  font-size: 1.0625rem;
  line-height: 1.5;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}

.panel--statement p {
  font-family: "Roboto", sans-serif;
  color: #f36d21;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.5;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}

.panel--text p {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.5;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}

/* ==========================================================================
	PANEL STYLES
========================================================================== */
.relatedContent {
  background-color: #d9d9d9;
  padding-top: 60px;
  padding-top: 3.75rem;
  padding-bottom: 60px;
  padding-bottom: 3.75rem;
}
.relatedContent__title {
  color: #000;
  font-family: "Roboto", sans-serif;
  margin-bottom: 36px;
  margin-bottom: 2.25rem;
}

.relatedContent--light {
  background-color: #efefef;
}

.relatedContent--white {
  background-color: #FFF;
}

/* ==========================================================================
	CARD STYLES
========================================================================== */
.card {
  position: relative;
  overflow: hidden;
  margin: 0px 12px 12px;
  margin: 0rem 0.75rem 0.75rem;
}
.card__title {
  position: relative;
  bottom: 0;
  width: 100%;
  background-color: #f36d21;
  color: #FFF;
  text-align: right;
  padding: 16px;
  padding: 1rem;
  border-top: 1px solid #FFF;
  margin-bottom: 0;
  font-size: 21px;
  font-size: 1.3125rem;
}
.card__title:after {
  border: 2px solid currentColor;
  border-width: 2px 2px 0 0;
  transform: rotate(45deg);
  width: 10px;
  height: 10px;
  content: "";
  position: relative;
  display: inline-block;
  top: -1px;
  top: -0.0625rem;
  margin-left: 4px;
  margin-left: 0.25rem;
}
.card__overlay {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  background-color: #f36d21;
  color: #FFF;
  padding: 24px;
  padding: 1.5rem;
  z-index: 100;
}
.card__overlayTitle {
  color: #FFF;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  padding-bottom: 12px;
  padding-bottom: 0.75rem;
  width: 80%;
  font-size: 24px;
  font-size: 1.5rem;
}
.card__overTitle, .card p {
  animation-name: fadeIn;
  animation-iteration-count: 1s;
  animation-duration: 1s;
  animation-delay: 0s;
  animation-timing-function: ease;
  animation-fill-mode: both;
  backface-visibility: hidden;
}
.card__btn {
  color: #000;
  position: relative;
}
.card__btn:after {
  border: 2px solid currentColor;
  border-width: 2px 2px 0 0;
  transform: rotate(45deg);
  width: 8px;
  height: 8px;
  content: "";
  position: relative;
  display: inline-block;
  top: -1px;
  top: -0.0625rem;
  margin-left: 4px;
  margin-left: 0.25rem;
}

.card:hover .card__overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation-name: flipInX;
  animation-iteration-count: 1s;
  animation-duration: 0.5s;
  animation-delay: 0s;
  animation-timing-function: ease;
  animation-fill-mode: both;
  backface-visibility: hidden;
}

/* ==========================================================================
	CARD LAYOUT STYLES
========================================================================== */
.l-card--third {
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}
@media (min-width: 59.375em) {
  .l-card--third {
    width: 31.4285714286%;
    float: left;
  }
  .l-card--third:nth-of-type(3n + 1) {
    margin-left: 0;
    margin-right: -100%;
    clear: both;
    margin-left: 0;
  }
  .l-card--third:nth-of-type(3n + 2) {
    margin-left: 34.2857142857%;
    margin-right: -100%;
    clear: none;
  }
  .l-card--third:nth-of-type(3n + 3) {
    margin-left: 68.5714285714%;
    margin-right: -100%;
    clear: none;
  }
}

/* ==========================================================================
	CARD POPUP MODAL STYLES
========================================================================== */
.cardPopUp__modal {
  padding: 5%;
  position: relative;
  height: 100%;
}
@media (min-width: 59.375em) {
  .cardPopUp__modal {
    padding: 3%;
    max-width: 1600px;
    max-width: 100rem;
    margin: auto;
  }
}
.cardPopUp__img {
  width: 100%;
  position: relative;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}
@media (min-width: 59.375em) {
  .cardPopUp__img {
    width: 95.7142857143%;
    float: right;
    margin-right: 0;
  }
}
.cardPopUp__content {
  animation-name: slideInLeft;
  animation-iteration-count: 1s;
  animation-duration: 1s;
  animation-delay: 0.2s;
  animation-timing-function: ease;
  animation-fill-mode: both;
  backface-visibility: hidden;
}
@media (min-width: 59.375em) {
  .cardPopUp__content {
    width: 65.7142857143%;
    padding: 24px;
    padding: 1.5rem;
    background-color: #FFF;
    position: absolute;
    top: -24px;
    top: -1.5rem;
    z-index: 300;
  }
}
.cardPopUp__content p {
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 1.6;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}

/* ==========================================================================
	CABLE STYLES
========================================================================== */
.cableGroup {
  margin-bottom: 60px;
  margin-bottom: 3.75rem;
  padding-top: 24px;
  padding-top: 1.5rem;
}
@media (min-width: 59.375em) {
  .cableGroup__description {
    width: 57.1428571429%;
    margin-left: 1.4285714286%;
  }
}

.cable {
  list-style: none;
  position: relative;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  width: 47.9166666667%;
  float: left;
}
.cable:nth-child(2n+1) {
  margin-left: 0;
  margin-right: -100%;
  clear: both;
  margin-left: 0;
}
.cable:nth-child(2n+2) {
  margin-left: 52.0833333333%;
  margin-right: -100%;
  clear: none;
}
@media (min-width: 37.5em) {
  .cable {
    width: 30.5555555556%;
    float: left;
  }
  .cable:nth-child(3n+1) {
    margin-left: 0;
    margin-right: -100%;
    clear: both;
    margin-left: 0;
  }
  .cable:nth-child(3n+2) {
    margin-left: 34.7222222222%;
    margin-right: -100%;
    clear: none;
  }
  .cable:nth-child(3n+3) {
    margin-left: 69.4444444444%;
    margin-right: -100%;
    clear: none;
  }
}
@media (min-width: 59.375em) {
  .cable {
    width: 16.6666666667%;
    float: left;
  }
  .cable:nth-child(5n+1) {
    margin-left: 0;
    margin-right: -100%;
    clear: both;
    margin-left: 0;
  }
  .cable:nth-child(5n+2) {
    margin-left: 20.8333333333%;
    margin-right: -100%;
    clear: none;
  }
  .cable:nth-child(5n+3) {
    margin-left: 41.6666666667%;
    margin-right: -100%;
    clear: none;
  }
  .cable:nth-child(5n+4) {
    margin-left: 62.5%;
    margin-right: -100%;
    clear: none;
  }
  .cable:nth-child(5n+5) {
    margin-left: 83.3333333333%;
    margin-right: -100%;
    clear: none;
  }
}
.cable__tag {
  background-color: #000;
  color: #FFF;
  padding: 6px 12px;
  padding: 0.375rem 0.75rem;
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 1.6;
  margin-bottom: 0;
  display: inline-block;
  width: auto;
  position: relative;
  left: -6px;
  left: -0.375rem;
  top: -12px;
  top: -0.75rem;
  border-left: 6px solid #f36d21;
  z-index: 50;
}
@media (min-width: 37.5em) {
  .cable__tag {
    left: -12px;
    left: -0.75rem;
  }
}
.cable__title {
  background-color: #000;
  color: #FFF;
  padding: 6px 12px;
  padding: 0.375rem 0.75rem;
  font-size: 4vh;
  display: block;
  width: auto;
  border-left: 6px solid #f36d21;
}

/* ==========================================================================
	CABLE ROLLOVER STYLES
========================================================================== */
.cable a:hover:before, .cable a:focus:before, .cable a:active:before {
  content: attr(data-title) "[ View Details ]";
  background-color: #f36d21;
  color: #FFF;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  padding: 12px;
  padding: 0.75rem;
  vertical-align: middle;
  position: absolute;
  z-index: 20;
  width: 100%;
  height: 100%;
  white-space: pre;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation-name: flipInX;
  animation-iteration-count: 1s;
  animation-duration: 0.5s;
  animation-delay: 0s;
  animation-timing-function: ease;
  animation-fill-mode: both;
  backface-visibility: hidden;
}

/* ==========================================================================
	CABLE MODAL STYLES
========================================================================== */
.cable__modal {
  padding: 10%;
  position: relative;
  height: 100%;
}
.cable__img {
  position: absolute;
  bottom: 0;
  display: block;
  width: 100%;
  height: 100%;
  text-align: right;
  transform-style: preserve-3d;
}
.cable__img img {
  width: 90%;
  position: relative;
  top: 35%;
  transform: translateY(-35%);
}
.cable__content {
  position: relative;
  top: 0;
  z-index: 300;
  animation-name: slideInLeft;
  animation-iteration-count: 1s;
  animation-duration: 1s;
  animation-delay: 0.2s;
  animation-timing-function: ease;
  animation-fill-mode: both;
  backface-visibility: hidden;
}
@media (min-width: 37.5em) {
  .cable__content {
    width: 48.5714285714%;
  }
}
.cable__content p {
  font-size: 17px;
  font-size: 1.0625rem;
  line-height: 1.5;
  margin-bottom: 0;
  margin-left: 20px;
  margin-left: 1.25rem;
}

/* ==========================================================================
	MODEL STYLES
========================================================================== */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 900;
  opacity: 0;
  transition: all 0.4s ease;
  pointer-events: none;
  width: 100%;
  height: 100%;
  display: none;
  background-color: #e8e8e8;
}
.modal:target {
  opacity: 1;
  pointer-events: auto;
}
.modal__close {
  margin: 24px 24px 0px;
  margin: 1.5rem 1.5rem 0rem;
  text-align: right;
  position: relative;
  z-index: 999;
}
.modal__closeBtn {
  color: #b94a48;
  padding: 6px;
  padding: 0.375rem;
  font-size: 14px;
  font-size: 0.875rem;
}
.modal__closeBtn:hover, .modal__closeBtn:focus, .modal__closeBtn:active {
  background: #b94a48;
  color: #FFF;
}
.modal__closeBtn:hover .icon-close:before, .modal__closeBtn:hover .icon-close:after, .modal__closeBtn:focus .icon-close:before, .modal__closeBtn:focus .icon-close:after, .modal__closeBtn:active .icon-close:before, .modal__closeBtn:active .icon-close:after {
  transition: all 0.4s ease;
  background: #FFF;
}
.modal__closeBtn .icon-close {
  width: 14px;
  height: 14px;
  margin-right: 3px;
  margin-right: 0.1875rem;
}

.modal:target {
  display: block;
}
.modal:target .modal__content {
  animation-name: fadeInUp;
  animation-iteration-count: 1s;
  animation-duration: 1s;
  animation-delay: 0s;
  animation-timing-function: ease;
  animation-fill-mode: both;
  backface-visibility: hidden;
}

/* ==========================================================================
	 VIDEO MODAL
	 A modal that more typical of other websites.
========================================================================== */
.modal--video {
  background-color: #FFF;
  height: auto;
  left: 0;
  max-height: 100vh;
  overflow: auto;
  top: 0;
  width: 100%;
}
@media (min-width: 37.5em) {
  .modal--video {
    width: 600px;
    width: 37.5rem;
    left: 50%;
    overflow: visible;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
@media (min-width: 47.5em) {
  .modal--video {
    width: 720px;
    width: 45rem;
  }
}
.modal--video:target .modal__content {
  animation: none;
}

.modal__content--video {
  padding: 24px;
  padding: 1.5rem;
}
.modal__content--video > :last-child {
  margin-bottom: 0;
}

.modal__video {
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  width: 100%;
}

.modal__background {
  background-color: rgba(0, 0, 0, 0.6);
  left: 0;
  height: 100%;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 899;
}

.modal__videoClose {
  background-color: #FFF;
  border-radius: 50%;
  color: #666;
  line-height: 0;
  padding: 0.3em;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.4s ease;
  z-index: 1;
}
@media (min-width: 37.5em) {
  .modal__videoClose {
    left: 100%;
    right: auto;
    transform: translate(-70%, -30%);
  }
}
.modal__videoClose:hover, .modal__videoClose:focus {
  background-color: #b94a48;
  color: #FFF;
}
.modal__videoClose > .icon-close {
  color: inherit;
  height: 14px;
  transition: none;
  width: 14px;
  top: 0;
}
.modal__videoClose > .icon-close::before, .modal__videoClose > .icon-close::after {
  background-color: currentColor;
  transition: none;
}

.modal__closeInfo {
  display: block;
  font-size: 0.9375rem;
  text-align: right;
}

.modal__videoTitle {
  color: #f36d21;
  font-size: 1.5rem;
}

/* ==========================================================================
	TESTING LAYOUT STYLES
========================================================================== */
.testing {
  margin-bottom: 60px;
  margin-bottom: 3.75rem;
}
@media (min-width: 47.5em) {
  .testing__description {
    width: 40%;
    float: left;
    margin-right: 2.8571428571%;
  }
}
.testing__cables {
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  clear: both;
}
@media (min-width: 47.5em) {
  .testing__cables {
    width: 27.1428571429%;
    float: right;
    margin-right: 0;
    margin-bottom: 0;
    clear: none;
  }
}
.testing__cableImg {
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  margin-top: -36px;
  margin-top: -2.25rem;
  margin-left: 24px;
  margin-left: 1.5rem;
  float: right;
}
@media (min-width: 47.5em) {
  .testing__equipment {
    width: 40%;
    float: left;
    margin-right: 2.8571428571%;
    padding-top: 24px;
    padding-top: 1.5rem;
  }
}
.testing__img {
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}
@media (min-width: 37.5em) {
  .testing__img {
    width: 31.4285714286%;
    float: right;
    margin-right: 0;
    margin-left: 24px;
    margin-left: 1.5rem;
  }
}
@media (min-width: 47.5em) {
  .testing__img {
    width: 22.8571428571%;
    float: left;
    margin-right: 2.8571428571%;
    margin-bottom: 0;
    margin-left: 0;
  }
}

/* ==========================================================================
	TESTING STYLING STYLES
========================================================================== */
.testing__subTitle {
  margin-bottom: 12px;
  margin-bottom: 0.75rem;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 500;
}

/* ==========================================================================
	LOGO LSITINGS LAYOUT STYLES
========================================================================== */
.logoListing {
  list-style: none;
  margin-bottom: 48px;
  margin-bottom: 3rem;
}
.logoListing:not(:last-of-type) {
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 48px;
  padding-bottom: 3rem;
}
.logoListing__img {
  border: 1px solid #d9d9d9;
  padding: 24px;
  padding: 1.5rem;
  height: 224px;
  transform-style: preserve-3d;
  vertical-align: middle;
  text-align: center;
}
@media (min-width: 37.5em) {
  .logoListing__img {
    width: 65.7142857143%;
  }
}
@media (min-width: 47.5em) {
  .logoListing__img {
    width: 31.4285714286%;
    float: left;
    margin-right: 2.8571428571%;
  }
}
.logoListing__img img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.logoListing__content {
  padding-top: 24px;
  padding-top: 1.5rem;
}
@media (min-width: 47.5em) {
  .logoListing__content {
    width: 65.7142857143%;
    float: right;
    margin-right: 0;
  }
}

/* ==========================================================================
	LOGO LISTING STYLING STYLES
========================================================================== */
.logoListing__btn {
  transition: all 0.4s ease;
  color: #000;
  position: relative;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-size: 0.8125rem;
  margin-left: 24px;
  margin-left: 1.5rem;
}
.logoListing__btn:before {
  content: "";
  background-color: #d9d9d9;
  width: 2px;
  bottom: 0;
  top: 15%;
  height: 80%;
  position: absolute;
  left: -14px;
  left: -0.875rem;
}
.logoListing__btn:after {
  border: 2px solid currentColor;
  border-width: 2px 2px 0 0;
  transform: rotate(45deg);
  width: 8px;
  height: 8px;
  content: "";
  position: relative;
  display: inline-block;
  top: -1px;
  top: -0.0625rem;
  margin-left: 4px;
  margin-left: 0.25rem;
}

/* ==========================================================================
	BG IMAGE STYLES
========================================================================== */
.bg {
  background-repeat: no-repeat;
}

@media (min-width: 59.375em) {
  .bg--logoListings {
    background-image: url("/assets/img/bg-cable-logolisting.jpg");
    background-position: top right;
    background-size: 70%;
    width: 450px;
    height: 390px;
    position: absolute;
    top: 0;
    right: 0;
  }
}
@media (min-width: 59.375em) and (min-width: 68.75em) {
  .bg--logoListings {
    background-size: 90%;
  }
}
@media (min-width: 59.375em) and (min-width: 93.75em) {
  .bg--logoListings {
    background-size: 100%;
  }
}

/* ==========================================================================
	TEAM LAYOUT STYLES
========================================================================== */
.team {
  padding-top: 60px;
}
.team__item {
  list-style: none;
  margin-bottom: 60px;
  margin-bottom: 3.75rem;
}
@media (min-width: 59.375em) {
  .team__item {
    width: 48.5714285714%;
    float: left;
  }
  .team__item:nth-child(2n+1) {
    margin-left: 0;
    margin-right: -100%;
    clear: both;
    margin-left: 0;
  }
  .team__item:nth-child(2n+2) {
    margin-left: 51.4285714286%;
    margin-right: -100%;
    clear: none;
  }
}
.team__img {
  width: 31.4285714286%;
  float: left;
  margin-right: 2.8571428571%;
  max-width: 200px;
}
.team__img--no-imge {
  height: 130px;
  max-height: 200px;
  display: block;
}
.team__content {
  width: 65.7142857143%;
  float: left;
  margin-right: 2.8571428571%;
  margin-right: 0;
  padding-top: 12px;
  padding-top: 0.75rem;
}
@media (min-width: 59.375em) {
  .team__content {
    padding-top: 18px;
    padding-top: 1.125rem;
  }
}

/* ==========================================================================
	TEAM  STYLES
========================================================================== */
.team__name {
  font-size: 19px;
  font-size: 1.1875rem;
  line-height: 1;
  margin-bottom: 6px;
  margin-bottom: 0.375rem;
}
.team__jobTitle {
  font-style: italic;
  font-size: 14px;
  font-size: 0.875rem;
  margin-bottom: 6px;
  margin-bottom: 0.375rem;
  padding-bottom: 6px;
  padding-bottom: 0.375rem;
  border-bottom: 1px solid #d9d9d9;
}
.team__contact {
  font-size: 12px;
  font-size: 0.75rem;
}
@media (min-width: 37.5em) {
  .team__contact {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
.team__bio {
  font-size: 14px;
  font-size: 0.875rem;
  margin-bottom: 6px;
  margin-bottom: 0.375rem;
  padding-bottom: 6px;
  padding-bottom: 0.375rem;
}

/* ==========================================================================
	TEAM RELATED LAYOUT STYLES
========================================================================== */
.teamRelated__item {
  display: flex;
  align-items: flex-start;
}
.teamRelated__img {
  margin-right: 1rem;
}
.teamRelated__img img {
  max-width: 125px;
}
.teamRelated__content {
  flex: 1;
}
.teamRelated__content p {
  margin-bottom: 0;
}

/* ==========================================================================
	TEAM RELATED STYLES
========================================================================== */
.teamRelated__name, .teamRelated__job-title {
  color: #000;
  margin-bottom: 0.5rem;
}
.teamRelated__name {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.2;
}
.teamRelated__job-title {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.5;
}
.teamRelated__contact {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.5;
}

/* ==========================================================================
	EXPANDING BLOCK STYLES
========================================================================== */
summary {
  outline: none;
}

.expandingBlock {
  margin-bottom: 36px;
  margin-bottom: 2.25rem;
}
.expandingBlock:first-of-type() {
  margin-top: 48px;
  margin-top: 3rem;
}
.expandingBlock:last-of-type() {
  margin-bottom: 48px;
  margin-bottom: 3rem;
}
.expandingBlock__title {
  font-weight: 400;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.2;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #d9d9d9;
  padding: 0px 12px 12px;
  padding: 0rem 0.75rem 0.75rem;
  cursor: pointer;
}
.expandingBlock__content {
  animation-name: fadeInUp;
  animation-iteration-count: 1s;
  animation-duration: 1s;
  animation-delay: 0s;
  animation-timing-function: ease;
  animation-fill-mode: none;
  backface-visibility: hidden;
  padding: 0px 12px 12px;
  padding: 0rem 0.75rem 0.75rem;
}

.expandingBlockCables__title {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.5;
  margin-bottom: 12px;
  margin-bottom: 0.75rem;
  color: #f36d21;
  font-weight: 400;
  cursor: pointer;
}
.expandingBlockCables__content {
  animation-name: fadeInUp;
  animation-iteration-count: 1s;
  animation-duration: 1s;
  animation-delay: 0s;
  animation-timing-function: ease;
  animation-fill-mode: both;
  backface-visibility: hidden;
}

/* ==========================================================================
	DOWNLOAD STYLES
========================================================================== */
.downloads li {
  list-style: none;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.7142857143;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}
.downloads .icon {
  width: 16px;
  height: 18px;
  color: silver;
  margin-right: 3px;
  margin-right: 0.1875rem;
}

.pdf {
  color: #d10d17;
}

.filesize {
  font-size: 15px;
  font-size: 0.9375rem;
  color: #a0a0a0;
  margin-left: 6px;
  margin-left: 0.375rem;
}

/* ==========================================================================
   certificateNotice STYLES
========================================================================== */
.certificateNotice__item {
  list-style: none;
}
.certificateNotice__item:not(:last-of-type) {
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  padding-bottom: 24px;
  padding-bottom: 1.5rem;
  border-bottom: 1px dashed #d9d9d9;
}
.certificateNotice__title {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.3333333333;
  margin-bottom: 0;
}
.certificateNotice__content p {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.5;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}
.certificateNotice__content p:last-of-type {
  margin-bottom: 12px;
  margin-bottom: 0.75rem;
}

.certificateHistory {
  margin-left: 12px;
  margin-left: 0.75rem;
}
.certificateHistory__title {
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 1.6;
  margin-bottom: 0;
}
.certificateHistory__item {
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 1.6153846154;
  margin-bottom: 0;
  list-style: none;
  font-style: italic;
}

/* ==========================================================================
News STYLES
========================================================================== */
/* ==========================================================================
 	DATE STYLES
========================================================================== */
.date {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.7142857143;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}

/* ==========================================================================
News Teaser STYLES
========================================================================== */
.newsTeaser {
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  padding-bottom: 24px;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #d9d9d9;
}
.newsTeaser__img {
  width: 31.4285714286%;
  float: right;
  margin-right: 0;
  margin-left: 24px;
  margin-left: 1.5rem;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}
@media (min-width: 37.5em) {
  .newsTeaser__img {
    width: 35.7142857143%;
    float: left;
    margin-right: 2.8571428571%;
    margin-left: 0;
    margin-bottom: 0;
  }
}
@media (min-width: 47.5em) {
  .newsTeaser__img {
    width: 22.8571428571%;
    float: left;
    margin-right: 2.8571428571%;
  }
}
.newsTeaser__content > :last-child {
  margin-bottom: 0;
}
@media (min-width: 37.5em) {
  .newsTeaser__content {
    width: 59.2857142857%;
    float: right;
    margin-right: 0;
  }
}
@media (min-width: 47.5em) {
  .newsTeaser__content {
    width: 72.1428571429%;
    float: right;
    margin-right: 0;
  }
}

.newsTeaser__title {
  font-size: 19px;
  font-size: 1.1875rem;
  line-height: 1.3;
  margin-bottom: 6px;
  margin-bottom: 0.375rem;
}
.newsTeaser p {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.5;
  margin-bottom: 6px;
  margin-bottom: 0.375rem;
}
.newsTeaser__link {
  position: relative;
}
.newsTeaser__link:after {
  border: 2px solid currentColor;
  border-width: 2px 2px 0 0;
  transform: rotate(45deg);
  width: 8px;
  height: 8px;
  content: "";
  position: relative;
  display: inline-block;
  top: -1px;
  top: -0.0625rem;
  margin-left: 4px;
  margin-left: 0.25rem;
}

/* ==========================================================================
	PAGINATION STYLES
========================================================================== */
.pagination {
  position: relative;
  clear: both;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}
.tab .pagination {
  margin-bottom: 0;
}

.pagination--bordered {
  padding-bottom: 24px;
  padding-bottom: 1.5rem;
  border-bottom: 2px solid #f36d21;
}

.pagination__page {
  margin-right: 12px;
  margin-right: 0.75rem;
  margin-left: 12px;
  margin-left: 0.75rem;
  font-size: 14px;
  font-size: 0.875rem;
}

.pagination__link {
  font-size: 14px;
  font-size: 0.875rem;
  position: relative;
}
.pagination__link--prev:before {
  border: 2px solid currentColor;
  border-width: 2px 2px 0 0;
  transform: rotate(225deg);
  width: 8px;
  height: 8px;
  content: "";
  position: relative;
  display: inline-block;
  top: -1px;
  top: -0.0625rem;
}
.pagination__link--next:after {
  border: 2px solid currentColor;
  border-width: 2px 2px 0 0;
  transform: rotate(45deg);
  width: 8px;
  height: 8px;
  content: "";
  position: relative;
  display: inline-block;
  top: -1px;
  top: -0.0625rem;
}

.page-links {
  list-style: none;
  font-size: 0;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}
.page-links li {
  transition: all 0.4s ease;
  border: 1px solid;
  border-color: #d9d9d9;
  text-align: center;
  display: inline-block;
  font-size: 13px;
  font-size: 0.8125rem;
  margin: 6px 3px;
  margin: 0.375rem 0.1875rem;
  width: 26px;
  width: 1.625rem;
  height: 26px;
  height: 1.625rem;
  line-height: 26px;
  line-height: 1.625rem;
  color: #666;
}
.page-links li a {
  display: block;
  color: currentColor;
}
.cableSearch__tab .page-links li a {
  color: white;
}
.page-links li a:hover, .page-links li a:focus, .page-links li a:active {
  background-color: #0e9ee4;
  color: #FFF;
}
.page-links li.disabled {
  color: #b6b6b6;
  background-color: #e3e3e3;
}
.tab .page-links li.disabled {
  border-color: #d5550c;
  color: #a54109;
  background-color: #d5550c;
}
.page-links .current {
  color: #FFF;
  background-color: #f36d21;
  border-color: #f36d21;
}

/* ==========================================================================
	COURSE DETAIL STYLES
========================================================================== */
.courseDetails {
  margin-top: 24px;
  margin-top: 1.5rem;
}
.courseDetails__item {
  position: relative;
  list-style: none;
  background-color: #f36d21;
  color: #FFF;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  margin-top: 60px;
  margin-top: 3.75rem;
  padding: 18px 12px;
  padding: 1.125rem 0.75rem;
}
@media (min-width: 37.5em) {
  .courseDetails__item {
    width: 31.4285714286%;
    float: left;
  }
  .courseDetails__item:nth-child(3n+1) {
    margin-left: 0;
    margin-right: -100%;
    clear: both;
    margin-left: 0;
  }
  .courseDetails__item:nth-child(3n+2) {
    margin-left: 34.2857142857%;
    margin-right: -100%;
    clear: none;
  }
  .courseDetails__item:nth-child(3n+3) {
    margin-left: 68.5714285714%;
    margin-right: -100%;
    clear: none;
  }
}
.courseDetails__label {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 17px;
  font-size: 1.0625rem;
  line-height: 1.4117647059;
  margin-bottom: 12px;
  margin-bottom: 0.75rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  padding-bottom: 6px;
  padding-bottom: 0.375rem;
}
.courseDetails__text {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.5;
  margin-bottom: 0;
}
.courseDetails .icon {
  color: #f36d21;
  top: -34px;
  top: -2.125rem;
  position: absolute;
}

/* ==========================================================================
	EVENTS LAYOUT STYLES
========================================================================== */
.event {
  list-style: none;
  margin-bottom: 48px;
  margin-bottom: 3rem;
}
.event:not(:last-of-type) {
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 48px;
  padding-bottom: 3rem;
}
.event__img {
  border: 1px solid #d9d9d9;
  padding: 24px;
  padding: 1.5rem;
  height: 224px;
  transform-style: preserve-3d;
  vertical-align: middle;
  text-align: center;
}
@media (min-width: 37.5em) {
  .event__img {
    width: 65.7142857143%;
  }
}
@media (min-width: 47.5em) {
  .event__img {
    width: 31.4285714286%;
    float: left;
    margin-right: 2.8571428571%;
  }
}
.event__img img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.event__content {
  padding-top: 12px;
  padding-top: 0.75rem;
}
@media (min-width: 47.5em) {
  .event__content {
    width: 65.7142857143%;
    float: right;
    margin-right: 0;
  }
}
.event__content p {
  font-size: 14px;
  font-size: 0.875rem;
}

/* ==========================================================================
	LOGO LISTING STYLING STYLES
========================================================================== */
.event .date {
  margin-bottom: 12px;
  margin-bottom: 0.75rem;
}
.event__btn {
  transition: all 0.4s ease;
  position: relative;
  font-size: 14px;
  font-size: 0.875rem;
}
.event__btn:after {
  border: 2px solid currentColor;
  border-width: 2px 2px 0 0;
  transform: rotate(45deg);
  width: 8px;
  height: 8px;
  content: "";
  position: relative;
  display: inline-block;
}
.event__description:last-child() {
  margin-bottom: 0 !important;
  border: 1px solid red;
}

/* ==========================================================================
	ENTRY TEASER STYLES
========================================================================== */
.entryTeaser {
  margin-bottom: 40px;
  margin-bottom: 2.5rem;
}
.entryTeaser__img, .entryTeaser__content {
  display: inline-block;
  vertical-align: middle;
}
.entryTeaser__img {
  width: 130px;
  margin-right: 6px;
  margin-right: 0.375rem;
  overflow: hidden;
}
.entryTeaser__content {
  width: calc(100% - 142px);
}
.entryTeaser__heading {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.2;
  margin-bottom: 6px;
  margin-bottom: 0.375rem;
  color: #a0a0a0;
}
.entryTeaser__subHeading {
  font-family: "Roboto", sans-serif;
  color: #000;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.3;
  margin-bottom: 12px;
  margin-bottom: 0.75rem;
  font-style: italic;
}
.entryTeaser__link {
  color: #f36d21;
  position: relative;
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 1.8461538462;
  margin-bottom: 0;
}
.entryTeaser__link:after {
  border: 2px solid currentColor;
  border-width: 2px 2px 0 0;
  transform: rotate(45deg);
  width: 7px;
  height: 7px;
  content: "";
  position: relative;
  display: inline-block;
  margin-left: 2px;
  margin-left: 0.125rem;
}
/* ==========================================================================
   CLIENT DETAILS PAGE STYLES
========================================================================== */
.clientTabs {
  margin-top: 24px;
  margin-top: 1.5rem;
}
@media (min-width: 55.625em) {
  .clientTabs {
    margin: 120px 0px 0px;
    margin: 7.5rem 0rem 0rem;
  }
}
.clientTabs__tab {
  padding-top: 60px;
  padding-top: 3.75rem;
  padding-bottom: 60px;
  padding-bottom: 3.75rem;
}
@media (min-width: 47.5em) {
  .clientTabs__content {
    width: 65.7142857143%;
    display: inline-block;
    vertical-align: middle;
  }
}
@media (min-width: 68.75em) {
  .clientTabs__content {
    width: 52.8571428571%;
  }
}
.clientTabs__title {
  color: currentColor;
  position: relative;
  padding-bottom: 12px;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}
.clientTabs p {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.625;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}

@media (min-width: 59.375em) {
  .client__summary {
    width: 65.7142857143%;
    float: left;
    margin-left: 4.2857142857%;
    margin-right: -100%;
  }
}
.client__logo {
  padding: 24px;
  padding: 1.5rem;
  height: 200px;
  transform-style: preserve-3d;
  vertical-align: middle;
  text-align: center;
  margin-bottom: 12px;
  margin-bottom: 0.75rem;
}
@media (min-width: 37.5em) {
  .client__logo {
    width: 65.7142857143%;
  }
}
@media (min-width: 47.5em) {
  .client__logo {
    width: 48.5714285714%;
    float: right;
    margin-right: 0;
  }
}
.client__logo img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
/* ==========================================================================
   CERTIFICATE STYLES
========================================================================== */
.certificate__item {
  list-style: none;
  color: currentColor;
  padding-top: 36px;
  padding-top: 2.25rem;
}
@media (min-width: 59.375em) {
  .certificate__item--half {
    width: 48.5714285714%;
    float: left;
  }
  .certificate__item--half:nth-child(2n+1) {
    margin-left: 0;
    margin-right: -100%;
    clear: both;
    margin-left: 0;
  }
  .certificate__item--half:nth-child(2n+2) {
    margin-left: 51.4285714286%;
    margin-right: -100%;
    clear: none;
  }
}
@media (min-width: 37.5em) {
  .certificate__item--cpr {
    width: 48.5714285714%;
    float: left;
  }
  .certificate__item--cpr:nth-child(2n+1) {
    margin-left: 0;
    margin-right: -100%;
    clear: both;
    margin-left: 0;
  }
  .certificate__item--cpr:nth-child(2n+2) {
    margin-left: 51.4285714286%;
    margin-right: -100%;
    clear: none;
  }
}
@media (min-width: 59.375em) {
  .certificate__item--cpr {
    width: 31.4285714286%;
    float: left;
  }
  .certificate__item--cpr:nth-child(3n+1) {
    margin-left: 0;
    margin-right: -100%;
    clear: both;
    margin-left: 0;
  }
  .certificate__item--cpr:nth-child(3n+2) {
    margin-left: 34.2857142857%;
    margin-right: -100%;
    clear: none;
  }
  .certificate__item--cpr:nth-child(3n+3) {
    margin-left: 68.5714285714%;
    margin-right: -100%;
    clear: none;
  }
}
.certificate__item--standby {
  position: relative;
}
.certificate__download {
  color: #000;
  font-size: 12px;
  font-size: 0.75rem;
  text-align: center;
  width: 100px;
  width: 6.25rem;
  margin-left: 12px;
  margin-left: 0.75rem;
  margin-bottom: 12px;
  margin-bottom: 0.75rem;
  float: right;
}
@media (min-width: 59.375em) {
  .certificate__download {
    margin-left: 24px;
    margin-left: 1.5rem;
    margin-bottom: 24px;
    margin-bottom: 1.5rem;
  }
}
.certificate__download .icon {
  margin-bottom: 6px;
  margin-bottom: 0.375rem;
  height: 50px;
  width: 59px;
  fill: #f36d21;
  float: right;
}
.certificate .filesize {
  color: rgba(0, 0, 0, 0.5);
  margin-left: 0;
}
.certificate__title {
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1.1428571429;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  color: #000;
  padding-bottom: 12px;
  padding-bottom: 0.75rem;
  margin-bottom: 12px;
  margin-bottom: 0.75rem;
  border-bottom: 1px solid #d9d9d9;
}
.certificate__title__subtitle {
  font-size: 15px;
  color: #f36d21;
  font-style: italic;
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 10px;
  display: inline-block;
}
.certificate__label {
  font-weight: 600;
  font-style: italic;
  line-height: 1.2;
}
.certificate .inline {
  display: inline-block;
}
.certificate .inline:not(:last-of-type) {
  margin-right: 36px;
  margin-right: 2.25rem;
}
.certificate .cpr-item {
  margin-bottom: 0;
}
.certificate a {
  color: currentColor;
}
.certificate p {
  font-size: 14px;
  font-size: 0.875rem;
  padding-right: 24px;
  padding-right: 1.5rem;
}
.certificate__standby {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.75);
  color: #f36d21;
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  justify-content: center;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 1;
}
.certificate__standby__title {
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1.1428571429;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  font-weight: 300;
  text-transform: uppercase;
}
.certificate__standby > p {
  padding-right: 0;
}
.certificate__standby > p:last-child {
  margin-bottom: 0;
}
.certificate__tooltip {
  display: inline;
  text-transform: none;
}
.certificate__tooltip > summary {
  color: #666;
  display: inline-block;
  height: 1em;
  width: 1em;
}
.certificate__tooltip > summary > svg {
  width: 100%;
}
.certificate__tooltip > article {
  font-size: 14px;
  font-size: 0.875rem;
  bottom: 100%;
  left: 50%;
  margin-bottom: 0.5em;
  padding: 1em;
  top: auto;
  transform: translateX(-50%);
  width: 22em;
}
.certificate__tooltip > article::after {
  background-color: #666;
  bottom: 0;
  content: "";
  display: block;
  height: 1em;
  left: calc(50% - 0.5em);
  position: absolute;
  transform: rotate(45deg) translate(10%, 10%);
  transform-origin: center;
  width: 1em;
}
.certificate__tooltip > article > p {
  padding-right: 0;
}
.certificate__tooltip > article > p:last-child {
  margin-bottom: 0;
}

/* ==========================================================================
   SYSTEM STYLES
========================================================================== */
@media (min-width: 59.375em) {
  .system {
    width: 48.5714285714%;
    float: left;
  }
  .system:nth-child(2n+1) {
    margin-left: 0;
    margin-right: -100%;
    clear: both;
    margin-left: 0;
  }
  .system:nth-child(2n+2) {
    margin-left: 51.4285714286%;
    margin-right: -100%;
    clear: none;
  }
}
.system:not(:last-of-type) {
  margin-bottom: 60px;
  margin-bottom: 3.75rem;
}
.system__title {
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}

/* ==========================================================================
   CLIENT SEARCH PAGE STYLES
========================================================================== */
.entryTeaserClient__logo {
  border: 1px solid #d9d9d9;
  padding: 6px;
  padding: 0.375rem;
  height: 130px;
  transform-style: preserve-3d;
  vertical-align: middle;
  text-align: center;
  margin-bottom: 6px;
  margin-bottom: 0.375rem;
  width: 65.7142857143%;
}
@media (min-width: 47.5em) {
  .entryTeaserClient__logo {
    width: 31.4285714286%;
    float: left;
    margin-right: 2.8571428571%;
    text-align: center;
  }
}
.entryTeaserClient__logo img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  max-height: 100px;
}
@media (min-width: 47.5em) {
  .entryTeaserClient__content {
    width: 65.7142857143%;
    float: right;
    margin-right: 0;
  }
}
.entryTeaserClient__totals {
  position: relative;
  margin-right: 12px;
}
.entryTeaserClient__totals:not(:last-of-type):after {
  content: "";
  width: 2px;
  height: 10px;
  position: absolute;
  top: 4px;
  right: -8px;
  background-color: #d9d9d9;
  display: inline-block;
}

/* ==========================================================================
   CLIENT SEARCH PAGE STYLES
========================================================================== */
.entryTeaserCertificate__logo {
  border: 1px solid #d9d9d9;
  padding: 6px;
  padding: 0.375rem;
  height: 130px;
  transform-style: preserve-3d;
  vertical-align: middle;
  text-align: center;
  margin-bottom: 12px;
  margin-bottom: 0.75rem;
  width: 65.7142857143%;
}
.entryTeaserCertificate__logo img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  max-height: 100px;
}
.entryTeaserCertificate__logo--no-imge {
  width: 65.7142857143%;
}
.entryTeaserCertificate__content {
  clear: both;
  width: 100%;
}
.entryTeaserCertificate__certificates {
  margin-top: 12px;
  margin-top: 0.75rem;
}
.entryTeaserCertificate__certificateItem {
  list-style: none;
  margin-bottom: 12px;
  margin-bottom: 0.75rem;
  margin-left: 12px;
  margin-left: 0.75rem;
  font-size: 12px;
  font-size: 0.75rem;
}
.entryTeaserCertificate__certificateItem span {
  color: #4d4d4d;
}

/* ==========================================================================
  AUTOCOMPLETE STYLES
========================================================================== */
.autocomplete-suggestions {
  background: #FFF;
  overflow: auto;
  z-index: unset !important;
}

.autocomplete-suggestion {
  padding: 12px;
  padding: 0.75rem;
  white-space: nowrap;
  overflow: hidden;
}

.autocomplete-selected {
  background-color: #a0a0a0;
}

.autocomplete-suggestions strong {
  font-weight: 600;
  color: #f36d21;
  text-decoration: underline;
}

.autocomplete-group {
  background-color: #f36d21;
  padding: 12px;
  padding: 0.75rem;
}
.autocomplete-group strong {
  color: #FFF !important;
  text-decoration: none !important;
}

/* ==========================================================================
	TEXT WIth IMAGE LAYOUT STYLES
========================================================================== */
.media {
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}
@media (min-width: 37.5em) {
  .media {
    display: flex;
    align-items: center;
  }
  .media *:last-child {
    margin-bottom: 0;
  }
}
@media (min-width: 37.5em) {
  .media__content {
    flex: 1 1 50%;
    padding-left: 24px;
    padding-left: 1.5rem;
  }
}
@media (min-width: 59.375em) {
  .media__content {
    flex: 1 1 66%;
  }
}
@media (min-width: 37.5em) {
  .media__img {
    flex: 1 1 50%;
  }
}
@media (min-width: 59.375em) {
  .media__img {
    flex: 1 1 33%;
  }
}
.media--start {
  align-items: flex-start;
}
.media--end {
  align-items: flex-end;
}

.avcp-title {
  border-bottom: 1px solid currentColor;
  font-size: 16px;
  font-size: 1rem;
  padding-bottom: 12px;
  padding-bottom: 0.75rem;
}

.avcp-detail {
  display: flex;
  flex-direction: column;
}
@media (min-width: 59.375em) {
  .avcp-detail {
    flex-direction: row;
    justify-content: space-between;
  }
}

.avcp-detail__content ul {
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  padding-left: 40px;
}
@media (min-width: 59.375em) {
  .avcp-detail__content {
    width: 35%;
  }
}

.avcp-detail__info {
  overflow: auto;
}
@media (min-width: 59.375em) {
  .avcp-detail__info {
    overflow: visible;
    width: 60%;
  }
}

.avcp-table {
  border-collapse: collapse;
  caption-side: bottom;
  width: 100%;
}
.avcp-table > caption {
  margin-top: 24px;
  margin-top: 1.5rem;
  text-align: left;
}
.avcp-table th,
.avcp-table td {
  border-color: #d9d9d9;
  border-style: solid;
  border-width: 1px;
  padding: 12px;
  padding: 0.75rem;
  text-align: center;
  vertical-align: middle;
}
.avcp-table thead th {
  background-color: #f36d21;
  border-color: currentColor;
  color: #FFF;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: normal;
  min-width: 112px;
}
.avcp-table tbody th {
  font-size: 21px;
  font-size: 1.3125rem;
}

.avcp-icon {
  fill: #4394d1;
  height: 24px;
  height: 1.5rem;
  width: 24px;
  width: 1.5rem;
}

/* ==========================================================================
   LISTS WITH SIGNIFICANT NUMBERS
========================================================================== */
ol.significant-numbers {
  counter-reset: significant-numbers;
  list-style-type: none;
}
ol.significant-numbers > li {
  counter-increment: significant-numbers;
  position: relative;
}
ol.significant-numbers > li::before {
  content: counter(significant-numbers);
  color: #f36d21;
  font-size: 24px;
  font-size: 1.5rem;
  left: -24px;
  left: -1.5rem;
  line-height: 1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

/* ==========================================================================
   STEPS WITH TRIANGLES BETWEEN THEM
========================================================================== */
ol.arrow-steps {
  color: #666;
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}
@media (min-width: 59.375em) {
  ol.arrow-steps {
    flex-direction: row;
    margin-bottom: 48px;
    margin-bottom: 3rem;
  }
}
ol.arrow-steps > li {
  background-color: currentColor;
  border-radius: 7.5px;
  color: inherit;
  display: flex;
  flex-basis: 20%;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  padding: 12px;
  padding: 0.75rem;
  position: relative;
}
@media (min-width: 59.375em) {
  ol.arrow-steps > li {
    margin-bottom: 0;
    margin-right: 24px;
    margin-right: 1.5rem;
  }
}
ol.arrow-steps > li.arrow-steps__v-narrow {
  flex-basis: 10%;
}
ol.arrow-steps > li.arrow-steps__narrow {
  flex-basis: 15%;
}
ol.arrow-steps > li.arrow-steps__wide {
  flex-basis: 25%;
}
ol.arrow-steps > li.arrow-steps__v-wide {
  flex-basis: 30%;
}
ol.arrow-steps > li::after {
  border-color: currentColor transparent transparent;
  border-style: solid;
  border-width: 12px;
  border-width: 0.75rem;
  content: "";
  color: inherit;
  left: 50%;
  position: absolute;
  top: 100%;
  transform: translateX(-50%);
}
@media (min-width: 59.375em) {
  ol.arrow-steps > li::after {
    border-color: transparent transparent transparent currentColor;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
  }
}
ol.arrow-steps > li:last-child {
  margin-bottom: 0;
  margin-right: 0;
}
ol.arrow-steps > li:last-child::after {
  display: none;
}
ol.arrow-steps > li > p {
  color: #FFF;
  font-size: 12px;
  font-size: 0.75rem;
  margin-bottom: 6px;
  margin-bottom: 0.375rem;
}
ol.arrow-steps > li > p:last-child {
  margin-bottom: 0;
}

ol.arrow-steps--orange {
  color: #f36d21;
}

ol.arrow-steps--light-grey {
  color: #a0a0a0;
}

.case-study__section {
  padding-bottom: 48px;
  padding-bottom: 3rem;
  padding-top: 48px;
  padding-top: 3rem;
}

.case-study__section--orange {
  background-color: #f36d21; /* ; */
}
.case-study__section--orange h3,
.case-study__section--orange p,
.case-study__section--orange ul {
  color: #FFF;
}

.case-study__section--grey {
  background-color: #666; /* ; */
}
.case-study__section--grey h3,
.case-study__section--grey p,
.case-study__section--grey ul {
  color: #FFF;
}

.smooth-scroll {
  scroll-behavior: smooth;
}
@media (prefers-reduced-motion: reduce) {
  .smooth-scroll {
    scroll-behavior: auto;
  }
}

.smooth-scroll--shared .smooth-scroll__target:target {
  margin-top: -60px;
  padding-top: 60px;
}
@supports (scroll-padding-top: 60px) {
  .smooth-scroll--shared, .smooth-scroll--shared > body {
    scroll-padding-top: 60px;
  }
  .smooth-scroll--shared .smooth-scroll__target:target {
    margin-top: 0;
    padding-top: 0;
  }
}

.cableConstruction__container {
  overflow: hidden;
}

.cableConstruction__videos {
  display: flex;
  flex-flow: row wrap;
  list-style-type: none;
  margin-left: calc(3rem / -2);
  margin-right: calc(3rem / -2);
}

.cableConstruction__video {
  font-size: 15px;
  font-size: 0.9375rem;
  margin: calc(3rem / 2);
  width: calc(100% - 3rem);
}
@media (min-width: 37.5em) {
  .cableConstruction__video {
    width: calc(50% - 3rem);
  }
}
@media (min-width: 47.5em) {
  .cableConstruction__video {
    width: calc(((1 / 3) * 100%) - 3rem);
  }
}

.cableConstruction__link {
  display: block;
  margin-bottom: calc(3rem / 2);
  position: relative;
}
.cableConstruction__link::before {
  background-image: url("../img/video-icon.png");
  background-repeat: no-repeat;
  content: "";
  display: block;
  height: 56px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 70px;
}
.cableConstruction__link > img {
  width: 100%;
}

.cableConstruction__title {
  color: #000;
  font-size: 16px;
  font-size: 1rem;
}

.stockists {
  background-color: #FFF;
  display: flex;
  flex-direction: column;
  resize: vertical;
  overflow: auto;
  margin-top: 1em;
  margin-bottom: 1em;
}
@media (min-width: 55.625em) {
  .stockists {
    position: absolute;
    left: calc(1.5rem + 10px);
    top: 60px;
    height: 228px;
    width: 230px;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.stockists__header {
  padding: 10px;
  border: 1px solid #ccc;
  background-image: linear-gradient(to top, #efefef, #fff 20%);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
}

.stockists__title {
  color: #666;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: bold;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
}

.stockists__tabs .r-tabs-tab {
  background-image: linear-gradient(to top, #aaa, #ddd 10%);
  font-size: 14px;
  font-size: 0.875rem;
  padding: 0.2em 0.5em;
}
.stockists__tabs .r-tabs-anchor {
  color: #666;
  padding: 1.125rem;
  display: block;
  position: relative;
}
.stockists__tabs .r-tabs-anchor::after {
  content: "▼";
  position: absolute;
  right: 12px;
  right: 0.75rem;
  font-size: 14px;
  font-size: 0.875rem;
}
.stockists__tabs .r-tabs-anchor:hover {
  color: #666;
}
@media (min-width: 55.625em) {
  .stockists__tabs .r-tabs-anchor {
    padding: 0;
    display: inline;
  }
  .stockists__tabs .r-tabs-anchor::after {
    display: none;
  }
}
.stockists__tabs .r-tabs-state-active {
  background-color: #ddd;
  background-image: none;
}
.stockists__tabs .r-tabs-state-active .r-tabs-anchor::after {
  content: "▲";
}
.stockists__tabs input[type=search] {
  border: 1px solid #ccc;
  padding: 1.125rem;
  width: 100%;
  box-sizing: border-box;
}
@media (min-width: 55.625em) {
  .stockists__tabs input[type=search] {
    font-size: 14px;
    font-size: 0.875rem;
    padding: 0.2em 0.5em;
  }
}

.stockists__results {
  border: 1px solid #ccc;
  padding: 10px;
  overflow: auto;
  max-height: 200px;
}
@media (min-width: 55.625em) {
  .stockists__results {
    border: none;
    flex-grow: 1;
    height: 100%;
    max-height: none;
  }
}

.stockists__companies {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.stockists__companies > li + li {
  margin-top: 0.5em;
}
.stockists__companies > li > a {
  font-weight: bold;
  padding: 1.125rem 0;
  display: inline-block;
}
@media (min-width: 55.625em) {
  .stockists__companies > li > a {
    padding: 0;
  }
}

.stockists__container h1,
.stockists__container h2,
.stockists__container h3,
.stockists__container h4,
.stockists__container h5,
.stockists__container h6 {
  font-weight: 400;
}

.stockist-tooltip__title {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 1em;
}

.stockist-tooltip__content {
  font-size: 0.8rem;
  color: #000;
  margin-bottom: 1em;
}

.stockist-tooltip__list {
  display: flex;
  flex-direction: row;
  list-style-type: none;
}
.stockist-tooltip__list > li {
  margin-right: 1em;
}

.stockist-tooltip__link {
  color: green;
}

.two-columns.two-columns--image-left {
  background-color: #eaeaea;
}
.two-columns:first-child .two-columns__content {
  padding-top: 0;
}
.two-columns:first-child .two-columns__media {
  padding-top: 120px;
}

.two-columns__inner {
  padding: 0;
}
@media screen and (min-width: 768px) {
  .two-columns__inner {
    display: flex;
  }
}
.two-columns__inner h2 {
  color: #f36d21;
}
.two-columns__inner h3 {
  color: #f36d21;
}

.two-columns__media {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  order: 2;
}
@media screen and (min-width: 992px) {
  .two-columns__media {
    padding-top: 64px;
    padding-left: 45px;
  }
}
.two-columns__media figure {
  width: 100%;
  height: 100%;
}
.two-columns__media img {
  max-height: 400px;
  width: 100%;
}
.two-columns--image-left .two-columns__media {
  order: 0;
  padding-left: 0;
  padding-right: 15px;
}
@media screen and (min-width: 992px) {
  .two-columns--image-left .two-columns__media {
    padding-right: 45px;
  }
}

.two-columns__content {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 15px;
}
@media screen and (min-width: 992px) {
  .two-columns__content {
    padding-top: 45px;
    padding-right: 45px;
  }
}
.two-columns--image-left .two-columns__content {
  padding-left: 15px;
  padding-right: 0;
}
@media screen and (min-width: 992px) {
  .two-columns--image-left .two-columns__content {
    padding-left: 45px;
  }
}

@media screen and (min-width: 768px) {
  .two-columns__media {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .two-columns--image-left .two-columns__media {
    order: 0;
  }
  .two-columns__content {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}
.background-gray {
  background-color: #eaeaea;
}

.icons-grid {
  padding: 60px 0;
}
.icons-grid h2 {
  color: #f36d21;
  margin-bottom: 60px;
}
.icons-grid h3 {
  font-weight: bold;
  font-size: 1.5rem;
  color: #848181;
}

.icons-grid__wrapper {
  display: flex;
  flex-wrap: wrap;
}
.icons-grid__wrapper a {
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 30px;
}
@media screen and (min-width: 768px) {
  .icons-grid__wrapper a {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media screen and (min-width: 992px) {
  .icons-grid__wrapper a {
    flex: 0 0 20%;
    max-width: 20%;
  }
}
.icons-grid__wrapper img {
  max-height: 110px;
  margin-bottom: 15px;
}

.form-module input,
.form-module select,
.form-module textarea {
  height: 45px;
  height: 2.8125rem;
  padding: 8px 6px;
  padding: 0.5rem 0.375rem;
  width: 100%;
}
.form-module textarea {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.71429;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  color: #666;
  margin: 0 !important;
  padding: 10px;
  min-height: 120px;
  line-height: 100%;
}

.form-module__collapse {
  position: relative;
  padding-bottom: 24px;
  padding-bottom: 1.5rem;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}

.form-module__summary {
  list-style: none;
  text-align: center;
  padding-top: 24px;
  padding-top: 1.5rem;
  padding-bottom: 24px;
  padding-bottom: 1.5rem;
}
.form-module__summary::before {
  display: none;
}
.form-module__summary::-webkit-details-marker {
  display: none;
}
.form-module__summary::after {
  content: "";
  display: block;
  cursor: pointer;
  background-image: url("../img/toggle_widget.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 32px;
  height: 18px;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  transform-origin: center;
}
[open] > .form-module__summary::after {
  transform: translateX(-50%) rotate(180deg);
}
.form-module__summary > h3 {
  padding: 0;
  margin: 0;
}

.form-module__content {
  animation-name: fadeInUp;
  animation-iteration-count: 1s;
  animation-duration: 1s;
  animation-delay: 0s;
  animation-timing-function: ease;
  animation-fill-mode: both;
  backface-visibility: hidden;
}

.form-module__collapsed-text {
  text-align: center;
}
[open] + .form-module__collapsed-text {
  display: none;
}
.form-module__collapsed-text > p {
  margin: 0;
}

.form-module__button {
  background-color: #f36d21;
  color: #FFF;
  position: fixed;
  right: 0;
  top: 100px;
  transform: rotate(-90deg);
  transform-origin: bottom right;
  z-index: 9999;
}
.form-module__button:hover, .form-module__button:focus, .form-module__button:active {
  background-color: #000;
  color: #FFF;
}

.certificate-table-wrapper {
  --padding: 0.75rem;
  --width: min(87.5em, calc(100vw - (var(--padding) * 2) - var(--scrollbar, 0px)));
  width: var(--width);
  height: calc(100vh - var(--scrollbar, 0px));
  overflow: auto;
}
@media (min-width: 37.5em) {
  .certificate-table-wrapper {
    --padding: 1.5rem;
  }
}
@media (min-width: 59.375em) {
  .certificate-table-wrapper {
    overflow: visible;
    height: auto;
  }
}

.certificate-table {
  border-collapse: collapse;
  min-width: 100%;
  font-size: 14px;
  color: #000;
}

.certificate-table__head,
.certificate-table__body {
  display: block;
}

.certificate-table__row {
  display: grid;
  grid-template-columns: minmax(10em, 3fr) minmax(10em, 5fr) minmax(4em, 1fr) minmax(8em, 2fr) minmax(8em, 2fr) minmax(17em, 9fr) minmax(5em, 2fr);
}
@media (min-width: 59.375em) {
  .certificate-table__row {
    grid-template-columns: minmax(120px, 1fr) minmax(10em, 3fr) minmax(10em, 3fr) minmax(3em, 1fr) minmax(3em, 2fr) minmax(5em, 2fr) minmax(10em, 3fr) minmax(5em, 2fr);
  }
}
.certificate-table__row[hidden] {
  display: none;
}

.certificate-table__head {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
}

.certificate-table__cell {
  border-bottom-style: solid;
  border-color: #a0a0a0;
  border-width: 1px;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (min-width: 59.375em) {
  .certificate-table__cell {
    padding: 20px;
  }
}
.certificate-table__cell > p {
  margin: 0;
}

.certificate-table__cell--hide-mobile {
  display: none;
}
@media (min-width: 59.375em) {
  .certificate-table__cell--hide-mobile {
    display: flex;
  }
}

.certificate-table__cell--heading {
  text-align: left;
  color: #f36d21;
}

.certificate-table__cell--fixed {
  position: sticky;
  left: 0;
  background-color: #fff;
  align-self: start;
}
@media (min-width: 59.375em) {
  .certificate-table__cell--fixed {
    position: static;
  }
}

.certificate-table__head .certificate-table__cell {
  border-width: 2px;
}

.certificate-table__head .certificate-table__cell--fixed {
  z-index: 2;
}

.certificate-table__list {
  margin-top: 1em;
  display: table;
}
.certificate-table__list > div {
  display: table-row;
}
.certificate-table__list > div > dt {
  color: #f36d21;
  font-weight: bold;
  display: table-cell;
  padding: 0.1em 0.2em 0.1em 0;
}
.certificate-table__list > div > dt::after {
  content: ":";
}
.certificate-table__list > div > dd {
  display: table-cell;
  padding: 0.1em 0 0.1em 0.2em;
  margin: 0;
}

@media (min-width: 47.5em) {
  .entryTeaserClient__logo--certificate-table {
    margin: 0;
    width: auto;
    float: none;
  }
}

.pagination--certificate-table {
  margin-top: 4rem;
  text-align: center;
}

.lead--certificate-table {
  font-size: 16px;
}

.regionalExpertsSlider {
  padding: 55px 0;
}
.regionalExpertsSlider h3 {
  color: #f36d21;
}

.regionalExperts__row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.regionalExperts__column {
  flex: 0 0 100%;
}
@media (min-width: 600px) {
  .regionalExperts__column:first-child:nth-last-child(n+2), .regionalExperts__column:first-child:nth-last-child(n+2) ~ .regionalExperts__column {
    flex-basis: 50%;
  }
}
@media (min-width: 760px) {
  .regionalExperts__column:first-child:nth-last-child(n+3), .regionalExperts__column:first-child:nth-last-child(n+3) ~ .regionalExperts__column {
    flex-basis: 33.3333%;
  }
}
@media (min-width: 950px) {
  .regionalExperts__column:first-child:nth-last-child(n+4), .regionalExperts__column:first-child:nth-last-child(n+4) ~ .regionalExperts__column {
    flex-basis: 25%;
  }
}

.regionalExpert {
  margin: 10px 30px;
}

.regionalExpert__item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.regionalExpert__img > img {
  max-width: 125px;
  border-radius: 100%;
}

.regionalExpert__content {
  flex: 1;
}
.regionalExpert__content > p {
  margin-bottom: 0;
}

.regionalExpert__name,
.regionalExpert__job-title {
  color: #000;
  margin-bottom: 0.5rem;
}

.regionalExpert__name {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.2;
}

.regionalExpert__job-title {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.5;
}

.regionalExpert__contact {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.5;
}

.relatedNewsSlider {
  padding: 55px 0;
}

.slick-carousel .slick-prev,
.slick-carousel .slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-carousel .slick-prev:hover, .slick-carousel .slick-prev:focus,
.slick-carousel .slick-next:hover,
.slick-carousel .slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-carousel .slick-prev:hover:before, .slick-carousel .slick-prev:focus:before,
.slick-carousel .slick-next:hover:before,
.slick-carousel .slick-next:focus:before {
  opacity: 1;
}
.slick-carousel .slick-prev.slick-disabled:before,
.slick-carousel .slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-carousel .slick-prev:before,
.slick-carousel .slick-next:before {
  font-size: 20px;
  line-height: 1;
  color: #f36d21;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-carousel .slick-prev {
  left: -5px;
}
[dir=rtl] .slick-carousel .slick-prev {
  left: auto;
  right: -5px;
}
.slick-carousel .slick-prev:before {
  border: 4px solid currentColor;
  border-width: 4px 4px 0 0;
  transform: rotate(-135deg);
  width: 20px;
  height: 20px;
  content: "";
  position: relative;
  display: inline-block;
  top: -1px;
  top: -0.0625rem;
}
[dir=rtl] .slick-carousel .slick-prev:before {
  border: 4px solid currentColor;
  border-width: 4px 4px 0 0;
  transform: rotate(45deg);
  width: 20px;
  height: 20px;
  content: "";
  position: relative;
  display: inline-block;
  top: -1px;
  top: -0.0625rem;
}
.slick-carousel .slick-next {
  right: -5px;
}
[dir=rtl] .slick-carousel .slick-next {
  left: -5px;
  right: auto;
}
.slick-carousel .slick-next:before {
  border: 4px solid currentColor;
  border-width: 4px 4px 0 0;
  transform: rotate(45deg);
  width: 20px;
  height: 20px;
  content: "";
  position: relative;
  display: inline-block;
  top: -1px;
  top: -0.0625rem;
}
[dir=rtl] .slick-carousel .slick-next:before {
  border: 4px solid currentColor;
  border-width: 4px 4px 0 0;
  transform: rotate(-135deg);
  width: 20px;
  height: 20px;
  content: "";
  position: relative;
  display: inline-block;
  top: -1px;
  top: -0.0625rem;
}

.slick-equal-heights .slick-track {
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-width: 100%;
}
.slick-equal-heights .slick-track::before, .slick-equal-heights .slick-track::after {
  display: none;
}
.slick-equal-heights .slick-track > .slick-slide {
  height: auto;
  float: none;
}
.slick-equal-heights .slick-track > .slick-slide > div {
  height: 100%;
}

.cookie-notice {
  background-color: #666;
  color: #fff;
  padding: 30px;
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 9000001;
}
@media (min-width: 768px) {
  .cookie-notice {
    width: 340px;
    right: 20px;
    bottom: 20px;
  }
}

.cookie-notice__heading {
  font-size: 16px;
  font-weight: bold;
  color: inherit;
}

.cookie-notice__body {
  font-size: 15px;
}
.cookie-notice__body a {
  color: inherit;
  font-weight: bold;
}
.cookie-notice__body a:hover, .cookie-notice__body a:focus {
  color: inherit;
  text-decoration: underline;
}

.cookie-notice__btn {
  min-width: 1px;
  font-size: 15px;
}

#olark-wrapper .olark-launch-button-wrapper {
  bottom: 65px !important;
}

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #000;
  opacity: 0.9;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}

.mfp-container:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}
.mfp-preloader a {
  color: #CCC;
}
.mfp-preloader a:hover {
  color: #FFF;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
}
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.8;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: "Roboto", sans-serif;
}
.mfp-close:hover, .mfp-close:focus {
  opacity: 1;
}
.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.8;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.mfp-arrow:active {
  margin-top: -54px;
}
.mfp-arrow:hover, .mfp-arrow:focus {
  opacity: 1;
}
.mfp-arrow:before, .mfp-arrow:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}
.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}
.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}
.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px;
}
.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F;
}

.mfp-arrow-right {
  right: 0;
}
.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px;
}
.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}
.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}
.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}
.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}
.mfp-figure:after {
  content: "";
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}
.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px;
}
.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #FFF;
  word-wrap: break-word;
  padding-right: 36px;
  margin-top: 12px;
  margin-top: 0.75rem;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
   * Remove all paddings around the image on small screen
   */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}
@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}
/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 0 0;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
}
.hamburger:hover {
  opacity: 1;
}

.hamburger-box {
  width: 30px;
  height: 19px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1.5px;
}
.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 30px;
  height: 3px;
  background-color: #FFF;
  border-radius: 0;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}
.hamburger-inner::before {
  top: -8px;
}
.hamburger-inner::after {
  bottom: -8px;
}

/*
 * Stand
 */
.hamburger--stand .hamburger-inner {
  transition: transform 0.1s 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.1s linear;
}
.hamburger--stand .hamburger-inner::before {
  transition: top 0.1s 0.1s ease-in, transform 0.1s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--stand .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease-in, transform 0.1s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--stand.is-active .hamburger-inner {
  transform: rotate(90deg);
  background-color: transparent;
  transition: transform 0.1s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.22s linear;
}
.hamburger--stand.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-45deg);
  transition: top 0.1s 0.12s ease-out, transform 0.1s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--stand.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(45deg);
  transition: bottom 0.1s 0.12s ease-out, transform 0.1s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/* Slider */ /* ==========================================================================
	STANDARD PAGINATION STYLES
========================================================================== */
.slick-dots {
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  width: 100%;
  margin-top: 12px;
  margin-top: 0.75rem;
}
.slick-dots li {
  transition: all 0.4s ease;
  position: relative;
  display: inline-block;
  height: 24px;
  width: 24px;
  margin: 0 5px 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  transition: all 0.4s ease;
  border: 0;
  background: transparent;
  display: inline-block;
  height: 24px;
  width: 24px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  opacity: 0.25;
  color: #666;
  border: 1px solid currentColor;
}
@media (min-width: 59.375em) {
  .slick-dots li button {
    color: #FFF;
  }
}
.slick-dots li button:hover, .slick-dots li button:focus {
  transition: all 0.4s ease;
  outline: none;
  opacity: 1;
}
.slick-dots li.slick-active button {
  background-color: #f36d21;
  color: #f36d21;
  opacity: 1;
}

/* ==========================================================================
  STANDARD PAGINATION STYLES
========================================================================== */
.slick-dots--grey {
  margin-top: 12px;
  margin-top: 0.75rem;
  display: block;
  list-style: none;
  padding: 0;
  position: relative;
  text-align: center;
  width: 100%;
  z-index: 100;
}
.slick-dots--grey li {
  cursor: pointer;
  display: inline-block;
  height: 24px;
  margin: 0 5px 5px;
  padding: 0;
  position: relative;
  transition: all 0.4s ease;
  width: 24px;
}
.slick-dots--grey li button {
  background: transparent;
  border: 0;
  border: 1px solid currentColor;
  color: #666;
  cursor: pointer;
  display: inline-block;
  font-size: 0px;
  height: 24px;
  line-height: 0px;
  opacity: 0.25;
  outline: none;
  transition: all 0.4s ease;
  width: 24px;
}
@media (min-width: 59.375em) {
  .slick-dots--grey li button {
    color: #a0a0a0;
  }
}
.slick-dots--grey li button:hover, .slick-dots--grey li button:focus {
  opacity: 1;
  outline: none;
  transition: all 0.4s ease;
}
.slick-dots--grey li.slick-active button {
  background-color: #f36d21;
  color: #f36d21;
  opacity: 1;
}

.slick-slider {
  box-sizing: border-box;
  display: block;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.slick-list {
  display: block;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  display: block;
  left: 0;
  position: relative;
  top: 0;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
  outline: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  border: 1px solid transparent;
  display: block;
  height: auto;
}

.slick-arrow.slick-hidden {
  display: none;
}

@media print {
  * {
    background: transparent !important;
    color: #000 !important; /* Black prints faster: h5bp.com/s */
    box-shadow: none !important;
    text-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: "";
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group; /* h5bp.com/t */
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  @page {
    margin: 0.5cm;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  img {
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
  }
  .main-menu, .footer-menu, [class*=btn] {
    display: none;
  }
  .footer {
    border-top: 1px solid #a0a0a0;
  }
}