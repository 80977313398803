/* ==========================================================================
   FOOTER STYLES
========================================================================== */

.footer {
	@extend %cf;
	width:100%;
	position:relative;
	background-color:$color-base;
	@include remit(padding-top,40);
}


.footer {
	@extend %cf;

	&__legals {
		clear:both;
		@include remit(margin-top, $g-spacing);
	}

	&__copyright {

		@include mq($regular) {
			width: span(10);
			display:inline-block;
		}
	}

	&__nav {
		@include remit(margin-bottom, $bsu);
		@include mq($regular) {
			display:inline-block;
			text-align:right;
			width: span(14);
		}
	}
}


@mixin footer__vertLine {
	display:inline-block;
	vertical-align:middle;
	position:relative;
	@include remit(margin-right, 30);
	@include remit(padding-right, 30);

	&:after {
		content: '';
		position:absolute;
		top:0;
		bottom:0;
		right:0;
		height:100%;
		display: block;
		width: 1px;
		background: rgba($white, 0.5);
	}
}

.footer__top {

	@include mq($large) {
		@include span(16);
		// border:1px solid red;
	}
}

.footer__logo {
	@include remit(margin-bottom, $bsu);

	@include mq($small) {
		@include footer__vertLine;
		margin-bottom:0;
	}

	img {
		width:180px;
		height:57px;

		@include mq($medium) {
			width:143px;
			height:45px;
		}
	}
}

.footer__social {
	@include mq($small) {
		display:inline-block;
		vertical-align:middle;
	}

	@include mq($medium) {
		@include footer__vertLine;
	}
}

.footer__contact {
	@include remit(margin-top, $bsu);

	@include mq($medium) {
		margin-top:0;
		display:inline-block;
		vertical-align:middle;
	}

}




