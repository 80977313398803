.sticky-header--social-share {
	background: #eee;
    display: block;
    padding: 2px 0 0 0;
    height: 60px;
	width: 100%;
    position: fixed;
    top: -60px;
    z-index: 999;
    transition: top 0.3s;

    &.fixed {
    	top: 0;
   		position: fixed;
    }

    .container {
    	padding-top: 10px;
    }

    .social-list {
    	@media screen and (max-width: 420px) {
	    	li {
	    		width: 28px;
			    height: 28px;
			    margin-top: 5px;
	    	}
    	}
    }

    .panel__title {
    	display: inline-block;
	    border: 0 none;
	    width: auto;
	    float: left;
	    margin: 0;
    	padding: 0.5rem 0;
    }

    .social-list {
    	float: right;
    	display: block;
    }
}