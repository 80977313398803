$cableConstruction_gutter: rem($bsu * 2);

.smooth-scroll {
	scroll-behavior: smooth;
	@media (prefers-reduced-motion: reduce) {
		scroll-behavior: auto;
	}
}

.smooth-scroll--shared {

	$share-heading-height: 60px;

	.smooth-scroll__target:target {
		margin-top: ($share-heading-height * -1);
	    padding-top: $share-heading-height;
	}

	@supports (scroll-padding-top: #{$share-heading-height}) {

		// This style needs to be on the `<body>` in Chrome <= 73 but it needs
		// to be on the `<html>` in future versions.
	    &,
	    & > body {
	        scroll-padding-top: $share-heading-height;
	    }

	    .smooth-scroll__target:target {
			margin-top: 0;
	        padding-top: 0;
	    }

	}

}

.cableConstruction__container {
	overflow: hidden;
}

.cableConstruction__videos {
    display: flex;
    flex-flow: row wrap;
    list-style-type: none;
    margin-left: calc(#{$cableConstruction_gutter} / -2);
    margin-right: calc(#{$cableConstruction_gutter} / -2);
}

.cableConstruction__video {

    @include font-rem($p-font-size);
    margin: calc(#{$cableConstruction_gutter} / 2);
    width: calc(100% - #{$cableConstruction_gutter});

    @include mq($small) {
        width: calc(50% - #{$cableConstruction_gutter});
    }

    @include mq($medium) {
        width: calc(((1 / 3) * 100%) - #{$cableConstruction_gutter});
    }

}

.cableConstruction__link {

    display: block;
    margin-bottom: calc(#{$cableConstruction_gutter} / 2);
    position: relative;

    &::before {
        background-image: url("../img/video-icon.png");
        background-repeat: no-repeat;
        content: "";
        display: block;
        height: 56px;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 70px;
    }

    > img {
        width: 100%;
    }

}

.cableConstruction__title {
    color: $black;
    @include font-rem($base-font-size);
}
