/* ==========================================================================
	 IMAGES
========================================================================== */
img {
	/* Responsive images (ensure images don't scale beyond their parents) */

	max-width: 100%;
	/* Part 1: Set a maxium relative to the parent */

	height: auto;
	/* Part 2: Scale the height according to the width, otherwise you get stretching */

	vertical-align: middle;
	-ms-interpolation-mode: bicubic;

	font-style:italic;
	/*So that `alt` text is visually offset if images don’t load */
}

svg { max-height: 100%; }

/* ==========================================================================
	 IMAGE PLACEMENTS
========================================================================== */
%img-right, .img-right {
	@include remit(margin-bottom, $bsu);
	@include remit(margin-left, $bsu);
	float:right;
}

.img-left {
	@include remit(margin-bottom, $bsu);
	@include remit(margin-right, $bsu);
	float:left;
}

.img-center {
	@include remit(margin-bottom, $bsu);
	display:block;
	margin-right:auto;
	margin-left:auto;
}

// .img-right--medium, %img-right--medium {
// 	@extend %img-center;

// 	@include mq($medium) {
// 		@include remit(margin-left, $bsu);
// 		float:right;
// 	}
// }

// .img-right--small, %img-right--small {
// 	@extend %img-center;

// 	@include mq($small) {
// 		@include remit(margin-left, $bsu);
// 		float:right;
// 	}
// }

%no-image {
	position:relative;
	background-color:lighten($lightgrey, 10%);

	&:before {
		content: 'No Image Avalable';
		text-align:center;
		display:block;
		color:lighten($color-text, 30%);
		@include font-rem(14);
		position: relative;
		top: 50%;
		transform: translateY(-50%);
	}
}

/* ==========================================================================
	 LAZY IMAGES
========================================================================== */

img[data-sizes="auto"] { display: block; width:100%; }

.lazyload {
	opacity: 0;

	.no-js & {
		display: none;
	}
}

.lazyloading {
	background-color: lighten($lightgrey, 10%);
	background-image: url('/assets/img/loading.svg');
	background-repeat:no-repeat;
	background-position:center center;
}


.lazyloaded {
	@include fadeIn();
}

// ========================================================================== //
//	 FIGURES
// ========================================================================== //

.figure {
	@include remit(margin-top, $bsu*2);
	@include remit(margin-bottom, $bsu*2);

	@include mq($regular) {
		&--fullwidth {
			margin-left: -(span(2));
		}
	}
}

.figcaption {
	@include font-size(13, no, 20);
	color:$color-sub;
	font-style:italic;
	@include remit(margin-top, 6);
}

.figure--ml0 > .figure--fullwidth {
	margin-left: 0;
}



/* ==========================================================================
	 MISC/UNIQUE IMAGE STYLES
========================================================================== */

.logo {

	.header & {
		width:130px;
		height:40px;
		display:inline-block;
		position:relative;
		@include remit(margin-right, 20);
		@include mq($small) {
			@include remit(margin-right, 30);
		}

		&:after {
			content: '';
			position:absolute;
			top:0;
			@include remit(right, -15);
			height: 100%;
			display: block;
			width: 1px;
			background: rgba($white, 0.5);

			@include mq($small) {
				@include remit(right, -20);
			}

		}

	}
}

.logoCircle {
	.header & {
		width:45px;
		height:45px;
		position:relative;
		@include remit(top, -2);
	}
}


%img-circle {
	@include remit(margin-bottom, $hsu);
	border-radius:50%;
	box-shadow: 0px 0px 0px 3px $white;

}
