/* ==========================================================================
   CLIENT SEARCH PAGE STYLES
========================================================================== */

.entryTeaserCertificate {
	// border-bottom:1px solid $lightgrey;

	&__logo {
		border:1px solid $lightgrey;
		@include remit(padding, 6);
		height:130px;
		transform-style: preserve-3d;
		vertical-align:middle;
		text-align:center;
		@include remit(margin-bottom, $hsu);
		width:span(16);

		// @include mq($medium) {
		// 	@include span(8);
		// 	text-align:center;
		// }


		img {
			position: relative;
			top: 50%;
			transform: translateY(-50%);
			max-height:100px;
		}

		&--no-imge {
			@extend %no-image;
			width:span(16);
		}
	}

	&__content {
		clear:both;
		width:100%;
		// @include mq($medium) {
		// 	@include span(16 last);
		// }
	}

	&__certificates {
		@include remit(margin-top, $hsu);
	}

	&__certificateItem {
		list-style:none;
		@include remit(margin-bottom, $hsu);
		@include remit(margin-left, $hsu);
		@include font-rem(12);

		span {
			color:darken($color-text, 10%);
		}
	}
}