/* ==========================================================================
	CARD STYLES
========================================================================== */
.card {
	position:relative;
	overflow:hidden;
	@include remit(margin, 0 $hsu $hsu);

	&__title {
		position:relative;
		bottom:0;
		width:100%;
		background-color:$color-base;
		color:$white;
		text-align:right;
		@include remit(padding, 16);
		border-top:1px solid $white;
		margin-bottom:0;
		@include font-rem($h3-font-size);

		&:after {
			@include arrow(10px, 45deg, 2px);
			content: '';
			position:relative;
			display:inline-block;
			@include remit(top, -1);
			@include remit(margin-left, 4);
		}
	}

	&__overlay {
		display:none;
		position:absolute;
		top:0;
		bottom:0;
		left:0;
		right:0;
		text-align:center;
		background-color:$color-base;
		color:$white;
		@include remit(padding, $bsu);
		z-index:100;
	}

	&__overlayTitle {
		color:$white;
		border-bottom:1px solid rgba($white, 0.5);
		@include remit(padding-bottom, $hsu);
		width:80%;
		@include font-rem($h2-font-size);
	}

	&__overTitle, p {
		@include fadeIn();
	}

	&__btn {
		color:$color-sub;
		position:relative;
		@extend %btn-reset;

		&:after {
			@include arrow(8px, 45deg, 2px);
			content: '';
			position:relative;
			display:inline-block;
			@include remit(top, -1);
			@include remit(margin-left, 4);
		}
	}
}


.card:hover {

	.card__overlay {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		// @include slideInUp($duration:0.5s);
		@include flipInX($duration:0.5s);
	}
}



/* ==========================================================================
	CARD LAYOUT STYLES
========================================================================== */

// .l-card--half {
// 	@include mq($small) {
// 		@include gallery(12, of-type);
// 	}
// }

.l-card--third {
	@include remit(margin-bottom, $bsu);

	@include mq($regular) {
		@include gallery(8, of-type);
	}
}
