/* ==========================================================================
	SOCIAL ICON STYLES
========================================================================== */
.icon-social {
	transition:all .20s ease-in-out;
	width: 40px;
	height: 40px;
	fill: currentColor;

	.footer & {
		color:$white;
	}

	@include hover() {
		transform: scale(1.4);
		z-index:10;
		color: $orange;
	}
}

@each $social-media, $color in $social-media {
.icon-#{$social-media} {
		transition:$g-trans;
		color:$color;
	}
}

.icon-email-share {
	transition:$g-trans;
	/*color:$color-text;*/
	color: $grey;
}

.social-list {
	font-size:0;

	li {
		list-style:none;
		display:inline-block;
	}

	&--share  {
		li {
			@include remit(margin-right, 3);
		}

	}

	&--follow {
		li {
			@include remit(margin-right, 8);
		}
	}

}