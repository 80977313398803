/* ==========================================================================
   HELPER STYLES
========================================================================== */

.island-top 			{@include remit(margin-top, $g-spacing, !important); }
// .island-right 			{@include remit(margin-right, $g-spacing, !important);}
.island-bottom 			{@include remit(margin-bottom, $g-spacing, !important);}
// .island-left 			{@include remit(margin-left, $g-spacing, !important);}
// .island					{@include remit(margin, $g-spacing, !important);}

.margin-top 				{@include remit(margin-top, $bsu, !important);}
.margin-topX2 				{@include remit(margin-top, $bsu*2, !important);}
// .margin-right 			{@include remit(margin-right, $bsu, !important);}
// .margin-bottom 			{@include remit(margin-bottom, $bsu, !important);}
.margin-bottomX2 			{@include remit(margin-bottom, $bsu*2, !important);}
// .margin-left 			{@include remit(margin-left, $bsu, !important);}

// .half-margin-top 		{@include remit(padding-top, $hsu, !important);}
// .half-margin-right 		{@include remit(margin-right, $hsu, !important);}
// .half-margin-bottom 	{@include remit(margin-bottom, $hsu, !important);}
// .half-margin-left 		{@include remit(margin-left, $bsu, !important);}


.flush-top 				{padding-top:0 !important;}
// .flush-right 			{margin-right:0 !important;}
.flush-bottom 			{margin-bottom:0 !important;}
// .flush-left 				{margin-left:0 !important;}
// .flush 					{margin:0 !important;}

.float-right			{float:right !important; }
.float-left				{float:left !important; }

.text-left				{text-align:left; }
.text-center			{text-align:center;}
.text-right				{text-align:right; }

.d-ib { display: inline-block !important; }

%muted					{opacity:0.5;}

%padding-container {
	@include remit(padding, 0 $hsu);

	@include mq($small) {
		@include remit(padding, 0 $bsu);
	}
}

%no-gutter, .no-gutter {
	@include mq($small, max) {
		@include remit(margin-left, -$hsu);
		@include remit(margin-right, -$hsu);
	}

	@include mq($small) {
		@include mq($regular, max) {
			@include remit(margin-left, -$bsu);
			@include remit(margin-right, -$bsu);
		}
	}
}

%inline-block {
	display: inline-block;

	.lt-ie9 & {
		zoom: 1;
		*display: inline;
		}
}

.underline {
	border-bottom: 1px solid #d9d9d9;
}

.background--grey {
	background-color: #eee;
}

.background--dark-grey {
	background-color: #a1a1a1;
	color: white;

	h1,h2,h3,h4,p,a {
		color: white!important;
	}
}

.background--orange {
	background-color: #f36d21;
	color: white;

	a:not(.btn) {
		color: white;
		text-decoration: underline;
	}
}

.padded {
	padding-top: 60px;
	padding-bottom: 60px;

	&--executive {
		padding-top: 0;
		display: flex;
		flex-direction: column;
		@include mq($medium) {
			justify-content: center;
			flex-direction: row;
		}
		// @include mq($large) {
		// 	padding-top: 60px;
		// }
	}
}

.padding-bottom {
	padding-bottom: 60px;
}

.padding-top {
	padding-top: 60px;
}

.one-of-three {
	font-size: 15px;
	min-height: 150px;

	@include mq($regular) {
		width: calc( 33.333% - 20px );
		float: left;
		margin-left: 10px;
		margin-right: 10px;

		&.article, .article & {
			margin: 0;
			width: 33.333%;
			float: left;
		}
	}

}

.inline-block {
	display: inline-block;
}

.certificate__item {
	&.one-of-two {

		@include mq($regular) {
			width: calc( 50% - 20px );
			float: left;
			margin-left: 10px;
			margin-right: 10px;
			padding-left: 0;
			padding-right: 0;
		}
	}
}

.custom-container {
	clear: both;

	@include mq($regular) {
		margin-left: -10px;
		margin-right: -10px;
	}
}

.one-of-four {
	margin: 10px 0;
	text-align: center;

	@include mq($medium) {
		width: calc( 50% - 20px );
		float: left;
		margin: 10px;
	}

	@include mq($large) {
		width: calc( 25% - 20px );
		float: left;
		margin: 0 10px;
		text-align: left;
	}
}

.side-padding {
	padding-left: 30px;
	padding-right: 30px;
}

// .col-lg-6 {
//     width: 49%;
//     float: left;

//     &:nth-child(odd) {
//    		margin-right: 1%;
//     }

//     &:nth-child(even) {
//    		margin-left: 1%;
//     }
// }


/* ==========================================================================
   CLEARFIX  STYLES
========================================================================== */
/*
 * Micro clearfix, as per: css-101.org/articles/clearfix/latest-new-clearfix-so-far.php
 * Extend the clearfix class with Sass to avoid the `%cf` class appearing over
 * and over in your markup.
 */

%cf {
	&:after{
		content:"";
		display:table;
		clear:both;
	}
}

.clear	{clear:both;}

//==========================================================================
// STYLING HELPERS
//==========================================================================

//$horizontal  $vertical  $blur  $spread  $color;
%shadow {
	box-shadow:0 0 4px 2px $grey;
}

//$horizontal  $vertical  $blur  $spread  $color;
%inner-shadow {
	box-shadow: inset 0 0 4px $grey;
}

//Makes Whole Box Rounded
%round {
	border-radius:10px;
}

// Border Radius To define each corner
%border-radius {
	border-radius: 10px 10px 10px 10px;
	-moz-background-clip: padding;
	background-clip: padding-box;
}

// Normalize (<button>)
%btn-reset {
	font: inherit;
	color: inherit;
	text-transform: none;
	background-color: transparent;
	border: 0;
	margin: 0;
	padding:0;
	overflow: visible;
	outline:0;
	-webkit-appearance: none;
}

/* ==========================================================================
	HIDE STYLES
========================================================================== */

.hidden {
	display: none;
}

.alt {
	@extend %visually-hidden;
}

//Image replacement (Replaces an image for text in HTML)
%ir {
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
}

//Hide only visually, but have it available for screenreaders: h5bp.com/v
%visually-hidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;

//Extends the .visuallyhidden class to allow the element to be focusable when navigated to via the keyboard: h5bp.com/p
	.focusable {
		&:active, &:focus {
			clip: auto;
			height: auto;
			margin: 0;
			overflow: visible;
			position: static;
			width: auto;
		}
	}
}


//==========================================================================
// ARROW/TRIANGLE  STYLES
//==========================================================================

// See Triangles Mixin for arrows
// Also see the forms.scss for an example in the %select-list


// Add an arrow "»" before the text
%arrow-before {
	content: "\00bb";
	@include remit(margin-right, 5);
}
