/* ==========================================================================
	EXPANDING BLOCK STYLES
========================================================================== */
summary {
	outline:none
}

.expandingBlock {
	@include remit(margin-bottom, $bsu*1.5);

	&:first-of-type() {
		@include remit(margin-top, $bsu*2);
	}

	&:last-of-type() {
		@include remit(margin-bottom, $bsu*2);
	}

	&__title {
		font-weight:400;
		@include font-size(18, yes, 18*1.2);
		border-bottom:1px solid $lightgrey;
		@include remit(padding, 0 $hsu $hsu);
		cursor:pointer;
	}

	&__content {
		@include fadeInUp($fill:none);
		@include remit(padding, 0 $hsu $hsu);
	}
}

.expandingBlockCables {
	&__title {
		@include font-size(14, $hsu, 21);
		color:$color-link;
		font-weight:400;
		cursor:pointer;
	}

	&__content {
		@include fadeInUp();
	}
}

