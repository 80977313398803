// rem size

@function rem($size) {
	@return #{$size/$doc-font-size}rem;
}

// em size

@function em($size) {
	@return #{$size/$doc-font-size}em;
}

// rem + px fallback

@mixin remit($property, $sizes, $important:null) {
	$rems: ();
	$pxs: ();

	@for $i from 1 through length($sizes) {
		$size: nth($sizes, $i);
		$pxs: join($pxs, #{$size}px);
		$rems: join($rems, #{$size/$doc-font-size}rem);
	}

	#{$property}: $pxs $important;
	#{$property}: $rems $important;
}

// making simple squares

@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}
