/* ==========================================================================
	TEAM RELATED LAYOUT STYLES
========================================================================== */

.teamRelated {
	&__item {
		// @extend %cf;
		display: flex;
		align-items: flex-start;
	}

	&__img {
		margin-right: 1rem;
		// @include span(8);

		img {
			max-width: 125px;
		}
	}

	&__content {
		flex: 1;
		// @include span(15);
		// margin-left:span(1);
		// margin-right:0;
		// @include remit(padding-top, 18);

		p {
			margin-bottom: 0;
		}
	}
}

/* ==========================================================================
	TEAM RELATED STYLES
========================================================================== */

.teamRelated {
	&__name,
	&__job-title {
		color: $color-sub;
		margin-bottom: 0.5rem;
	}

	&__name {
		@include font-rem(16);
		line-height: 1.2;
	}

	&__job-title {
		@include font-rem(14);
		line-height: 1.5;
	}

	&__contact {
		@include font-rem(14);
		line-height: 1.5;
	}
}