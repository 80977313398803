/* ==========================================================================
	CABLE STYLES
========================================================================== */
.cableGroup {
	@extend %cf;
	@include remit(margin-bottom, $g-spacing);
	@include remit(padding-top, $bsu);

	&__description {

		@include mq($regular) {
			width: span(14);
			margin-left:span(1);
		}
	}
}

$map: (columns: 5, gutters: 0.25,);

@include with-layout($map) {
	.cable {
		list-style:none;
		position:relative;
		@include remit(margin-bottom, $bsu);
		@include gallery(2.5);

		@include mq($small) {
			@include gallery(5/3);
		}

		@include mq($regular) {
			@include gallery(1);
		}

		&__tag {
			background-color:$color-sub;
			color:$white;
			@include remit(padding, 6 $hsu);
			@include font-size(15, no, 24);
			display:inline-block;
			width:auto;
			position:relative;
			@include remit(left, -6);
			@include remit(top, -$hsu);
			border-left:6px solid $color-base;
      z-index:50;

			@include mq($small) {
				@include remit(left, -$hsu);
			}
		}

		&__title {
			background-color:$color-sub;
			color:$white;
			@include remit(padding, 6 $hsu);
			// @include font-size(21, $hsu, 21*1.3);
			font-size:4vh;
			display:block;
			width:auto;
			border-left:6px solid $color-base;
		}
	}
}

/* ==========================================================================
	CABLE ROLLOVER STYLES
========================================================================== */
.cable a {
  // position:relative;
	@include hover() {
		&:before {
			content:attr(data-title) "[ View Details ]";
			background-color:$color-base;
			color:$white;
			text-align:center;
			font-family:$font-header;
			font-weight:600;
			@include remit(padding, $hsu);
			vertical-align:middle;
			position:absolute;
      z-index:20;

			width:100%;
			height:100%;
			white-space: pre;

			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			@include flipInX($duration:0.5s);
		}
	}
}


/* ==========================================================================
	CABLE MODAL STYLES
========================================================================== */
.cable {

	&__modal {
		padding:10%;
		position: relative;
		height:100%;
	}

	&__img {
		position:absolute;
		bottom:0;
		display:block;
		width:100%;
		height:100%;
		text-align:right;
		transform-style: preserve-3d;

		img {
			width:90%;
			position: relative;
			top: 35%;
			transform: translateY(-35%);
		}
	}

	&__content {
		position:relative;
		top:0;
		z-index:300;
		@include slideInLeft($delay:0.2s);

		@include mq($small) {
			width:span(12);
		}

		p {
			@include font-size(17, no, 17*1.5);
			@include remit(margin-left, 20);
		}
	}
}