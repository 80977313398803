/* ==========================================================================
	BG IMAGE STYLES
========================================================================== */

.bg {
	background-repeat:no-repeat;
}

.bg--logoListings {
	@include mq($regular) {
		background-image: url('/assets/img/bg-cable-logolisting.jpg');
		background-position:top right;
		background-size:70%;
		width:450px;
		height:390px;
		position:absolute;
		top:0;
		right:0;

		@include mq($large) {
			background-size:90%;
		}

		@include mq(1500) {
			background-size:100%;
		}
	}
}