
// $slick-font-family: "slick" !default;
$slick-arrow-color: $color-base !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

.slick-carousel {
    .slick-prev,
    .slick-next {
        position: absolute;
        display: block;
        height: 20px;
        width: 20px;
        line-height: 0px;
        font-size: 0px;
        cursor: pointer;
        background: transparent;
        color: transparent;
        top: 50%;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
        padding: 0;
        border: none;
        outline: none;

        &:hover, &:focus {
            outline: none;
            background: transparent;
            color: transparent;

            &:before {
                opacity: $slick-opacity-on-hover;
            }
        }

        &.slick-disabled:before {
            opacity: $slick-opacity-not-active;
        }

        &:before {
            // font-family: $slick-font-family;
            font-size: 20px;
            line-height: 1;
            color: $slick-arrow-color;
            opacity: $slick-opacity-default;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
    }

    .slick-prev {
        left: -5px;

        [dir="rtl"] & {
            left: auto;
            right: -5px;
        }

        &:before {
            @include arrow(20px, -135deg, 4px);
            content: '';
            position: relative;
            display: inline-block;
            @include remit(top, -1);

            [dir="rtl"] & {
                @include arrow(20px, 45deg, 4px);
                content: '';
                position: relative;
                display: inline-block;
                @include remit(top, -1);
            }
        }
    }

    .slick-next {
        right: -5px;

        [dir="rtl"] & {
            left: -5px;
            right: auto;
        }

        &:before {
            @include arrow(20px, 45deg, 4px);
            content: '';
            position: relative;
            display: inline-block;
            @include remit(top, -1);

            [dir="rtl"] & {
                @include arrow(20px, -135deg, 4px);
                content: '';
                position: relative;
                display: inline-block;
                @include remit(top, -1);
            }
        }
    }
}

.slick-equal-heights .slick-track {

    display: flex;
    flex-direction: row;
    justify-content: center;
    min-width: 100%;

    &::before,
    &::after {
        display: none;
    }

    > .slick-slide {

        height: auto;
        float: none;

        > div {
            height: 100%;
        }

    }

}
