/* ==========================================================================
	TEAM LAYOUT STYLES
========================================================================== */
.team {
	padding-top: 60px;

	&__item {
		@extend %cf;
		list-style:none;
		@include remit(margin-bottom, $g-spacing);


		@include mq($regular) {
			@include gallery(12);
		}
	}

	&__img {
		@include span(8);
		max-width:200px;

		&--no-imge {
			@extend %no-image;
			height:130px;
			max-height:200px;
			display:block;
		}
	}

	&__content {
		@include span(16);
		margin-right:0;
		@include remit(padding-top, $hsu);

		@include mq($regular) {
			@include remit(padding-top, 18);
		}
	}
}

/* ==========================================================================
	TEAM  STYLES
========================================================================== */
.team {

	&__name {
		@include font-size(19, 6, 19);
	}

	&__jobTitle {
		font-style:italic;
		@include font-rem(14);
		@include remit(margin-bottom, 6);
		@include remit(padding-bottom, 6);
		border-bottom:1px solid $lightgrey;
	}

	&__contact {
		@include font-rem(12);

		@include mq($small) {
			@include font-rem(14);
		}
	}

	&__bio {
		@include font-rem(14);
		@include remit(margin-bottom, 6);
		@include remit(padding-bottom, 6);
	}
}