/* ==========================================================================
	HEADER BTTON STYLES
========================================================================== */
.header__btns {
	@extend %cf;
	@include span(10 last);
	text-align:right;
	@include remit(margin-top, 8);
	width: calc(60px + 1.75rem); // fixes layout on 320px wide screen.

	@include mq($nav-bp) {
		display:none;
	}
}

.header__btn {
	text-align:center;
	position:relative;
	background-color:$color-base;
	display:inline-block;
	vertical-align:middle;
	@include remit(padding, 4);

	+ .header__btn {
		@include remit(margin-left, $hsu);
	}

	.icon {
		vertical-align:middle;
		height:24px;
		width:24px;
	}

	.header__btn-icon {
		color:$white;
		@extend %btn-reset;
	}
}
