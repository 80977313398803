/* ==========================================================================
  STANDARD PAGINATION STYLES
========================================================================== */
$slick-dot-color: $grey;
$slick-dot-color-active: $color-base;
$slick-dot-size: 24px;
$slick-opacity-default: 0.75;
$slick-opacity-on-hover: 1;
$slick-opacity-not-active: 0.25;

.slick-dots--grey {
  @include remit(margin-top, $hsu);
  display: block;
  list-style: none;
  padding: 0;
  position:relative;
  text-align: center;
  width: 100%;
  z-index:100;

  li {
    cursor: pointer;
    display: inline-block;
    height: $slick-dot-size;
    margin: 0 5px 5px;
    padding: 0;
    position: relative;
    transition:$g-trans;
    width: $slick-dot-size;

    button {
      background: transparent;
      border: 0;
      border:1px solid currentColor;
      color:$color-text;
      cursor: pointer;
      display: inline-block;
      font-size: 0px;
      height: $slick-dot-size;
      line-height: 0px;
      opacity: $slick-opacity-not-active;
      outline: none;
      transition:$g-trans;
      width: $slick-dot-size;

      @include mq($regular) {
        color:$slick-dot-color;
      }

      &:hover, &:focus {
        opacity: $slick-opacity-on-hover;
        outline: none;
        transition:$g-trans;
      }
    }
    &.slick-active button {
      background-color:$slick-dot-color-active;
      color: $slick-dot-color-active;
      opacity: $slick-opacity-on-hover;
    }
  }
}

