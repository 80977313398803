/* ==========================================================================
News Teaser STYLES
========================================================================== */

.newsTeaser {
	@extend %cf;
	@include remit(margin-bottom, $bsu);
	@include remit(padding-bottom, $bsu);
	border-bottom:1px solid $lightgrey;

	&__img {
		@include span(8 last);
		@include remit(margin-left, $bsu);
		@include remit(margin-bottom, $bsu);

		@include mq($small) {
			@include span(9);
			margin-left:0;
			margin-bottom:0;
		}

		@include mq($medium) {
			@include span(6);
		}
	}

	&__content {
		// @include remit(padding-top, $hsu);

		> :last-child {
			margin-bottom:0;
		}

		@include mq($small) {
			@include span(14.5 last);
		}

		@include mq($medium) {
			@include span(17.5 last);
		}
	}
}


.newsTeaser {

	&__title {
		@include font-size(19, 6, 19*1.3);
	}


	p {
		@include font-size(14, 6, 21);
	}

	&__link {
		position:relative;

		&:after {
			@include arrow(8px, 45deg, 2px);
			content: '';
			position:relative;
			display:inline-block;
			@include remit(top, -1);
			@include remit(margin-left, 4);
		}
	}
}
