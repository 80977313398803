.stockists {
    background-color: $white;
    display: flex;
    flex-direction: column;
    resize: vertical;
    overflow: auto;
    margin-top: 1em;
    margin-bottom: 1em;

    @include mq($tabs-bp) {
        position: absolute;
        left: calc(1.5rem + 10px);
        top: 60px;
        height: 228px;
        width: 230px;
        margin-top: 0;
        margin-bottom: 0;
	}

}

.stockists__header {
    padding: 10px;
    border: 1px solid #ccc;
    background-image: linear-gradient(to top, #efefef, #fff 20%);
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
}

.stockists__title {
    color: $color-text;
    @include font-rem($h4-font-size);
    font-weight: bold;
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
}

.stockists__tabs {

    .r-tabs-tab {
        background-image: linear-gradient(to top, #aaa, #ddd 10%);
        @include font-rem($h6-font-size);
        padding: 0.2em 0.5em;
    }

    .r-tabs-anchor {

        color: $color-text;
        padding: 1.125rem;
        display: block;
        position: relative;

        &::after {
            content: "\25bc";
            position: absolute;
            @include remit(right, $hsu);
            @include font-rem(14);
        }

        &:hover {
            color: $color-text;
        }

        @include mq($tabs-bp) {

            padding: 0;
            display: inline;

            &::after {
                display: none;
            }

        }

    }

    .r-tabs-state-active {

        background-color: #ddd;
        background-image: none;

        .r-tabs-anchor::after {
            content: "\25b2";
        }

    }

    input[type="search"] {

        border: 1px solid #ccc;
        padding: 1.125rem;
        width: 100%;
        box-sizing: border-box;

        @include mq($tabs-bp) {
            @include font-rem($h6-font-size);
            padding: 0.2em 0.5em;
        }

    }

}

.stockists__results {

    border: 1px solid #ccc;
    padding: 10px;
    overflow: auto;
    max-height: 200px;

    @include mq($tabs-bp) {
        border: none;
        flex-grow: 1;
        height: 100%;
        max-height: none;
    }

}

.stockists__companies {

    list-style-type: none;
    margin: 0;
    padding: 0;

    > li {

        + li {
            margin-top: 0.5em;
        }

        > a {

            font-weight: bold;
            padding: 1.125rem 0;
            display: inline-block;

            @include mq($tabs-bp) {
                padding: 0;
            }

        }

    }

}

.stockists__container {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: 400;
    }

}

.stockist-tooltip__title {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 1em;
}

.stockist-tooltip__content {
    font-size: 0.8rem;
    color: #000;
    margin-bottom: 1em;
}

.stockist-tooltip__list {

    display: flex;
    flex-direction: row;
    list-style-type: none;

    > li {
        margin-right: 1em;
    }

}

.stockist-tooltip__link {
    color: green;
}
