// duration     Specifies how many seconds or milliseconds an animation takes to complete one cycle. Default 0
//
// delay        Specifies when the animation will start. Default 0
//
// function     Describes how the animation will progress over one cycle of its duration. Default "ease"
//
// count        Specifies the number of times an animation is played. Default 1
//
// fill-mode    Specifies if the effects of an animation are before the animation starts and after it ends.
//
// visibility   Determines whether or not a transformed element is visible when it is not facing the screen.

$countDefault: 1s !default;
$durationDefault: 1s !default;
$delayDefault: 0s !default;
$functionDefault: ease !default;
$fillDefault: both;
$visibilityDefault: hidden !default;

@mixin count($count: 1) {
	animation-iteration-count: $count;
}

@mixin duration($duration: 1s) {
	animation-duration: $duration;
}

@mixin delay($delay: .2s) {
	animation-delay: $delay;
}

@mixin function($function: ease) {
	animation-timing-function: $function;
}

@mixin fill-mode($fill: both) {
	animation-fill-mode: $fill;
}

@mixin visibility($visibility: hidden) {
	backface-visibility: $visibility;
}

@mixin transform($property) {
	transform: $property;
}

@mixin transform-origin($transform-origin: center center) {
	transform-origin: $transform-origin;
}

@mixin transform-style($transform-style: flat) {
	transform-style: $transform-style;
}

@mixin animation-name($animation-name) {
	animation-name: $animation-name;
}

@mixin keyframes($animation-name) {
	@keyframes #{$animation-name} {
		@content;
	}
}
