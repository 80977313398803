/* ==========================================================================
   certificateNotice STYLES
========================================================================== */

.certificateNotice {
	@extend %cf;

	&__item {
		list-style:none;

		&:not(:last-of-type) {
			@include remit(margin-bottom, $bsu);
			@include remit(padding-bottom, $bsu);
			border-bottom:1px dashed $lightgrey;
		}
	}

	&__title {
		@include font-size(18, no, 24);
	}


	&__content {
		p {
			@include font-size(14, yes, 21);

			&:last-of-type {
				@include remit(margin-bottom, $hsu);
			}
		}
	}
}


.certificateHistory {
	// @include remit(margin-left, 60);
	// @include remit(margin-right, 60);
	@include remit(margin-left, $hsu);

	&__title {
		@include font-size(15, no, 24);
	}

	&__item {
		@include font-size(13, no, 21);
		list-style:none;
		font-style:italic;
	}
}