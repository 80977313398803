/* ==========================================================================
	ARROW MODULES
========================================================================== */

@mixin arrow($size, $transform, $weight: 1px) {
	border: $weight solid currentColor;
	border-width: $weight $weight 0 0;
	transform: rotate($transform);
	width: $size;
	height: $size;
}