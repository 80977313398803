/* ==========================================================================
	PANEL STYLES
========================================================================== */
.panel {
	&__title {
		font-family:$font-body;
		color:$color-sub;
		font-weight:500;
		@include font-size(18, yes, 18*1.2);
		border-bottom:1px solid $lightgrey;
		@include remit(padding-bottom, $hsu);

		&--small {
			@include font-size(16, yes, 16*1.2);
		}
	}

	.article--sidebar & {
		&:not(:last-of-type) {
			@include remit(margin-bottom, $g-spacing);
		}
	}

	td, th {
		padding: 5px 5px;
		border: 1px solid #d9d9d9;
		vertical-align: top;

		p {
			margin-top: 0px;
			margin-bottom: 0px;
		}
	}
}

.panel--notes {
	p {
		font-family:$font-header;
		color:$color-base;
		@include font-size(17, yes, 17*1.5);
	}
}

.panel--statement {

	p {
		font-family:$font-header;
		color:$color-base;
		@include font-size(24, yes, 24*1.5);
	}
}

.panel--social {
	
}

.panel--text {
	p {
		@include font-size(14, yes, 21);
	}
}