.icons-grid {
    padding: 60px 0;

    h2 {
        color: $orange;
        margin-bottom: 60px;
    }

    h3 {
        font-weight: bold;
        font-size: 1.5rem;
        color: #848181;
    }
}

.icons-grid__wrapper {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-evenly;

    a {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 30px;

        @media screen and (min-width: 768px) {
            flex: 0 0 50%;
            max-width: 50%;
        }

        @media screen and (min-width: 992px) {
            flex: 0 0 20%;
            max-width: 20%;
        }
    }


    img {
        max-height: 110px;
        margin-bottom: 15px;
    }


}
