/* ==========================================================================
	CONTACT STYLES
========================================================================== */

.container--contact {


	h1,h2,h3,h4,.lead {
		font-weight: 500;
		padding-top: 30px;
	}

	h1 {
		margin-right: 0;
		margin-bottom: 0;
		padding-top: 0;
		font-weight: 400;
	}

	h2 {
		color: #666;
	}

	h3 {
		color: black;
	}

	h4 {
		border-bottom: 1px solid rgba(255,255,255,0.6);
		padding-bottom: 14px;
		margin-bottom: 14px;
	}

	.lead {
		padding-top: 10px;
		margin-bottom: 10px;

		p {
			margin-bottom: 0;
		}
	}

	// form {
	// 	label {
 //    		display: inline-block;
	// 		margin-top: 1.5rem;
	// 		margin-bottom: 0;
	// 	}

	// 	.submit-align-center {
	// 		text-align: center;

	// 		button {
	// 			margin-top: 1.5rem;
	// 		}
	// 	}
	// }
}

.tel {
	@include font-size(24);
}

.email {
	@include font-size(16);
}

.address {
	@include font-size(14);
	margin-bottom: 30px;
}

.map-wrapper {
	position: relative;

	.map__overlay {
		background-color: $color-base;
		color: white;
		font-size: 12px;
    	line-height: 1.4;
	    position: absolute;
        padding: 28px 28px 28px 50px;
	    left: 10px;
	    bottom: 35px;
	    // margin: 0 auto;
	    max-width: 245px;
	    max-height: 200px;

	    @include mq($medium) {
	    	// bottom: 140px;
	    }

	    @include mq($regular) {
		    top: 42px;
		    left: auto;
	    	right: 15%;
	    	bottom: unset;
	    	height: 184px
	    }

	    img.pin-icon {
		    width: 22px;
		    position: absolute;
		    left: 20px;
		}
	}
}

.support-icon {
	max-height: 90px;
}

.contact-icon {
	max-height: 25px;
    max-width: 25px;
    float: left;
    margin-right: 10px;
}

.btn--support {
    display: inline-block;
	background-color: #f36d21;
    border: 0 none;
    padding: 15px 30px;
    color: white;

    &:hover,
    &:focus,
    &:visited,
    &:active {
    	color: white;
    }

    &:hover {
    	background-color: #000;
    }

    &.pagination__link--next {
    	&:after {
    		margin-left: 5px;
    	}
    }
}

.executive {
	background-color: $color-base;
	color: white;
	padding: 30px 30px 20px;
	margin: 65px 10px 0;

	h1,h2,h3,h4,h5,p,a {
		color: white;
		margin: 0;
		padding: 0 0 10px 0;
		border: 0 none;
	}

	img {
		display: block;
		text-align: center;
		margin: 30px auto;
		max-width: 133px;
		max-height: 133px;

		@include mq($large) {
	    	margin: -96px auto 30px;
		}
	}

}

.faq {
	@include mq($regular) {
		padding-left: 60px;
		padding-right: 60px;
	}

	h1 {
		padding-top: 10px;
		padding-bottom: 30px;
		font-weight: normal;
	}

	.faq__item {
		.faq__item__question {
			font-weight: 600;
			margin-bottom: 0;
		}
	}
}


/* ==========================================================================
	GOOGLE MAP STYLES
========================================================================== */

.map {
	clear:both;
	// @extend %no-gutter;
	width:100%;
	// border:5px solid $lightgrey;
	// @include remit(margin-bottom, $bsu);
}

#map-google {
	height:280px;
	width:100%;


	@include mq($regular) {
		height: 450px;
	}

	img{max-width: inherit;}
}
