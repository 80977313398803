.regionalExpertsSlider {

    padding: 55px 0;

    h3 {
        color: $color-base;
    }

}

.regionalExperts__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.regionalExperts__column {

    flex: 0 0 100%;

    @include minSiblings(2) {
        @media (min-width: #{$small}px) {
            flex-basis: 50%;
        }
    }

    @include minSiblings(3) {
        @media (min-width: #{$medium}px) {
            flex-basis: 33.3333%;
        }
    }

    @include minSiblings(4) {
        @media (min-width: #{$regular}px) {
            flex-basis: 25%;
        }
    }

}

.regionalExpert {
    margin: 10px 30px;
}

.regionalExpert__item {
	display: flex;
    flex-direction: column;
	align-items: center;
}

.regionalExpert__img > img {
    max-width: 125px;
    border-radius: 100%;
}

.regionalExpert__content {
	flex: 1;

	> p {
		margin-bottom: 0;
	}
}

.regionalExpert__name,
.regionalExpert__job-title {
	color: $color-sub;
	margin-bottom: 0.5rem;
}

.regionalExpert__name {
	@include font-rem(16);
	line-height: 1.2;
}

.regionalExpert__job-title {
	@include font-rem(14);
	line-height: 1.5;
}

.regionalExpert__contact {
	@include font-rem(14);
	line-height: 1.5;
}
