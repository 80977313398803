/* ==========================================================================
	COURSE DETAIL STYLES
========================================================================== */

.courseDetails {
	// @include remit(margin-bottom, $bsu);
	@include remit(margin-top, $bsu);
	@extend %cf;

	&__item {
		position:relative;
		list-style:none;
		background-color:$color-base;
		color:$white;
		@include remit(margin-bottom, $bsu);
		@include remit(margin-top, $g-spacing);
		@include remit(padding, 18 $hsu);

		@include mq($small) {
			@include gallery(8);
		}
	}

	&__label {
		font-family:$font-header;
		font-weight:600;
		@include font-size(17, $hsu, 24);
		border-bottom:1px solid rgba($white, 0.3);
		@include remit(padding-bottom, 6);
	}


	&__text {
		@include font-size(14, no, 21);
	}


	.icon {
		color:$color-base;
		@include remit(top, -34);
		position:absolute;
	}
}
