/* ==========================================================================
   cableSearch STYLES
========================================================================== */

.cableSearch--padding-top {
	@include remit(margin, $g-spacing*2 0 0);
}

.cableSearch {
	background-color:$color-base;
	color:$white;
	@include remit(margin, $g-spacing 0 0);
}

.cableSearch {

	.tabs {
		@include mq(1000) {
			@include remit(padding, 0 $g-spacing);
		}
	}

	&__tab {
		@include remit(padding-top, $bsu);
		@include remit(padding-bottom, $bsu);
	}

	&__icon {
		@include remit(margin-bottom, $hsu);
		float:left;
		margin-right: gutter();

		@include mq($medium) {
			float:none;
			width:span(6);
			display:inline-block;
			vertical-align:middle;
			margin-right: gutter();
			height:30vw;
		}

		svg {
			width:37px;
			height:50px;

			@include mq($medium) {
				height: 100%;
				width: 100%;
			}
		}
	}

	&__content {
		@include remit(margin-bottom, $bsu);
		@include mq($medium) {
			width:span(18);
			display:inline-block;
			vertical-align:middle;
		}

		@include mq($large) {
			width:span(15);
		}
	}

	&__title {
		color:currentColor;
		position:relative;
		@include remit(padding-bottom, $hsu);
		border-bottom:1px solid rgba($white, 0.5);
		@include font-size(26);

		@include mq($small) {
			@include font-size(36);
		}
	}

	&__titleIcon {
		display:block;
		position:absolute;
		right:0;
		top:0;

		.icon {
			width:18px;
			height:18px;

			@include mq($small) {
				width:26px;
				height:26px;
			}
		}

	}

	&__map {
		height: 80vh;
	}

	p {
		@include font-size(16, yes, 26);
	}
}


/* ==========================================================================
   cableSearch FORM STYLES
========================================================================== */

.cableSearch__form {
	font-size:0;
	overflow: hidden;

	input {
		@extend %text-input;
		width: 100%;
		display:inline-block;
		border:0;
		@include remit(height, 40);
		@include remit(padding, 10);
		margin:0;
		color: $color-text;
		@include font-rem(16);
		vertical-align: bottom;

		@include mq($medium) {
			width: 70%;
		}
	}

	button {
		@extend %text-input;
		display:inline-block;
		color:$white;
		background-color:$color-sub;
		border:0;
		margin:0;
		// @include remit(padding, 10);
		@include remit(height, 60);
		@include remit(width, 60);
		vertical-align: bottom;
		width: 100%;

		@include mq($medium) {
			width: auto;
		}
	}

	.icon {
		width:24px;
		height:24px;
	}
}
