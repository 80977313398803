/* ==========================================================================
	DOWNLOAD STYLES
========================================================================== */
.downloads {
	li {
		list-style:none;
		@include font-size(14);
	}

	.icon {
		width:16px;
		height:18px;
		color: darken($lightgrey, 10%);
		@include remit(margin-right, 3);
	}
}

.pdf {
	color:#d10d17;
}

.filesize {
	@include font-rem(15);
	color:$grey;
	@include remit(margin-left, 6);
}