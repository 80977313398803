/* ==========================================================================
	MODEL STYLES
========================================================================== */
.modal {
	position:fixed;
	top:0;
	left:0;
	z-index: 900;
	opacity:0;
	transition:$g-trans;
	pointer-events: none;
	width:100%;
	height:100%;
	display:none;
	background-color:#e8e8e8;

	&:target {
		opacity:1;
		pointer-events: auto;
	}

	&__close {
		@include remit(margin, $bsu $bsu 0);
		text-align:right;
		position: relative;
		z-index: 999;
	}

	&__closeBtn {
		color: $color-error;
		@include remit(padding, 6);
		@include font-rem(14);
		@include hover() {
			background: $color-error;
			color:$white;

			.icon-close:before, .icon-close:after, {
				transition:$g-trans;
				background: $white;
			}
		}

		.icon-close {
			width: 14px;
			height: 14px;
			@include remit(margin-right, 3);
		}
	}
}

.modal:target {
	display:block;
	.modal__content {
		@include fadeInUp();
	}
}

/* ==========================================================================
	 VIDEO MODAL
	 A modal that more typical of other websites.
========================================================================== */

.modal--video {

	background-color: $white;
	height: auto;
	left: 0;
	max-height: 100vh;
	overflow: auto;
	top: 0;
	width: 100%;

	@include mq($small) {
		@include remit(width, $bsu * 25);
		left: 50%;
		overflow: visible;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	@include mq($medium) {
		@include remit(width, $bsu * 30);
	}

	&:target .modal__content {
		animation: none;
	}

}

.modal__content--video {

	@include remit(padding, $bsu);

	> :last-child {
		margin-bottom: 0;
	}

}

.modal__video {
	@include remit(margin-bottom, $bsu);
	width: 100%;
}

.modal__background {
	background-color: rgba(0, 0, 0, 0.6);
	left: 0;
	height: 100%;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 899;
}

.modal__videoClose {

	background-color: $white;
	border-radius: 50%;
	color: $color-text;
	line-height: 0;
	padding: 0.3em;
	position: absolute;
	right: 0;
	top: 0;
	transition: $g-trans;
	z-index: 1;

	@include mq($small) {
		left: 100%;
		right: auto;
		transform: translate(-70%, -30%);
	}

	&:hover,
	&:focus {
		background-color: $color-error;
		color: $white;
	}

	> .icon-close {

		color: inherit;
		height: 14px;
		transition: none;
		width: 14px;
		top: 0;

		&::before,
		&::after {
			background-color: currentColor;
			transition: none;
		}

	}

}

.modal__closeInfo {
	display: block;
	font-size: rem($p-font-size);
	text-align: right;
}

.modal__videoTitle {
	color: $orange;
	font-size: rem($h2-font-size);
}
