/* ==========================================================================
	 ANIMATION STYLES
========================================================================== */


.cableSearch {
	&__icon {
		@include fadeInLeft($delay:0.5s);
	}

	&__content {
		@include fadeInRight($delay:1s);
	}

	&__content--full {
		@include fadeInRight($delay:0s);
	}

	.pagination {
		@include fadeInUp($delay:1.2s);
	}
}

.card__btn, %linkAnimated {
	animation-name:sideToSide;
	animation-duration:2.5s;
	animation-iteration-count:infinite;
}




@keyframes sideToSide {
	0%, 20%, 50%, 80%, 100% {
	transform: translateX(0);
	}
	40% {
	transform:translateX(20px);
	}
	60% {
	transform:translateX(10px);
	}
}
