/* ==========================================================================
	BREADCRUMB STYLES
========================================================================== */
.breadcrumb {

	position:absolute;
	@include remit(top, -34);
	display: inline-block;
	// @include remit(height, 34);
	// overflow:hidden;
}

/* ==========================================================================
	BREADCRUMB NAV STYLES
========================================================================== */


.nav--breadcrumb {
	background-color:$white;

	ul {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width:auto;
		max-width: 300px;

		@include mq($small) {
			max-width:500px;
		}
	}

	&__item {
		list-style:none;
		display:inline;
		@include font-rem(12);
		font-weight:500;
		@extend %caps;
		// @include remit(padding-right, 20);
		@include remit(margin-right, 20);
		// @include remit(margin-top, 6);
		height:34px;
		line-height:38px;

		&:not(:last-of-type) {
			&:after {
				content: '//';
				@include remit(margin-left, 20);
			}
		}

		a {
		transition:$g-trans;
		color:inherit;
			@include hover() {
				color:$color-sub;
			}
		}
	}

	&__home {
		display:inline-block;
		transition:$g-trans;
		background-color:$grey;
		// @include remit(padding, 9);
		margin-top:0;
		height:34px;
		width:34px;
		line-height:38px;
		text-align:center;
		color:$white;

		&:not(:only-child) {
			@include remit(margin-right, 20);
		}

		&:after {
			display:none;
		}

		@include hover() {
			transition:$g-trans;
			background-color:$color-sub;
		}

		a {
		color:inherit;
			@include hover() {
				color:$white;
			}
		}
	}

	&__current {
		color:$color-base;
		font-weight:700;
	}

	.icon {
		width:15px;
		height:15px;
	}
}