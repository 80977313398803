.two-columns {
    &.two-columns--image-left {
        background-color: #eaeaea;
    }
    &:first-child {
        .two-columns__content {
            padding-top: 0;
        }
        .two-columns__media {
            padding-top: 120px;
        }
    }
}

.two-columns__inner {
    // max-width: 100%;
    padding: 0;

    @media screen and (min-width: 768px) {
        display: flex;
    }

    h2 {
        @extend .alpha;
        color: $orange;
    }

    h3 {
        @extend .beta;
        color: $orange;
    }
}

.two-columns__media {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    order: 2;

    @media screen and (min-width: 992px) {
        padding-top: 64px;
        // padding-bottom: 45px;
        padding-left: 45px;
    }

    figure {
        width: 100%;
        height: 100%;
    }

    img {
        max-height: 400px;
        width: 100%;
    }

    .two-columns--image-left & {

        order: 0;
        padding-left: 0;
        padding-right: 15px;

        @media screen and (min-width: 992px) {
            padding-right: 45px;
        }

    }
}

.two-columns__content {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 15px;

    @media screen and (min-width: 992px) {
        padding-top: 45px;
        // padding-bottom: 45px;
        padding-right: 45px;
    }

    .two-columns--image-left & {

        padding-left: 15px;
        padding-right: 0;

        @media screen and (min-width: 992px) {
            padding-left: 45px;
        }

    }

}

@media screen and (min-width: 768px){
    .two-columns__media {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;

        .two-columns--image-left & {
            order: 0;
        }
    }

    .two-columns__content {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
}

.background-gray {
    background-color: #eaeaea;
}
