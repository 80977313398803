/* ==========================================================================
   CLIENT SEARCH PAGE STYLES
========================================================================== */

.entryTeaserClient {

	&__logo {
		border:1px solid $lightgrey;
		@include remit(padding, 6);
		height:130px;
		transform-style: preserve-3d;
		vertical-align:middle;
		text-align:center;
		@include remit(margin-bottom, 6);
		width:span(16);

		@include mq($medium) {
			@include span(8);
			text-align:center;
		}


		img {
			position: relative;
			top: 50%;
			transform: translateY(-50%);
			max-height:100px;
		}

		&--no-imge {
			@extend %no-image;
		}
	}

	&__content {
		@include mq($medium) {
			@include span(16 last);
		}
	}

	&__totals {
		position:relative;
		margin-right:12px;

		&:not(:last-of-type) {
			&:after {
				content:'';
				width:2px;
				height:10px;
				position:absolute;
				top:4px;
				right:-8px;
				background-color:$lightgrey;
				display:inline-block;
			}
		}
	}
}