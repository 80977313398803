/* ==========================================================================
  HEADER STYLES
========================================================================== */
.header {
  @extend %cf;
  position:relative;

  // &:after {
  //   @include remit(bottom, -170);
  //   background-image: url('/assets/img/bg-cable-default.jpg');
  //   background-repeat:no-repeat;
  //   background-size:70%;
  //   content: '';
  //   display:block;
  //   filter: FlipH;
  //   height:185px;
  //   position:absolute;
  //   right:0;
  //   transform: scaleX(-1);
  //   width:145px;
  //   z-index:-1;

  //   @include mq($regular) {
  //     display:none;
  //     filter:none;
  //     transform: scaleX(1);
  //   }

  //   @include mq(1400) {
  //     @include remit(left, -30);
  //     @include remit(bottom, -185);
  //     background-size:80%;
  //     display:block;
  //   }

  //   @include mq(1500) {
  //     left:0;
  //   }

  //   @include mq(1600) {
  //     background-size:100%;
  //   }
  // }
}

.header__topBar {
  @include remit(padding-top, $hsu);
  @include remit(padding-bottom, $hsu);
  background-color:$color-base;
  position:relative;
  z-index:100;
}

.header__logos {
  display:inline-block;
}

.header__topNav {
  display:none;

  @include mq($nav-bp) {
    @include remit(margin-top, 12);
    display:inline-block;
    float:right;
    text-align:right;
  }
}


/* ==========================================================================
  HEADER MAIN MENU AREA STYLES
========================================================================== */

.header__navArea--with-slider {
  position:absolute;
  top:0;
  width:100%;
  z-index:100;
}


.header__nav {
  position:relative;
  z-index:500;
  background-color:rgba($black, 0.5);
  // @include mq($large) {
  //   @include span(18);
  // }

  .container {
     @extend %no-gutter;
     margin-right: 0; // removes horizontal scrollbar on tablet.
  }
}

.header__tel {
display:none;
  @include remit(padding, $hsu 0);

  @include mq($large) {
    @include span(6 last);
    text-align:right;
  }
}

/* ==========================================================================
  HEADER SEARCH STYLES
========================================================================== */

.header__search {
  @extend %cf;
  display:block;
  height:100%;
  opacity:0;
  position:fixed;
  top:0;
  transition: $g-trans;
  width:100%;
  z-index:-1;

  @include mq($regular) {
    position:absolute;
  }

  .js_search_is_open & {
    background-color:rgba($black, 0.95);
    opacity:1;
    z-index:600;
  }

  &.header__search--force-visible {
    background-color:rgba($black, 0.50);
    opacity:1;
    z-index:0;
    position: relative;

    @include mq($regular) {
      position:absolute;
    }

    .search-header {
      padding: 6% 3%;
    }
  }
}


/* ==========================================================================
  HEADER NAV STYLES
========================================================================== */

.header__nav {
  display:none;

  @include mq($nav-bp) {
    display:block;
  }

  &.is-active {
    @include fadeInDown();
    background-color:$color-sub;
    display:block;
    position:absolute;
    top:0;
    width:100%;
  }
}
