/* ==========================================================================
   CLIENT DETAILS PAGE STYLES
========================================================================== */

.clientTabs {
	// background-color:$color-base;
	// color:$white;
	@include remit(margin-top, $bsu);

	@include mq($tabs-bp) {
		@include remit(margin, $g-spacing*2 0 0);
	}


	&__tab {
		@include remit(padding-top, $g-spacing);
		@include remit(padding-bottom, $g-spacing);
		// text-align:center;
	}

	&__content {
		@include mq($medium) {
			width:span(16);
			display:inline-block;
			vertical-align:middle;
		}

		@include mq($large) {
			width:span(13);
		}
	}

	&__title {
		color:currentColor;
		position:relative;
		@include remit(padding-bottom, $hsu);
		border-bottom:1px solid rgba($white, 0.5);
		@include font-size(24);
	}

	p {
		@include font-size(16, yes, 26);
	}
}

.client {
	&__summary {
		@include mq($regular) {
			@include span(isolate 16 at 2);
		}
	}

	&__logo {
		// border:1px solid $lightgrey;
		@include remit(padding, $bsu);
		height:200px;
		transform-style: preserve-3d;
		vertical-align:middle;
		text-align:center;
		@include remit(margin-bottom, $hsu);

		@include mq($small) {
			width:span(16);
		}

		@include mq($medium) {
			@include span(12 last);
		}


		img {
			position: relative;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	&--no-imge {
		@extend %no-image;
	}
}
