/* ==========================================================================
	ENTRY TEASER STYLES
========================================================================== */

.entryTeaser {
	@include remit(margin-bottom, 40);
	@extend %cf;

	&__img, &__content {
		display:inline-block;
		vertical-align:middle;
	}
	&__img {
		width:130px;
		@include remit(margin-right, 6);
		overflow:hidden;
	}

	&__content {
		width:calc(100% - 142px);
	}


	&__heading {
		font-family:$font-body;
		@include font-size(14, 6, 14*1.2);
		// font-style:italic;
		color:$grey;
	}

	&__subHeading {
		font-family:$font-header;
		color:$color-sub;
		@include font-size(14, $hsu, 14*1.3);
		font-style:italic;
	}

	&__link {
		color:$color-base;
		position:relative;
		@include font-size(13, no, 24);

		&:after {
			@include arrow(7px, 45deg, 2px);
			content: '';
			position:relative;
			display:inline-block;
			// @include remit(top, -1);
			@include remit(margin-left, 2);
		}
	}

	@include hover() {
		.entryTeaser__link {
			@extend %linkAnimated;
		}
	}
}

