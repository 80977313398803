/* ==========================================================================
   SYSTEM STYLES
========================================================================== */

.system {
	@extend %cf;

	@include mq($regular) {
		@include gallery(12);
	}

	&:not(:last-of-type) {
		@include remit(margin-bottom, $g-spacing);
	}

	&__title {
		// color:$white;
		@include font-size(24);
	}

}