// .tooltip {
//
//     display: none;
//     @include fadeInUp();
//
//     &[hidden] {
//         display: none !important;
//     }
//
// }

.tooltip {
    position: relative;
}

.tooltip > summary {
    cursor: pointer;
}

.tooltip > summary::-webkit-details-marker {
    display: none;
}

.tooltip > article {
    color: #FFF;
    background-color: #666;
    border-radius: 0.25em;
    left: 0;
    padding: 0.5em;
    position: absolute;
    top: 100%;
    z-index: 1;
}

.details-polyfill:not([open]) > *:not(summary) {
    display: none;
}
