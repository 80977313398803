/* ==========================================================================
  BLOCKQUOTE
========================================================================== */

// blockquote:before,
// q:before {
// 	content: "";
// 	padding:20px 0px 0px 0px;
// 	}

// blockquote:after,
// q:after {
// 	 content: open-quote;
// 	float:right; margin-top:-20px;
// 	@include font-rem(136);
// }

blockquote {
	 quotes: "\201C""\201D""\2018""\2019";

	p {
		color: $color-text;
		font-style:italic;
		font-family:$font-header;

		&:before {content: open-quote;}
		&:after {content: close-quote;}

	}

	cite {
		@include font-size(15);
		display: block;
		color:$color-base;

		@include mq($small) {
			@include remit(margin, $hsu 30 0 $bsu);
			@include remit(padding-left, 20);
		}


		&:before {
			content: "\2014 \0020";
		}
	}
}
